/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CenterSupplier } from './CenterSupplier';
import {
    CenterSupplierFromJSON,
    CenterSupplierFromJSONTyped,
    CenterSupplierToJSON,
} from './CenterSupplier';
import type { ResponseResult } from './ResponseResult';
import {
    ResponseResultFromJSON,
    ResponseResultFromJSONTyped,
    ResponseResultToJSON,
} from './ResponseResult';

/**
 * 
 * @export
 * @interface ResponseCenterSupplier
 */
export interface ResponseCenterSupplier {
    /**
     * 
     * @type {ResponseResult}
     * @memberof ResponseCenterSupplier
     */
    result: ResponseResult;
    /**
     * 
     * @type {CenterSupplier}
     * @memberof ResponseCenterSupplier
     */
    data: CenterSupplier;
}

/**
 * Check if a given object implements the ResponseCenterSupplier interface.
 */
export function instanceOfResponseCenterSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ResponseCenterSupplierFromJSON(json: any): ResponseCenterSupplier {
    return ResponseCenterSupplierFromJSONTyped(json, false);
}

export function ResponseCenterSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCenterSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ResponseResultFromJSON(json['result']),
        'data': CenterSupplierFromJSON(json['data']),
    };
}

export function ResponseCenterSupplierToJSON(value?: ResponseCenterSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ResponseResultToJSON(value.result),
        'data': CenterSupplierToJSON(value.data),
    };
}

