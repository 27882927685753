import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom, FileDownloadData } from 'components/commonparts';
import { SearchCondition, DataList } from 'components/parts/MasterStockCenterItem';
import { useCenter } from 'hooks/useCenter';
import { useCenterStockItem } from 'hooks/useCenterStockItem';
import { useItem } from 'hooks/useItems';
import { useSupplier } from 'hooks/useSupplier';
import { useTypecode } from 'hooks/useTypecode';
import { useSearchParamStore } from 'store/searchParamStore';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';

import type { GetMstCenterStockItemCenterStockItemGetRequest, MstCenterStockItem, ResponseResult } from 'api-client';

function MasterStockCenterItem() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCenterId = localSearchParam?.centerStockItem.centerId || undefined
  const localOrgItemCode = localSearchParam?.centerStockItem.orgItemCode || undefined

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCenterStockItemCenterStockItemGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    centerId: localCenterId,
    orgItemCode: localOrgItemCode,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCenterStockItem[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')
  const [downloadColDef, setDownloadColDef] = useState<any[] | undefined>([]);

  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { fetchCenterStockItemSearch, centerstockitemSearchIsLoading, centerstockitemSearch, centerstockitemSearchResult } = useCenterStockItem()
  const { fetchCenterSearch, centerSearch } = useCenter()
  const { fetchItems, items } = useItem()
  const { fetchSupplierSearch, supplierSearch } = useSupplier()
  const { fetchTypeCode, typeCode } = useTypecode()
  const [searchParam, setSearchParam] = useState<GetMstCenterStockItemCenterStockItemGetRequest>(initSearchParam)
  const isFirstRender = useRef(true);
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<boolean | undefined>(undefined);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)


  useEffect(() => {
    setResultMessage(centerstockitemSearchResult)
  }, [centerstockitemSearchResult])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage, gridMessage?.status, gridMessage?.message])

  useEffect(() => {
    setSearchParam(searchParam)
  }, [searchParam])

  const doSearch = useCallback(async (requestParam: GetMstCenterStockItemCenterStockItemGetRequest) => {
    try {
        fetchCenterStockItemSearch({
          ...requestParam,
        });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstCenterStockItemCenterStockItemGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'CenterStockItem',
            { 
              ...requestParam,
            }
          );
        }
  
        setSearchParam(requestParam);
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [
    hasGridEdited,
      setDiscardChangeDialogOpen,
      fetchCenterStockItemSearch,
      loginUserInfo,
      setSearchParamState,
      setSearchParam,
      setHasGridEdited
  ]);

  const handleRegister = () => {
    setUpdateTrigger((prev) => !prev);
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender.current) {
        isFirstRender.current = false
        const itemsCenterStockPromise = fetchCenterStockItemSearch({
          enabled: false,
          ...searchParam,
        });
        const itemsPromise = fetchItems({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        const centerPromise = fetchCenterSearch({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        const supplierPromise = fetchSupplierSearch({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        const typePromise = fetchTypeCode();
        await Promise.all([itemsCenterStockPromise, itemsPromise, centerPromise, supplierPromise, typePromise]);
      }
    }
    firstTimeRender()
  }, [])


  const generateDownloadData = (): MstCenterStockItem[] => {
    if (!centerstockitemSearch?.list && centerstockitemSearch.list.length === 0) {
      return [{} as MstCenterStockItem]
    }
    const downloadData: MstCenterStockItem[] = [...centerstockitemSearch.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        センター在庫管理
      </Typography>
      <MasterSearchFrom
        result={resultMessage}
        onDoUpdate={handleRegister}
        onReload={onReload}
        onImportData={handleUploadData}
        onFileDownLoad={handleFileDownload}
        discardChangeDialogOpen={discardChangeDialogOpen}
        setDownloadMode={setDownloadMode}
        hasDataEdited={hasGridEdited}
        importRequiredFields={["org_center_id","org_item_code"]}
        headerJp={["自社センター","商品コード"]}
        lastUpdateDatetime={centerstockitemSearch?.lastUpdateDatetime}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>
      <FileDownloadData fileName="センター在庫管理" downloadData={downloadGridRows} downloadMode={downloadMode} columnDefs={downloadColDef} />
      <DataList
          list={centerstockitemSearch}
          items={items.list}
          center={centerSearch.list}
          supplier={supplierSearch.list}
          systype={typeCode}
          onReload={onReload}
          isLoading={centerstockitemSearchIsLoading}
          searchParam={searchParam}
          updateTrigger={updateTrigger}
          onUploadData={uploadData}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
          setDownloadColumnDefs={setDownloadColDef}
        />
    </Box>
          
  )
}

export default MasterStockCenterItem;
