/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewalOrgItem
 */
export interface RenewalOrgItem {
    /**
     * 
     * @type {number}
     * @memberof RenewalOrgItem
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof RenewalOrgItem
     */
    centerId: number | null;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrgItem
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrgItem
     */
    orgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrgItem
     */
    itemName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RenewalOrgItem
     */
    startDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RenewalOrgItem
     */
    endDate: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RenewalOrgItem
     */
    renewalNumber: number | null;
    /**
     * 
     * @type {Date}
     * @memberof RenewalOrgItem
     */
    renewalStartDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RenewalOrgItem
     */
    renewalEndDate: Date | null;
}

/**
 * Check if a given object implements the RenewalOrgItem interface.
 */
export function instanceOfRenewalOrgItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "orgItemCode" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "renewalNumber" in value;
    isInstance = isInstance && "renewalStartDate" in value;
    isInstance = isInstance && "renewalEndDate" in value;

    return isInstance;
}

export function RenewalOrgItemFromJSON(json: any): RenewalOrgItem {
    return RenewalOrgItemFromJSONTyped(json, false);
}

export function RenewalOrgItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewalOrgItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'orgCenterId': json['org_center_id'],
        'orgItemCode': json['org_item_code'],
        'itemName': json['item_name'],
        'startDate': (json['start_date'] === null ? null : new Date(json['start_date'])),
        'endDate': (json['end_date'] === null ? null : new Date(json['end_date'])),
        'renewalNumber': json['renewal_number'],
        'renewalStartDate': (json['renewal_start_date'] === null ? null : new Date(json['renewal_start_date'])),
        'renewalEndDate': (json['renewal_end_date'] === null ? null : new Date(json['renewal_end_date'])),
    };
}

export function RenewalOrgItemToJSON(value?: RenewalOrgItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'center_id': value.centerId,
        'org_center_id': value.orgCenterId,
        'org_item_code': value.orgItemCode,
        'item_name': value.itemName,
        'start_date': (value.startDate === null ? null : value.startDate.toISOString()),
        'end_date': (value.endDate === null ? null : value.endDate.toISOString()),
        'renewal_number': value.renewalNumber,
        'renewal_start_date': (value.renewalStartDate === null ? null : value.renewalStartDate.toISOString()),
        'renewal_end_date': (value.renewalEndDate === null ? null : value.renewalEndDate.toISOString()),
    };
}

