import React, { useState, useEffect } from 'react'

import { Alert, Box, type AlertColor } from '@mui/material'
import  { type ResponseResult} from 'api-client'

interface MessageInfoProps {
  // result: ResponseResult | ResponseUpdate | undefined,
  result: ResponseResult | undefined,
}

// interface MessageInfoType {
//   status: string | null;
//   message: string | null;
// }

function MessageInfo({ result }: MessageInfoProps) {
  const [viewStatus, setViewStatus] = useState('');
  const [viewUpdateResult, setViewUpdateResult] = useState(false);
  const [resultObj, setResultObj] = useState<ResponseResult|undefined>(undefined);
  const MAX_UPDATE_MESSAGE_VIES = 7000;

  useEffect(() => {
    let timeoutId : any = null
    const nowStatus = resultObj?.status ?? '';
    const nowMessage = resultObj?.message ?? '';
    const newStatus = result?.status ?? '';
    const newMessage = result?.message ?? '';

    const isPrioritize = (nowMessage === '' && newMessage !== '') || (nowStatus === '' && newStatus !== '');
    // console.log('MessageInfo result', result, viewUpdateResult,timeoutId, resultObj?.message,isPrioritize, !!result?.message);
    if (!result) {
      // undefindが渡された場合は、とりあえず何もしない（7秒後に消える）
      // すぐにクリアする場合は、setResultMessage(clearMessageInfo())を使用する
      // if (!viewUpdateResult) setResultObj(undefined);
    } else if (!viewUpdateResult || isPrioritize){
      setViewStatus(newStatus);
      setViewUpdateResult(true);
      setResultObj(result);
      timeoutId = setTimeout(() => {
        // 7秒経過するまでは前のメッセージを上書きしない
        setViewUpdateResult(false);
      }, MAX_UPDATE_MESSAGE_VIES)
    }
    // return () => {
    //   clearTimeout(timeoutId)
    // }
  }, [result, result?.message]);

  useEffect(() => {
    // console.log('MessageInfo viewUpdateResult', viewUpdateResult, viewStatus);
    // エラー以外のメッセージは、7秒後に消す
    if (!viewUpdateResult && viewStatus !=='error') setResultObj(undefined);
  }, [viewUpdateResult, viewStatus]);


  return <Box>{resultObj?.status ? <Alert severity={resultObj!.status as AlertColor}>{resultObj.message}</Alert> : <div />}</Box>
}

export default MessageInfo
