import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider'
import { type CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import LoadingOverlay from 'components/commonparts/LoadingOverlay/LoadingOverlay'
import { useCenterStockItem } from 'hooks/useCenterStockItem'
import { useGridCommon } from 'hooks/useGridCommon'
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes'
import { convertDateToRequestParam } from 'utils/convertFromLocalDate'
import { validateRequired } from 'utils/validation'

import type {
  ColDef,
  ColGroupDef,
  IRichCellEditorParams,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import type {
  MstCenterStockItem,
  CenterStockItem,
  GetMstCenterStockItemCenterStockItemGetRequest,
  MstItem,
  MstSupplier,
  Groups,
  MstCenter,
  Codes,
} from 'api-client'
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
  org_center_id: 'orgCenterId',
  org_item_code: 'orgItemCode',
  start_date: 'startDate',
  stock_days_setting: 'stockDaysSetting',
  safety_stock_setting: 'safetyStockSetting',
  stock_days_rank: 'stockDaysRank',
  safety_stock_rank: 'safetyStockRank',
  min_stock_quantity: 'minStockQuantity',
  stop_ship: 'stopShip',
  stop_arrival: 'stopArrival',
  org_supplier_id: 'orgSupplierId',
  order_unit_type: 'orderUnitType',
  case_lot: 'caseLot',
  min_order_quantity: 'minOrderQuantity',
  delete_flg: 'deleteFlg',
}

const primayKey = ['orgCenterId', 'orgItemCode']

interface DataListProps extends DataListPropsBase {
  list: CenterStockItem
  items: MstItem[]
  supplier: MstSupplier[]
  systype: Groups[]
  center: MstCenter[]
  searchParam: GetMstCenterStockItemCenterStockItemGetRequest
}

interface MstCenterStockItemWithId extends MstCenterStockItem, RecordEditEx {}

function DataList({
  list,
  isLoading,
  items,
  supplier,
  center,
  systype,
  searchParam,
  onReload,
  updateTrigger,
  onUploadData,
  setHasGridEdited,
  setResultMessage,
  setDownloadColumnDefs,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const { result, fetchCenterStockItemUpdate, isUpdating, updateResult } =
    useCenterStockItem()
  const gridRef = useRef<AgGridReact<MstCenterStockItemWithId>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [editorDataReady, setEditorDataReady] = useState(false)
  const [row, setRow] = useState<MstCenterStockItemWithId[]>(
    list.list.map((item) => ({ ...item, ...initRecordEditEx() }))
  )
  const [supplierSelect, setSupplierSelect] = useState<(string | null)[]>([])
  const [itemSelect, setItemSelect] = useState<(string | null)[]>([])
  const [centerSelect, setCenterSelect] = useState<(string | null)[]>([])
  const [orderSysTypeCodeLists, setOrderSysTypeCodeLists] = useState<{ [key: string]: Codes[] }>({})
  const [orderSysTypeCodeSelects, setOrderSysTypeCodeSelects] = useState<{ [key: string]: (string | null)[] }>({})
  const orderUnitTypeGroups = ['stock_days_rank', 'safety_stock_rank', 'order_unit_type']
  const [rowChangeTriggerRowID, setRowChangeTriggerRowID] = useState<string | null>(null);
  
  const tableFieldToGroup: { [key: string]: string } = {
    orderUnitType: 'order_unit_type',
    stockDaysRank: 'stock_days_rank',
    safetyStockRank: 'safety_stock_rank',
  }

  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    // setResultMessage({...updateResult} as ResponseResult | ResponseUpdate)
  }, [updateResult])

  useEffect(() => {
    const allSelected = orderUnitTypeGroups.every((group) => orderSysTypeCodeSelects[group]?.length > 0)
    if (supplierSelect.length > 0 && itemSelect.length > 0 && centerSelect.length > 0 && allSelected) {
      setEditorDataReady(true)
    }
  }, [supplierSelect, itemSelect, centerSelect, orderSysTypeCodeSelects])

  useEffect(() => {
    const values = supplier?.map((r) => r.orgSupplierId) || []
    setSupplierSelect(values)
  }, [supplier])
  useEffect(() => {
    const values = center?.map((r) => r.orgCenterId) || []
    setCenterSelect(values)
  }, [center])
  useEffect(() => {
    const newLists: { [key: string]: Codes[] } = {}
    const newSelects: { [key: string]: (string | null)[] } = {}

    orderUnitTypeGroups.forEach((group) => {
      const codes = systype.find((r) => r.typeGroup === group)?.codes || []
      newLists[group] = codes
      newSelects[group] = codes.map((r) => r.typeCode)
    })

    setOrderSysTypeCodeLists(newLists)
    setOrderSysTypeCodeSelects(newSelects)
  }, [systype])
  useEffect(() => {
    // console.log("items", items)
    // if (items.length === 0) return
    setEditorDataReady(false)
    const values = items?.map((r) => r.orgItemCode) || []
    setItemSelect([...values])
  }, [items])

  const createBlankRow = useCallback((): MstCenterStockItemWithId => {
    // カスタムフックから直接コンポーネントの状態にアクセスすることはできないため、useGridCommonからコールされたcreateBlankRowの場合、searchParamが取れない
    // rowChangeTriggerRowIDのEffectで処理する必要がある
    const searchCenterDefault = center.find((rowCenter) => rowCenter.centerId === searchParam.centerId)
    const blankRow: Partial<MstCenterStockItemWithId> = {
      companyId: loginUserInfo?.companyId || null,
      orgItemCode: searchParam.orgItemCode || null,
      orgCenterId: searchCenterDefault?.orgCenterId || null,
      centerId: searchParam.centerId || null,
      supplierCompanyId: null,
      startDate: null,
      stockDaysRank: null,
      safetyStockRank: null,
      stockDaysSetting: null,
      safetyStockSetting: null,
      minStockQuantity: null,
      orderUnitType: null,
      caseLot: null,
      minOrderQuantity: null,
      stopShip: false,
      stopArrival: false,
      orgSupplierId: null,
      itemCode: null,
      recencyFlg: false,
      deleteDatetime: null,
      updateUser: null,
      deleteUser: null,
      insertDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      updateDatetime: null,
      deleteFlg: false,
      ...initRecordEditEx(true),
    }
    setMstItemCol(blankRow as MstCenterStockItemWithId, searchParam.orgItemCode)
    return blankRow as MstCenterStockItemWithId
  }, [searchParam, loginUserInfo?.companyId, loginUserInfo?.userId])

  const setMstItemCol = useCallback((pRow: MstCenterStockItemWithId, orgItemCode: string | null | undefined) => {
    if (pRow) {
      const selectedItem = { ...items.find((orgId) => orgId.orgItemCode === orgItemCode) }
      // console.log("setMstItemCol", pRow, orgItemCode, items.length, selectedItem)
      Object.assign(pRow, {
        mstItemItemName: selectedItem ? selectedItem.itemName : null,
        mstItemSalesStartdate: selectedItem ? selectedItem.salesStartdate : null,
        mstItemSalesEnddate: selectedItem ? selectedItem.salesEnddate : null,
        mstItemLongside: selectedItem ? selectedItem.longside : null,
        mstItemShortside: selectedItem ? selectedItem.shortside : null,
        mstItemHeight: selectedItem ? selectedItem.height : null,
        mstItemWeight: selectedItem ? selectedItem.weight : null,
        mstItemSpec: selectedItem ? selectedItem.spec : null,
        mstItemMaxPalletQuantity: selectedItem ? selectedItem.maxPalletQuantity : null,
        mstItemPalletQuantityFace: selectedItem ? selectedItem.palletQuantityFace : null,
        mstItemPalletQuantityTier: selectedItem ? selectedItem.palletQuantityTier : null,
        mstItemJan: selectedItem ? selectedItem.jan : null,
        mstItemJanCase: selectedItem ? selectedItem.janCase : null,
        mstItemGtin13: selectedItem ? selectedItem.gtin13 : null,
        mstItemGtin14: selectedItem ? selectedItem.gtin14 : null,
        mstItemItf: selectedItem ? selectedItem.itf : null,
        mstItemSdp: selectedItem ? selectedItem.sdp : null,
        mstItemReplaceOrgItemCode: selectedItem ? selectedItem.replaceOrgItemCode : null,
        mstItemMakerGln: selectedItem ? selectedItem.makerGln : null,
        mstItemMakerName: selectedItem ? selectedItem.makerName : null,
        mstItemOrgSupplierId: selectedItem ? selectedItem.supplierName : null,
        mstItemMinOrderQuantity: selectedItem ? selectedItem.minOrderQuantity : null,
        mstItemOrderUnitType: selectedItem ? selectedItem.orderUnitType : null,
        mstItemCaseLot: selectedItem ? selectedItem.caseLot : null,
      })
    }
  }, [items])

  // 入力チェック
  const fieldValidate = (pRow: MstCenterStockItemWithId, field: string | undefined, newValue: any): boolean => {
    let hasErrors = false
    // console.log("fieldValidate", field, pRow)
    switch (field) {
      case 'orgItemCode':
      case 'orgCenterId':
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue))
        if (!hasErrors) {
          hasErrors = duplicateCheck(pRow, newValue)
        }
        break
      default:
        return false
    }
    return hasErrors
  }

  useEffect(() => {
    // 値変更時に、useStateの値を参照する処理を行う場合
    // まとめてコピペした場合など、1回しか発生しないので、変更行はまとめて設定する
    if (!rowChangeTriggerRowID) return
    // console.log("cellChangeTrigger", rowChangeTriggerRowID, items.length)
    const updatedRows: MstCenterStockItemWithId[] = [];
    gridRef.current?.api?.forEachNode((node) => {
      if (node?.data) {
        const trRow = node.data as MstCenterStockItemWithId
        // console.log("cellChangeTrigger",trRow.isEdited, `'${trRow.orgCenterId}'`,searchParam.orgItemCode)
        if (trRow.isEdited) {
          // 新規行にセンターIDが入っていない場合は、検索条件のセンターIDをセット
          if (!trRow.orgCenterId) {
            const searchCenterDefault = center.find((rowCenter) => rowCenter.centerId === searchParam.centerId)
            trRow.orgCenterId = searchCenterDefault?.orgCenterId || null
            if (trRow.orgItemCode) duplicateCheck(trRow, trRow.orgCenterId)
          }
          // 商品マスタの値を設定
          setMstItemCol(trRow, trRow.orgItemCode)
          // node.setData(trRow)
          updatedRows.push(trRow)
        }
      }
    })
    if (updatedRows.length > 0) gridRef.current?.api?.applyTransaction({ update: updatedRows })
    // 必要な処理が終わったら、NULLに戻すこと！
    setRowChangeTriggerRowID(null);
  }, [rowChangeTriggerRowID])

  const cellValueChanged = useCallback((event: CellValueChangedEvent<MstCenterStockItemWithId>, hasError: boolean) => {
    const { data, colDef, newValue, oldValue, node } = event
    const { field } = colDef
    // なぜか以下のconsole.logをコメントアウトすると、新規行にセンターID設定がうまく動かない・・・
    console.log('cellValueChanged', field, newValue, oldValue, data, hasError, items.length)
    const updatedRow = { ...data }
    if (field === 'orgItemCode') {
      // カスタムフックから直接コンポーネントの状態にアクセスすることはできないため、ここでsetMstItemColの処理をした場合、itemsが取れない
      // setMstItemCol(updatedRow, newValue)
      // node.setData(updatedRow)
      // setRowChangeTriggerRowID(updatedRow.id)
    } else if (field === "orgSupplierId") {
      // 仕入先コード変更時に、APIのエラーを解消
      checkAndSetError(updatedRow, "orgCenterId", null);
      checkAndSetError(updatedRow, "orgItemCode", null);
      // API以外のエラーを再度チェック
      fieldValidate(updatedRow, "orgCenterId", updatedRow.orgCenterId)
      fieldValidate(updatedRow, "orgItemCode", updatedRow.orgItemCode)
    }
  }, [items])

  const updateMstCenterStockItem = useCallback(
    async (updatedRows: MstCenterStockItemWithId[]) => {
      const datalist = updatedRows.map(({ isEdited, editedFields, startDate, errors, ...rest }) => ({
        ...rest,
        startDate: startDate ? convertDateToRequestParam(new Date(startDate)) : null,
      }))

      try {
        await fetchCenterStockItemUpdate({
          mstCenterStockItemUpdate: { body: datalist },
        })
        if (onReload) onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === 'error') {
          const gridApi = gridRef.current?.api

          err.errors.forEach((errrow: any) => {
            const { rowdata } = errrow
            const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id
            const orgItemCode = (rowdata as { org_item_code: string | null }).org_item_code
            const errRow = updatedRows.find(
              (rowdatas) => rowdatas.orgCenterId === orgCenterId && rowdatas.orgItemCode === orgItemCode
            )
            if (errRow) {
              // UpdateAPIのErrorは、PKをエラー表記して、サーバーから取得したエラーを表示する
              errRow.errors = {
                orgCenterId: [errrow.errorMessage],
                orgItemCode: [errrow.errorMessage],
              }
              const rowNode = gridApi?.getRowNode(errRow.id || '')
              if (rowNode) {
                rowNode.setData(errRow)
              }
            }
          })
        }
      }
    },
    [fetchCenterStockItemUpdate, searchParam]
  )

  const handleImportExcel = (data: any[]) => {
    let hasError = false
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCenterStockItemWithId> = {}

      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping]
          const importvalue = excelRow[key]
          let setvalue = importvalue || null
          if (['deleteFlg', 'stopShip', 'stopArrival'].indexOf(tableField) >= 0) {
            setvalue = parseBoolean(excelRow[key])
          } else if (['orderUnitType', 'stockDaysRank', 'safetyStockRank'].indexOf(tableField) >= 0) {
            const group = tableFieldToGroup[tableField]
            const typeList = orderSysTypeCodeLists[group]

            const codevalue = typeCodeParser(importvalue, typeList)
            setvalue = typeCodeParser(codevalue, typeList)
            if (!codevalue && importvalue) hasError = true
          } else if (tableField === 'startDate') {
            setvalue = parseDate(importvalue) as unknown as undefined
          } else if (['caseLot', 'minOrderQuantity', 'stockDaysSetting', 'minStockQuantity'].indexOf(tableField) >= 0) {
            setvalue = parsePositiveInteger(importvalue)
          } else if (['safetyStockSetting'].indexOf(tableField) >= 0) {
            setvalue = parseNumeric(importvalue)
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!isNonInputValue(importvalue) && setvalue === null) hasError = true
          mappedRow[tableField as keyof MstCenterStockItemWithId] = setvalue as unknown as undefined
        }
      })

      const { orgItemCode, orgCenterId } = mappedRow
      setMstItemCol(mappedRow as MstCenterStockItemWithId, orgItemCode)

      return mappedRow as MstCenterStockItemWithId
    })
    return {mappedData, hasError}
  }

  const {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    parseStringValue,
    parseTime,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    onReload,
    onUploadData,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateMstCenterStockItem,
    handleImportExcel,
    createBlankRow,
    fieldValidate,
    cellValueChanged,
    setRowChangeTriggerRowID,  
  })

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
      headerName: '自社センター',
      field: 'orgCenterId',
      rowDrag: true,
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null,
      },
      width: 300,
      suppressPaste: false,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) =>
      selectValueFormatter(params.value, center, 'orgCenterId', 'centerName'),
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) =>
          selectValueFormatter(params.value, center, 'orgCenterId', 'centerName'),
      },      
      valueParser: (params: ValueParserParams) => selectValueParser(params, center, 'orgCenterId'),
      pinned: 'left',
      cellEditorParams: editorDataReady
        ? ({
            values: [null, ...centerSelect],
            formatValue: (value: string) => selectValueFormatter(value, center, 'orgCenterId', 'centerName'),
            searchType: 'matchAny',
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
          } as IRichCellEditorParams)
        : { values: ['-'] },
    },
    {
      headerName: '社内商品コード',
      field: 'orgItemCode',
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null,
      },
      width: 300,
      suppressPaste: false,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) =>
        selectValueFormatter(params.value, items, 'orgItemCode', 'itemName'),
      valueParser: (params: ValueParserParams) => selectValueParser(params, items, 'orgItemCode'),
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) =>
          selectValueFormatter(params.value, items, 'orgItemCode', 'itemName'),
      },      
      pinned: 'left',
      cellEditorParams: editorDataReady
        ? ({
            values: [null, ...itemSelect],
            formatValue: (value: string) => selectValueFormatter(value, items, 'orgItemCode', 'itemName'),
            searchType: 'matchAny',
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
          } as IRichCellEditorParams)
        : { values: ['-'] },
    },
    {
      headerName: '商品マスタ',
      children: [
        {
          headerName: '商品名称',
          field: 'mstItemItemName',
        },
        {
          headerName: '発売日',
          field: 'mstItemSalesStartdate', // 発売日
          width: 200,
          minWidth: 200,
          maxWidth: 250,
          valueFormatter: dateFormatter,
        },
        {
          headerName: '終了日',
          field: 'mstItemSalesEnddate', // 終了日
          width: 200,
          minWidth: 200,
          maxWidth: 250,
          columnGroupShow: 'open',
          valueFormatter: dateFormatter,
        },
        {
          headerName: 'ケースサイズ 長手（mm）',
          field: 'mstItemLongside', // ケースサイズ 長手（mm）
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: (params) => formatDecimalValue(params, 2),
        },
        {
          headerName: 'ケースサイズ 短手（mm）',
          field: 'mstItemShortside', // ケースサイズ 短手（mm）
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: (params) => formatDecimalValue(params, 2),
        },
        {
          headerName: 'ケースサイズ 高さ（mm）',
          field: 'mstItemHeight', // ケースサイズ 高さ（mm）
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: (params) => formatDecimalValue(params, 2),
        },
        {
          headerName: 'ケースサイズ 重量（g）',
          field: 'mstItemWeight', // ケースサイズ 重量（g）
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: (params) => formatDecimalValue(params, 2),
        },
        {
          headerName: '規格',
          field: 'mstItemSpec',
          columnGroupShow: 'open',
        },
        {
          headerName: 'パレット最大積付数',
          field: 'mstItemMaxPalletQuantity', // パレット最大積付数
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: (params) => formatDecimalValue(params, 2),
        },
        {
          headerName: 'パレット積載数（面）',
          field: 'mstItemPalletQuantityFace', // パレット積載数（面）
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: 'パレット積載数（段）',
          field: 'mstItemPalletQuantityTier', // パレット積載数（段）
          columnGroupShow: 'open',
          cellClass: 'number-cell',
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: 'JANコード（バラ）',
          field: 'mstItemJan', // JANコード（バラ）
          columnGroupShow: 'open',
        },
        {
          headerName: 'JANコード（ケース）',
          field: 'mstItemJanCase', // JANコード（ケース）
        },
        {
          headerName: 'GTINコード（バラ）',
          field: 'mstItemGtin13', // GTINコード（バラ）
          columnGroupShow: 'open',
        },
        {
          headerName: 'GTINコード（ケース）',
          field: 'mstItemGtin14', // GTINコード（ケース）
          columnGroupShow: 'open',
        },
        {
          headerName: 'ITFコード',
          field: 'mstItemItf', // ITFコード
          columnGroupShow: 'open',
        },
        {
          headerName: 'SDPコード',
          field: 'mstItemSdp', // SDPコード
          columnGroupShow: 'open',
        },
        {
          headerName: '後継社内商品',
          field: 'mstItemReplaceOrgItemCode', // 後継社内商品
          columnGroupShow: 'open',
          valueFormatter: (params: ValueFormatterParams) =>
            selectValueFormatter(params.value, items, 'mstItemReplaceOrgItemCode', 'itemName'),
        },
        {
          headerName: 'メーカーGS1事業者コード',
          field: 'mstItemMakerGln', // メーカーGS1事業者コード
          columnGroupShow: 'open',
        },
        {
          headerName: 'メーカー名',
          field: 'mstItemMakerName', // メーカー名
          columnGroupShow: 'open',
        },
        {
          headerName: '仕入先',
          field: 'mstItemOrgSupplierId', // 仕入先
          width: 220,
          // valueFormatter: (params: ValueFormatterParams) => selectValueFormatter(params.value, supplier, "mstItemOrgSupplierId", "supplierName"),
        },
        {
          headerName: '発注単位',
          field: 'mstItemOrderUnitType', // 発注単位
          valueFormatter: (params: ValueFormatterParams) =>
            typecodeFormatter(params.value, orderSysTypeCodeLists['order_unit_type'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typecodeFormatter(params.value, orderSysTypeCodeLists['order_unit_type'!]),
          },      
        },
        {
          headerName: 'ケースロット数',
          field: 'mstItemCaseLot', // ケースロット数
          cellClass: 'number-cell',
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: '最低発注量',
          field: 'mstItemMinOrderQuantity', // 最低発注量
          cellClass: 'number-cell',
          valueFormatter: numberValueFormatter,
        },
      ],
    },
    {
      headerName: 'センター別発注設定',
      children: [
        {
          headerName: '発注単位',
          field: 'orderUnitType',
          editable: true,
          suppressPaste: false,
          cellEditor: 'agRichSelectCellEditor',
          valueFormatter: (params: ValueFormatterParams) =>
            typecodeFormatter(params.value, orderSysTypeCodeLists['order_unit_type'!]),
          valueParser: (params: ValueParserParams) =>
            selectTypecodeValueParser(params, orderSysTypeCodeLists['order_unit_type'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typecodeFormatter(params.value, orderSysTypeCodeLists['order_unit_type'!]),
          },      
          cellEditorParams: editorDataReady
            ? ({
                values: [null, ...orderSysTypeCodeSelects['order_unit_type'!]],
                formatValue: (value: string) =>
                  selectValueFormatter(value, orderSysTypeCodeLists['order_unit_type'!], 'typeCode', 'typeName'),
              } as IRichCellEditorParams)
            : { values: ['-'] },
        },
        {
          headerName: 'ケースロット数',
          field: 'caseLot',
          editable: true,
          cellClass: 'number-cell',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            step: 1,
            min: 0,
          },
          valueParser: numberValueParser,
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: '最低発注量',
          field: 'minOrderQuantity',
          editable: true,
          cellClass: 'number-cell',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            step: 1,
            min: 0,
          },
          valueParser: numberValueParser,
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: '出荷停止',
          field: 'stopShip',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '入荷停止',
          field: 'stopArrival',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '仕入先企業',
          field: 'orgSupplierId',
          editable: true,
          width: 220,
          suppressPaste: false,
          cellEditor: 'agRichSelectCellEditor',
          valueFormatter: (params: ValueFormatterParams) =>
            selectValueFormatter(params.value, supplier, 'orgSupplierId', 'supplierName'),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              selectValueFormatter(params.value, supplier, 'orgSupplierId', 'supplierName'),
          },      
          valueParser: (params: ValueParserParams) => selectValueParser(params, supplier, 'orgSupplierId'),
          cellEditorParams: editorDataReady
            ? ({
                values: [null, ...supplierSelect],
                formatValue: (value: string) => selectValueFormatter(value, supplier, 'orgSupplierId', 'supplierName'),
                searchType: 'match',
                allowTyping: true,
                filterList: true,
                highlightMatch: true,
              } as IRichCellEditorParams)
            : { values: ['-'] },
        },
      ],
    },
    {
      headerName: '在庫設定',
      children: [
        {
          headerName: '適用開始日',
          field: 'startDate',
          editable: true,
          width: 130,
          minWidth: 130,
          maxWidth: 250,
          cellEditor: 'agDateCellEditor',
          valueFormatter: dateFormatter,
          valueParser: parseDateValue,
        },
        {
          headerName: '在庫日数',
          field: 'stockDaysSetting',
          editable: true,
          cellClass: 'number-cell',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            step: 1,
            min: 0,
          },
          valueParser: numberValueParser,
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: '安全在庫',
          field: 'safetyStockSetting',
          editable: true,
          cellClass: 'number-cell',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            step: 1e-18,
            min: 0,
          },
          valueFormatter: (params) => formatDecimalValue(params, 3),
          valueParser: (params) => parseDecimalValue(params, 3),
        },
        {
          headerName: '在庫日数ランク',
          field: 'stockDaysRank',
          editable: true,
          cellClass: 'number-cell',
          suppressPaste: false,
          cellEditor: 'agRichSelectCellEditor',
          valueFormatter: (params: ValueFormatterParams) =>
            typecodeFormatter(params.value, orderSysTypeCodeLists['stock_days_rank'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typecodeFormatter(params.value, orderSysTypeCodeLists['stock_days_rank'!]),
          },      
          valueParser: (params: ValueParserParams) =>
            selectTypecodeValueParser(params, orderSysTypeCodeLists['stock_days_rank'!]),
          cellEditorParams: editorDataReady
            ? ({
                values: [null, ...orderSysTypeCodeSelects['stock_days_rank'!]],
                formatValue: (value: string) =>
                  selectValueFormatter(value, orderSysTypeCodeLists['stock_days_rank'!], 'typeCode', 'typeName'),
              } as IRichCellEditorParams)
            : { values: ['-'] },
        },
        {
          headerName: '安全在庫ランク',
          field: 'safetyStockRank',
          editable: true,
          cellClass: 'number-cell',
          suppressPaste: false,
          cellEditor: 'agRichSelectCellEditor',
          valueFormatter: (params: ValueFormatterParams) =>
            typecodeFormatter(params.value, orderSysTypeCodeLists['safety_stock_rank'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typecodeFormatter(params.value, orderSysTypeCodeLists['safety_stock_rank'!]),
          },
          valueParser: (params: ValueParserParams) =>
            selectTypecodeValueParser(params, orderSysTypeCodeLists['safety_stock_rank'!]),
          cellEditorParams: editorDataReady
            ? ({
                values: [null, ...orderSysTypeCodeSelects['safety_stock_rank'!]],
                formatValue: (value: string) =>
                  selectValueFormatter(value, orderSysTypeCodeLists['safety_stock_rank'!], 'typeCode', 'typeName'),
              } as IRichCellEditorParams)
            : { values: ['-'] },
        },
        {
          headerName: '最低在庫数',
          field: 'minStockQuantity',
          editable: true,
          cellClass: 'number-cell',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            step: 1,
            min: 0,
          },
          valueParser: numberValueParser,
          valueFormatter: numberValueFormatter,
        },
      ],
    },
    {
      headerName: '削除フラグ',
      children: [
        {
          headerName: '削除フラグ',
          field: 'deleteFlg', // 削除フラグ
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
      ],
    },
    {
      headerName: 'PSI計算項目',
      children: [
        {
          headerName: '商品コード',
          field: 'itemCode',
        },
        {
          headerName: '最新商品フラグ',
          field: 'recencyFlg',
          cellRenderer: 'agCheckboxCellRenderer',
        },
      ],
    },
  ]
  const columnDefs = useMemo<ColDef[]>(updateColumnDefs, [row, editorDataReady])

  // ダウンロードファイル用のカラム定義
  // ダウンロードファイル用のカラム定義
  useEffect(() => {
    if (setDownloadColumnDefs)
      setDownloadColumnDefs([
          { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
          { headerName: '自社センター', children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }] },
          { headerName: '社内商品コード', children: [{ headerName: 'org_item_code', field: 'orgItemCode', cellClass: 'stringType' }] },
          { headerName: '適用開始日', children: [{ headerName: 'start_date', field: 'startDate', valueFormatter: dateFormatter }] },
          { headerName: '在庫日数', children: [{ headerName: 'stock_days_setting', field: 'stockDaysSetting', cellClass: 'numberType' }] },
          { headerName: '安全在庫', children: [{ headerName: 'safety_stock_setting', field: 'safetyStockSetting', cellClass: 'decimalType' }] },
          { headerName: '在庫日数ランク', children: [{ headerName: 'stock_days_rank', field: 'stockDaysRank', cellClass: 'stringType' }] },
          { headerName: '安全在庫ランク', children: [{ headerName: 'safety_stock_rank', field: 'safetyStockRank', cellClass: 'stringType' }] },
          { headerName: '最低在庫数', children: [{ headerName: 'min_stock_quantity', field: 'minStockQuantity', cellClass: 'numberType' }] },
          { headerName: '出荷停止', children: [{ headerName: 'stop_ship', field: 'stopShip', cellClass: 'booleanType' }] },
          { headerName: '入荷停止', children: [{ headerName: 'stop_arrival', field: 'stopArrival', cellClass: 'booleanType' }] },
          { headerName: '仕入先企業', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }] },
          { headerName: '発注単位', children: [{ headerName: 'order_unit_type', field: 'orderUnitType', cellClass: 'stringType' }] },
          { headerName: 'ケースロット数', children: [{ headerName: 'case_lot', field: 'caseLot', cellClass: 'numberType' }] },
          { headerName: '最低発注量', children: [{ headerName: 'min_order_quantity', field: 'minOrderQuantity', cellClass: 'numberType' }] },
          { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
          { headerName: '商品コード', children: [{ headerName: 'item_code', field: 'itemCode', cellClass: 'stringType' }] },
          { headerName: '最新商品フラグ', children: [{ headerName: 'recency_flg', field: 'recencyFlg', cellClass: 'booleanType' }] },
        ])
    },[setDownloadColumnDefs])

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow()
      setRow([blankRow])
    } else {
      const datalist = list.list.map((item) => ({ ...item, ...initRecordEditEx() }))
      datalist.forEach((item) => {
        setMstItemCol(item as MstCenterStockItemWithId, item.orgItemCode)
      })
      setRow(datalist)
    }
  }, [list.list])

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            key={`${row[0]?.companyId}-${editorDataReady}`}
            rowData={row}
            ref={gridRef}
            getRowId={(params) => params.data.id}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            autoSizeStrategy={autoSizeStrategy}
            loadingOverlayComponent={LoadingOverlay}
          />
        </div>
      </div>
    </div>
  )
}

export default DataList
