/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCenterStockItemUpdateBase
 */
export interface MstCenterStockItemUpdateBase {
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItemUpdateBase
     */
    deleteFlg?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItemUpdateBase
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItemUpdateBase
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    updateUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItemUpdateBase
     */
    deleteDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    deleteUser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    centerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    orgCenterId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    orgItemCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    orgSupplierId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    stockDaysSetting?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    safetyStockSetting?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    minStockQuantity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    stockDaysRank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    safetyStockRank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItemUpdateBase
     */
    orderUnitType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    caseLot?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItemUpdateBase
     */
    minOrderQuantity?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItemUpdateBase
     */
    startDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItemUpdateBase
     */
    stopShip?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItemUpdateBase
     */
    stopArrival?: boolean | null;
}

/**
 * Check if a given object implements the MstCenterStockItemUpdateBase interface.
 */
export function instanceOfMstCenterStockItemUpdateBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MstCenterStockItemUpdateBaseFromJSON(json: any): MstCenterStockItemUpdateBase {
    return MstCenterStockItemUpdateBaseFromJSONTyped(json, false);
}

export function MstCenterStockItemUpdateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCenterStockItemUpdateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : json['delete_flg'],
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
        'deleteDatetime': !exists(json, 'delete_datetime') ? undefined : (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': !exists(json, 'delete_user') ? undefined : json['delete_user'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'centerId': !exists(json, 'center_id') ? undefined : json['center_id'],
        'orgCenterId': !exists(json, 'org_center_id') ? undefined : json['org_center_id'],
        'orgItemCode': !exists(json, 'org_item_code') ? undefined : json['org_item_code'],
        'orgSupplierId': !exists(json, 'org_supplier_id') ? undefined : json['org_supplier_id'],
        'stockDaysSetting': !exists(json, 'stock_days_setting') ? undefined : json['stock_days_setting'],
        'safetyStockSetting': !exists(json, 'safety_stock_setting') ? undefined : json['safety_stock_setting'],
        'minStockQuantity': !exists(json, 'min_stock_quantity') ? undefined : json['min_stock_quantity'],
        'stockDaysRank': !exists(json, 'stock_days_rank') ? undefined : json['stock_days_rank'],
        'safetyStockRank': !exists(json, 'safety_stock_rank') ? undefined : json['safety_stock_rank'],
        'orderUnitType': !exists(json, 'order_unit_type') ? undefined : json['order_unit_type'],
        'caseLot': !exists(json, 'case_lot') ? undefined : json['case_lot'],
        'minOrderQuantity': !exists(json, 'min_order_quantity') ? undefined : json['min_order_quantity'],
        'startDate': !exists(json, 'start_date') ? undefined : (json['start_date'] === null ? null : new Date(json['start_date'])),
        'stopShip': !exists(json, 'stop_ship') ? undefined : json['stop_ship'],
        'stopArrival': !exists(json, 'stop_arrival') ? undefined : json['stop_arrival'],
    };
}

export function MstCenterStockItemUpdateBaseToJSON(value?: MstCenterStockItemUpdateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': value.deleteDatetime === undefined ? undefined : (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'company_id': value.companyId,
        'center_id': value.centerId,
        'org_center_id': value.orgCenterId,
        'org_item_code': value.orgItemCode,
        'org_supplier_id': value.orgSupplierId,
        'stock_days_setting': value.stockDaysSetting,
        'safety_stock_setting': value.safetyStockSetting,
        'min_stock_quantity': value.minStockQuantity,
        'stock_days_rank': value.stockDaysRank,
        'safety_stock_rank': value.safetyStockRank,
        'order_unit_type': value.orderUnitType,
        'case_lot': value.caseLot,
        'min_order_quantity': value.minOrderQuantity,
        'start_date': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString().substring(0,10)),
        'stop_ship': value.stopShip,
        'stop_arrival': value.stopArrival,
    };
}

