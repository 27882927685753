/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MstCustomerCenterUpdateBase } from './MstCustomerCenterUpdateBase';
import {
    MstCustomerCenterUpdateBaseFromJSON,
    MstCustomerCenterUpdateBaseFromJSONTyped,
    MstCustomerCenterUpdateBaseToJSON,
} from './MstCustomerCenterUpdateBase';

/**
 * 
 * @export
 * @interface MstCustomerCenterUpdate
 */
export interface MstCustomerCenterUpdate {
    /**
     * 
     * @type {Array<MstCustomerCenterUpdateBase>}
     * @memberof MstCustomerCenterUpdate
     */
    body: Array<MstCustomerCenterUpdateBase>;
}

/**
 * Check if a given object implements the MstCustomerCenterUpdate interface.
 */
export function instanceOfMstCustomerCenterUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function MstCustomerCenterUpdateFromJSON(json: any): MstCustomerCenterUpdate {
    return MstCustomerCenterUpdateFromJSONTyped(json, false);
}

export function MstCustomerCenterUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCustomerCenterUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': ((json['body'] as Array<any>).map(MstCustomerCenterUpdateBaseFromJSON)),
    };
}

export function MstCustomerCenterUpdateToJSON(value?: MstCustomerCenterUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': ((value.body as Array<any>).map(MstCustomerCenterUpdateBaseToJSON)),
    };
}

