import { useEffect, useCallback, useState, useRef, type default as React } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom, FileDownloadData } from 'components/commonparts'
import { SearchCondition, DataList } from 'components/parts/MasterItem';
import { useItem } from 'hooks/useItems';
import { useSupplier } from 'hooks/useSupplier';
import { useTypecode } from 'hooks/useTypecode';
import { useSearchParamStore } from 'store/searchParamStore';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';

import type { GetMstItemsItemGetRequest, MstItem, ResponseResult } from 'api-client';

function MasterItem() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)
  const localOrgSupplierId = localSearchParam?.item.orgSupplierId || ''

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstItemsItemGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    orgSupplierId: localOrgSupplierId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstItem[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')
  const [downloadColDef, setDownloadColDef] = useState<any[] | undefined>([]);

  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { result, items, fetchItems, isLoading, } = useItem()
  const { supplierSearch, fetchSupplierSearch } = useSupplier()
  const { typeCode, fetchTypeCode } = useTypecode()
  const [searchParam, setSearchParam] = useState<GetMstItemsItemGetRequest>(initSearchParam)
  const isFirstRender = useRef(true);
  const [updateTrigger, setUpdateTrigger] = useState<boolean | undefined>(undefined);
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstItemsItemGetRequest) => {

    try {
        fetchItems({
          enabled: false,
          ...requestParam,
          pageNo: 0,
        });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstItemsItemGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'Item',
            {
              ...requestParam,
            }
          );
        }
        setSearchParam(requestParam);
        setHasGridEdited(false)

      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [items]);

  const handleRegister = () => {
    setUpdateTrigger((prev) => !prev);
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender.current) {
        isFirstRender.current = false
        const itemsPromise = fetchItems({
          enabled: false,
          ...searchParam,
        });
        const supplierPromise = fetchSupplierSearch({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        const typeCodePromise = fetchTypeCode();
        await Promise.all([itemsPromise, supplierPromise, typeCodePromise]);
      }
    }
    firstTimeRender()
  }, [])

  const generateDownloadData = (): MstItem[] => {
    if (!items?.list || items.list.length === 0) {
      return [{} as MstItem]
    }
    const downloadData: MstItem[] = [...items.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }


  return (
    <Box>
      <Typography component="div" variant="largeBold">
        商品マスタ
      </Typography>
      <MasterSearchFrom
        result={resultMessage}
        onDoUpdate={handleRegister}
        onReload={onReload}
        onImportData={handleUploadData}
        onFileDownLoad={handleFileDownload}
        discardChangeDialogOpen={discardChangeDialogOpen}
        setDownloadMode={setDownloadMode}
        hasDataEdited={hasGridEdited}
        importRequiredFields={["org_item_code"]}
        headerJp={["社内商品コード"]}
        lastUpdateDatetime={items?.lastUpdateDatetime}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>
      <FileDownloadData fileName="商品マスタ" downloadData={downloadGridRows} downloadMode={downloadMode} columnDefs={downloadColDef} />
      <DataList
          list={items}
          onReload={onReload}
          supplier={supplierSearch.list}
          systype={typeCode}
          isLoading={isLoading}
          searchParam={initSearchParam}
          onUploadData={uploadData}
          updateTrigger={updateTrigger}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
          setDownloadColumnDefs={setDownloadColDef}
        />
    </Box>
          
  )
}

export default MasterItem;
