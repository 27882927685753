import React, { useCallback, useEffect, useState,useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom, FileDownloadData } from 'components/commonparts'
import { SearchCondition, DataList } from 'components/parts/MasterCalender';
import { useCalendar } from 'hooks/useCalendar';
import { useCenter } from 'hooks/useCenter';
import { useSearchParamStore } from 'store/searchParamStore';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';

import type { GetMstCalendarCalendarGetRequest, MstCalendar, ResponseResult } from 'api-client';

function MasterCalendar() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)
  const localOrgCenterId = localSearchParam?.calendar?.orgCenterId || '' 

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCalendarCalendarGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    orgCenterId: localOrgCenterId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCalendar[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')
  const [downloadColDef, setDownloadColDef] = useState<any[] | undefined>([]);

  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const { centerSearch, fetchCenterSearch } = useCenter()

  const { calendarSearch, fetchCalendarSearch, calendarSearchResult, isLoading } = useCalendar()
  const [searchParam, setSearchParam] = useState<GetMstCalendarCalendarGetRequest>(initSearchParam)
  const isFirstRender = useRef(true);
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<boolean | undefined>(undefined);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setResultMessage(calendarSearchResult)
  }, [calendarSearchResult])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstCalendarCalendarGetRequest) => {
    try {
      fetchCalendarSearch({
        enabled: false,
          ...requestParam,
      });
  
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstCalendarCalendarGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'Calendar',
            {
              ...requestParam,
            }
          );
        }
  
        setSearchParam(requestParam);
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    }, [
      hasGridEdited,
      setDiscardChangeDialogOpen,
      fetchCalendarSearch,
      loginUserInfo,
      setSearchParamState,
      setSearchParam,
      setHasGridEdited
  ]);

  const handleRegister = () => {
    setUpdateTrigger((prev) => !prev);
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
    setUploadData(mappedData); 
  };
  const onReload = () => {
    // 検索処理の実行を行うトリガー
    // 子コンポーネントからの検索命令⇒検索条件の検索を実行⇒doSearchがコールされる
    setSearchTrigger((prev) => !prev);
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender.current) {
        isFirstRender.current = false
        
        const calendarPromise = fetchCalendarSearch({
          enabled: false,
          ...searchParam,
        });
        const centerPromise = fetchCenterSearch({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        await Promise.all([calendarPromise, centerPromise]);
      }
    }
    firstTimeRender();
  }, [isFirstRender, searchParam]);

  const generateDownloadData = (): MstCalendar[] => {
    if (!calendarSearch?.list || calendarSearch.list.length === 0) {
      return [{} as MstCalendar]
    }
    const downloadData: MstCalendar[] = [...calendarSearch.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }


  return (
    <Box>
      <Typography component="div" variant="largeBold">
      カレンダー登録
      </Typography>
      <MasterSearchFrom
          onReload={onReload}
          result={resultMessage}
          onDoUpdate={handleRegister}
          onImportData={handleUploadData}
          onFileDownLoad={handleFileDownload}
          discardChangeDialogOpen={discardChangeDialogOpen}
          setDownloadMode={setDownloadMode}
          importRequiredFields={["org_center_id","calendar_date"]}
          headerJp={["自社センターID","日付"]}
          lastUpdateDatetime={calendarSearch?.lastUpdateDatetime}
          hasDataEdited={hasGridEdited}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>
      <FileDownloadData fileName="カレンダーマスタ" downloadData={downloadGridRows} downloadMode={downloadMode} columnDefs={downloadColDef} />
      <DataList
          list={calendarSearch}
          onReload={onReload}
          center={centerSearch.list}
          isLoading={isLoading}
          searchParam={searchParam}
          onUploadData={uploadData}
          updateTrigger={updateTrigger}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
          setDownloadColumnDefs={setDownloadColDef}
        />
      
    </Box>
          
  )
}

export default MasterCalendar;
