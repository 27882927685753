/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MasterItemUpdateBase
 */
export interface MasterItemUpdateBase {
    /**
     * 
     * @type {boolean}
     * @memberof MasterItemUpdateBase
     */
    deleteFlg?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MasterItemUpdateBase
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MasterItemUpdateBase
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    updateUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MasterItemUpdateBase
     */
    deleteDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    deleteUser?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    orgItemCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    itemName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MasterItemUpdateBase
     */
    salesStartdate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MasterItemUpdateBase
     */
    salesEnddate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    longside?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    shortside?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    weight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    spec?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    maxPalletQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    palletQuantityFace?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    palletQuantityTier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    jan?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    janCase?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    gtin13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    gtin14?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    itf?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    sdp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    replaceOrgItemCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    makerGln?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    makerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    orgSupplierId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MasterItemUpdateBase
     */
    orderUnitType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    caseLot?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    minOrderQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MasterItemUpdateBase
     */
    sortOrder?: number | null;
}

/**
 * Check if a given object implements the MasterItemUpdateBase interface.
 */
export function instanceOfMasterItemUpdateBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterItemUpdateBaseFromJSON(json: any): MasterItemUpdateBase {
    return MasterItemUpdateBaseFromJSONTyped(json, false);
}

export function MasterItemUpdateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterItemUpdateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : json['delete_flg'],
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
        'deleteDatetime': !exists(json, 'delete_datetime') ? undefined : (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': !exists(json, 'delete_user') ? undefined : json['delete_user'],
        'orgItemCode': !exists(json, 'org_item_code') ? undefined : json['org_item_code'],
        'itemName': !exists(json, 'item_name') ? undefined : json['item_name'],
        'salesStartdate': !exists(json, 'sales_startdate') ? undefined : (json['sales_startdate'] === null ? null : new Date(json['sales_startdate'])),
        'salesEnddate': !exists(json, 'sales_enddate') ? undefined : (json['sales_enddate'] === null ? null : new Date(json['sales_enddate'])),
        'longside': !exists(json, 'longside') ? undefined : json['longside'],
        'shortside': !exists(json, 'shortside') ? undefined : json['shortside'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'spec': !exists(json, 'spec') ? undefined : json['spec'],
        'maxPalletQuantity': !exists(json, 'max_pallet_quantity') ? undefined : json['max_pallet_quantity'],
        'palletQuantityFace': !exists(json, 'pallet_quantity_face') ? undefined : json['pallet_quantity_face'],
        'palletQuantityTier': !exists(json, 'pallet_quantity_tier') ? undefined : json['pallet_quantity_tier'],
        'jan': !exists(json, 'jan') ? undefined : json['jan'],
        'janCase': !exists(json, 'jan_case') ? undefined : json['jan_case'],
        'gtin13': !exists(json, 'gtin_13') ? undefined : json['gtin_13'],
        'gtin14': !exists(json, 'gtin_14') ? undefined : json['gtin_14'],
        'itf': !exists(json, 'itf') ? undefined : json['itf'],
        'sdp': !exists(json, 'sdp') ? undefined : json['sdp'],
        'replaceOrgItemCode': !exists(json, 'replace_org_item_code') ? undefined : json['replace_org_item_code'],
        'makerGln': !exists(json, 'maker_gln') ? undefined : json['maker_gln'],
        'makerName': !exists(json, 'maker_name') ? undefined : json['maker_name'],
        'orgSupplierId': !exists(json, 'org_supplier_id') ? undefined : json['org_supplier_id'],
        'orderUnitType': !exists(json, 'order_unit_type') ? undefined : json['order_unit_type'],
        'caseLot': !exists(json, 'case_lot') ? undefined : json['case_lot'],
        'minOrderQuantity': !exists(json, 'min_order_quantity') ? undefined : json['min_order_quantity'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
    };
}

export function MasterItemUpdateBaseToJSON(value?: MasterItemUpdateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': value.deleteDatetime === undefined ? undefined : (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'org_item_code': value.orgItemCode,
        'item_name': value.itemName,
        'sales_startdate': value.salesStartdate === undefined ? undefined : (value.salesStartdate === null ? null : value.salesStartdate.toISOString()),
        'sales_enddate': value.salesEnddate === undefined ? undefined : (value.salesEnddate === null ? null : value.salesEnddate.toISOString()),
        'longside': value.longside,
        'shortside': value.shortside,
        'height': value.height,
        'weight': value.weight,
        'spec': value.spec,
        'max_pallet_quantity': value.maxPalletQuantity,
        'pallet_quantity_face': value.palletQuantityFace,
        'pallet_quantity_tier': value.palletQuantityTier,
        'jan': value.jan,
        'jan_case': value.janCase,
        'gtin_13': value.gtin13,
        'gtin_14': value.gtin14,
        'itf': value.itf,
        'sdp': value.sdp,
        'replace_org_item_code': value.replaceOrgItemCode,
        'maker_gln': value.makerGln,
        'maker_name': value.makerName,
        'org_supplier_id': value.orgSupplierId,
        'order_unit_type': value.orderUnitType,
        'case_lot': value.caseLot,
        'min_order_quantity': value.minOrderQuantity,
        'sort_order': value.sortOrder,
    };
}

