import { useEffect, useState, type Dispatch, type SetStateAction, type default as React } from 'react';

import { Grid, Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchButton, SelectSupplier } from 'components/commonparts'

import type { GetMstItemsItemGetRequest, Item, MstItem, ResponseResult } from 'api-client'

interface SearchConditionProps {
  searchParam: GetMstItemsItemGetRequest
  onSearch: (requestParam: GetMstItemsItemGetRequest) => void
  hasDataEdited: boolean  // 編集中のデータがあるか？
  setDiscardChangeDialogOpen: Dispatch<SetStateAction<boolean>>,
  searchTrigger?: boolean // 親コンポーネントから検索実行された場合に値が変化する
}

function SearchCondition({
  searchParam, 
  onSearch, 
  hasDataEdited=false,
  setDiscardChangeDialogOpen,
  searchTrigger,
}: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()
  const [orgSupplierId, setOrgSupplierId] = useState(searchParam.orgSupplierId)

  const onSearchClick=()=>{
    if (hasDataEdited) {
      setDiscardChangeDialogOpen((prev) => !prev)
      return
    }

    onSearch({
      companyId: loginUserInfo!.companyId,
      orgSupplierId,
    })      
  }

  useEffect(() => {
    // searchTriggerの変更を検知して、検索イベントを起こす
    // 親コンポーネントなどで検索を行いたい場合は、このトリガーを使用する
    onSearch({
      companyId: loginUserInfo!.companyId,
      orgSupplierId,
    })   
  }, [searchTrigger])

  return (
    <Grid container>
      <SelectSupplier
        trCompanyId={searchParam.companyId}
        orgSupplierId={orgSupplierId}
        setOrgSupplierId={setOrgSupplierId}
        setSupplierInfo={() => {}}
        isRequred={false}
        setIsValid={() => {}}
        enabled={false}
        />
      <Box display="flex" justifyContent="center" alignItems="end" marginBottom='5px' marginRight='10px'>
          <SearchButton searchId="item" onSearchClick={onSearchClick}/>
      </Box>
    </Grid>
  )
}

export default SearchCondition
