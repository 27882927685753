import React, { type Dispatch, type SetStateAction } from 'react';

import type { ResponseResult, ResponseUpdate } from 'api-client'
// import type {ColDef,
//   ColGroupDef,
//   ExcelDataType,
// } from 'ag-grid-community'

// AGGridを用いたデータ編集時に、APIのインターフェイスに追加する編集管理項目
export interface RecordEditEx {
    id: string | null
    isEdited: boolean
    errors: Record<string, string[]>
    editedFields: Set<string>,
    isNewRecord: boolean,
  }
  export const initRecordEditEx = (isNewRow=false): RecordEditEx => ({
    id: Math.random().toString(32).substring(2),
    isEdited: false,
    errors: {},
    editedFields: new Set<string>(),
    isNewRecord: isNewRow,
  })
  
  export interface DataListPropsBase {
    list?: any;
    isLoading: boolean;
    onReload?: () => void;
    onUploadData?: any[];
    searchParam?: any
    updateTrigger?: boolean // 親コンポーネントから登録ボタンを押下された場合に値が変化する
    // setHasGridEdited: (value: boolean) => void,
    // setResultMessage: (value: ResponseResult | undefined) => void,
    downloadColumnDefs?: (coldef: any[] | undefined) => void;
    setHasGridEdited: Dispatch<SetStateAction<boolean>>,
    setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>,
    setDownloadColumnDefs?: Dispatch<SetStateAction<any[] | undefined>>,
  }
  
  
 