import { useCallback, useEffect, useRef, useState, type default as React } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom, FileDownloadData } from 'components/commonparts'
import { SearchCondition, DataList } from 'components/parts/MasterCenterSupplier';
import { useCenter } from 'hooks/useCenter';
import { useCenterSupplier } from 'hooks/useCenterSupplier';
import { useSupplier } from 'hooks/useSupplier';
import { useTypecode } from 'hooks/useTypecode';
import { useSearchParamStore } from 'store/searchParamStore';
import { initDataListStatus, type DataListStatus } from 'types/types';
import { sortToRequestParam } from 'utils/sortToRequestParam';

import type { GetMstCenterSupplierCenterSupplierGetRequest, MstCenterSupplier, ResponseResult } from 'api-client';

function MasterCenterSupplier() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCenterSupplierCenterSupplierGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCenterSupplier[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')
  const [downloadColDef, setDownloadColDef] = useState<any[] | undefined>([]);
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { centerSupplierSearchResult, centerSupplierSearch , fetchCenterSupplierSearch, centerSupplierSearchIsLoading } = useCenterSupplier()
  const { fetchSupplierSearch, supplierSearch } = useSupplier()
  const { fetchCenterSearch, centerSearch } = useCenter()
  const { fetchTypeCode, typeCode } = useTypecode()
  const [searchParam, setSearchParam] = useState<GetMstCenterSupplierCenterSupplierGetRequest>(initSearchParam)
  const isFirstRender = useRef(true);

  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<boolean | undefined>(undefined);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)
  
  useEffect(() => {
    setResultMessage(centerSupplierSearchResult)
  }, [centerSupplierSearchResult])
  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstCenterSupplierCenterSupplierGetRequest) => {
    try {
      fetchCenterSupplierSearch({
        enabled: false,
          ...requestParam,
        });
        // fetchSupplier({
        //   companyId: requestParam.companyId, 
        // });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstCenterSupplierCenterSupplierGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'CenterSupplier',
            {
              ...requestParam,
            }
          );
        }
  
        setSearchParam(requestParam); 
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [hasGridEdited,
      setDiscardChangeDialogOpen,
      fetchCenterSupplierSearch,
      loginUserInfo,
      setSearchParamState,
      setSearchParam,
      setHasGridEdited]);

  const handleRegister = () => {
    setUpdateTrigger((prev) => !prev);
  }

  // const [downloadColDef, setDownloadColDef] = useState<any[]>([]);
  // const downloadColumnDefs = (coldef:any[] | undefined) => {
  //   if (coldef) setDownloadColDef(coldef);
  // }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender.current) {
        isFirstRender.current = false
        const itemsCenterStockPromise = fetchCenterSupplierSearch({
          enabled: false,
          ...searchParam,
        });
        const centerPromise = fetchCenterSearch({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        const supplierPromise = fetchSupplierSearch({
          enabled: false,
          companyId: searchParam.companyId, 
        });
        const typePromise = fetchTypeCode();
        await Promise.all([itemsCenterStockPromise, centerPromise, supplierPromise, typePromise]);
      }
    }
    firstTimeRender()
  }, [])

  const generateDownloadData = (): MstCenterSupplier[] => {
    if (!centerSupplierSearch?.list || centerSupplierSearch.list.length === 0) {
      return [{} as MstCenterSupplier]
    }
    const downloadData: MstCenterSupplier[] = [...centerSupplierSearch.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        センター仕入先
      </Typography>
      <MasterSearchFrom
          result={resultMessage}
          onDoUpdate={handleRegister}
          onReload={onReload}
          onImportData={handleUploadData}
          onFileDownLoad={handleFileDownload}
          discardChangeDialogOpen={discardChangeDialogOpen}
          setDownloadMode={setDownloadMode}
          hasDataEdited={hasGridEdited}
          importRequiredFields={["org_center_id", "org_supplier_id"]}
          headerJp={["自社センターID", "仕入先企業ID"]}
          lastUpdateDatetime={centerSupplierSearch?.lastUpdateDatetime}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>
      <FileDownloadData fileName="センター仕入先" downloadData={downloadGridRows} downloadMode={downloadMode} columnDefs={downloadColDef} />
        <DataList
          list={centerSupplierSearch}
          center={centerSearch.list}
          supplier={supplierSearch.list}
          systype={typeCode}
          onReload={onReload}
          isLoading={centerSupplierSearchIsLoading}
          searchParam={initSearchParam}
          onUploadData={uploadData}
          updateTrigger={updateTrigger}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
          setDownloadColumnDefs={setDownloadColDef}
        />
      
    </Box>
          
  )
}

export default MasterCenterSupplier;
