export const validateRequired = (value: any): string => {
    if (value === undefined || value === null || value === '') {
      return 'は必須項目です';
    }
    return '';
};

export const validateValue = (value: any): string => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;

  if (trimmedValue === undefined || trimmedValue === null || trimmedValue === '') {
    return 'は必須項目です';
  }

  return '';
};

export const validateDuplicate = (value: string | number | undefined, id: string, dataRow: any[]): string => {
    const isDuplicate = dataRow.some(row => row.orgItemCode === value && row.id !== id);
    if (isDuplicate) {
      return '入力値はすでに登録されています';
    }
    return '';
};
  
export const validate4to13Length = (value: string | number | undefined): string[] => {
  const errors: string[] = [];
  const stringValue = value !== undefined ? String(value) : '';
  const regex4to13 = /^[0-9]{4,13}$/;

  if (value === '' || value === null) {
    return errors;
  } 
  
  if (!regex4to13.test(stringValue)) {
    errors.push('は4桁以上13桁以下の数字を入力してください'); 
  }
  
  return errors;
};

export const validate4to14Length = (value: string | number | undefined): string[] => {
  const errors: string[] = [];
  const stringValue = value !== undefined ? String(value) : '';
  const regex4to14 = /^[0-9]{4,14}$/;

  if (value === '' || value === null) {
    return errors;
  } 
  
  if (!regex4to14.test(stringValue)) {
    errors.push('は4桁以上14桁以下の数字を入力してください'); 
  }
  
  return errors;
};

export const validate6to10Length = (value: string | number | undefined): string[] => {
  const errors: string[] = [];
  
  const stringValue = value !== undefined ? String(value) : '';
    if (value === '' || value === null) {
    return errors;
  } 

  const regex6to10 = /^[0-9]{6,10}$/;
  if (!regex6to10.test(stringValue)) {
    errors.push('は6桁以上10桁以下の数字を入力してください。');
  }

  return errors;
};
