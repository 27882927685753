/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCustomerUpdateBase
 */
export interface MstCustomerUpdateBase {
    /**
     * 
     * @type {boolean}
     * @memberof MstCustomerUpdateBase
     */
    deleteFlg?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCustomerUpdateBase
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCustomerUpdateBase
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    updateUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCustomerUpdateBase
     */
    deleteDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    deleteUser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCustomerUpdateBase
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCustomerUpdateBase
     */
    customerCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    orgCustomerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    customerHojinNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    customerAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerUpdateBase
     */
    customerGln?: string | null;
}

/**
 * Check if a given object implements the MstCustomerUpdateBase interface.
 */
export function instanceOfMstCustomerUpdateBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MstCustomerUpdateBaseFromJSON(json: any): MstCustomerUpdateBase {
    return MstCustomerUpdateBaseFromJSONTyped(json, false);
}

export function MstCustomerUpdateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCustomerUpdateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : json['delete_flg'],
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
        'deleteDatetime': !exists(json, 'delete_datetime') ? undefined : (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': !exists(json, 'delete_user') ? undefined : json['delete_user'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'customerCompanyId': !exists(json, 'customer_company_id') ? undefined : json['customer_company_id'],
        'orgCustomerId': !exists(json, 'org_customer_id') ? undefined : json['org_customer_id'],
        'customerName': !exists(json, 'customer_name') ? undefined : json['customer_name'],
        'customerHojinNo': !exists(json, 'customer_hojin_no') ? undefined : json['customer_hojin_no'],
        'customerAddress': !exists(json, 'customer_address') ? undefined : json['customer_address'],
        'customerGln': !exists(json, 'customer_gln') ? undefined : json['customer_gln'],
    };
}

export function MstCustomerUpdateBaseToJSON(value?: MstCustomerUpdateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': value.deleteDatetime === undefined ? undefined : (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'company_id': value.companyId,
        'customer_company_id': value.customerCompanyId,
        'org_customer_id': value.orgCustomerId,
        'customer_name': value.customerName,
        'customer_hojin_no': value.customerHojinNo,
        'customer_address': value.customerAddress,
        'customer_gln': value.customerGln,
    };
}

