import { useEffect, useState, type Dispatch, type SetStateAction, type default as React } from 'react';

import { Grid, Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchButton, SelectCenter } from 'components/commonparts'

import type { GetMstCalendarCalendarGetRequest } from 'api-client'

interface SearchConditionProps {
  hasDataEdited: boolean
  searchParam: GetMstCalendarCalendarGetRequest
  onSearch: (requestParam: GetMstCalendarCalendarGetRequest) => void
  setDiscardChangeDialogOpen: Dispatch<SetStateAction<boolean>>,
  searchTrigger?: boolean // 親コンポーネントから検索実行された場合に値が変化する
}

function SearchCondition({
  searchParam, 
  onSearch, 
  hasDataEdited=false,
  setDiscardChangeDialogOpen,
  searchTrigger,
}: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [orgCenterId, setOrgCenterId] = useState<string | null | undefined>(searchParam.orgCenterId)

  const onSearchClick=()=>{
    if (hasDataEdited) {
      setDiscardChangeDialogOpen((prev) => !prev)
      return
    }

    onSearch({
      companyId: loginUserInfo!.companyId,
      orgCenterId,
    })      
  }

  useEffect(() => {
    // searchTriggerの変更を検知して、検索イベントを起こす
    // 親コンポーネントなどで検索を行いたい場合は、このトリガーを使用する
    onSearch({
      companyId: loginUserInfo!.companyId,
      orgCenterId,
    })   
  }, [searchTrigger])

  return (


        <Grid container>
          <SelectCenter
            trCompanyId={searchParam.companyId}
            orgCenterId={orgCenterId}
            setOrgCenterId={setOrgCenterId}
            setCenterInfo={() => {}}
            isRequired={false}
            setIsValid={() => {}}
            enabled={false}
          />
          <Box display="flex" justifyContent="center" alignItems="end" marginBottom='5px'>
              <SearchButton searchId="Calendar" onSearchClick={onSearchClick}/>
          </Box>
        </Grid>
  )
}

export default SearchCondition
