/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCenterStockItem
 */
export interface MstCenterStockItem {
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItem
     */
    deleteFlg: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItem
     */
    insertDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    insertUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItem
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    updateUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItem
     */
    deleteDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    deleteUser: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    centerId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    itemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    orgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    supplierCompanyId: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItem
     */
    recencyFlg: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    stockDaysSetting: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    safetyStockSetting: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    stockDaysRank: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    safetyStockRank: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    minStockQuantity: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    orderUnitType: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    caseLot: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    minOrderQuantity: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItem
     */
    startDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItem
     */
    stopShip: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterStockItem
     */
    stopArrival: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemItemName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItem
     */
    mstItemSalesStartdate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterStockItem
     */
    mstItemSalesEnddate: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemLongside: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemShortside: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemHeight: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemWeight: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemSpec: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemMaxPalletQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemPalletQuantityFace: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemPalletQuantityTier: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemJan: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemJanCase: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemGtin13: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemGtin14: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemItf: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemSdp: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemReplaceOrgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemMakerGln: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemMakerName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemOrgSupplierId: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemMinOrderQuantity: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterStockItem
     */
    mstItemOrderUnitType: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterStockItem
     */
    mstItemCaseLot: number | null;
}

/**
 * Check if a given object implements the MstCenterStockItem interface.
 */
export function instanceOfMstCenterStockItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deleteFlg" in value;
    isInstance = isInstance && "insertDatetime" in value;
    isInstance = isInstance && "insertUser" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "updateUser" in value;
    isInstance = isInstance && "deleteDatetime" in value;
    isInstance = isInstance && "deleteUser" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "orgItemCode" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "supplierCompanyId" in value;
    isInstance = isInstance && "recencyFlg" in value;
    isInstance = isInstance && "stockDaysSetting" in value;
    isInstance = isInstance && "safetyStockSetting" in value;
    isInstance = isInstance && "stockDaysRank" in value;
    isInstance = isInstance && "safetyStockRank" in value;
    isInstance = isInstance && "minStockQuantity" in value;
    isInstance = isInstance && "orderUnitType" in value;
    isInstance = isInstance && "caseLot" in value;
    isInstance = isInstance && "minOrderQuantity" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "stopShip" in value;
    isInstance = isInstance && "stopArrival" in value;
    isInstance = isInstance && "mstItemItemName" in value;
    isInstance = isInstance && "mstItemSalesStartdate" in value;
    isInstance = isInstance && "mstItemSalesEnddate" in value;
    isInstance = isInstance && "mstItemLongside" in value;
    isInstance = isInstance && "mstItemShortside" in value;
    isInstance = isInstance && "mstItemHeight" in value;
    isInstance = isInstance && "mstItemWeight" in value;
    isInstance = isInstance && "mstItemSpec" in value;
    isInstance = isInstance && "mstItemMaxPalletQuantity" in value;
    isInstance = isInstance && "mstItemPalletQuantityFace" in value;
    isInstance = isInstance && "mstItemPalletQuantityTier" in value;
    isInstance = isInstance && "mstItemJan" in value;
    isInstance = isInstance && "mstItemJanCase" in value;
    isInstance = isInstance && "mstItemGtin13" in value;
    isInstance = isInstance && "mstItemGtin14" in value;
    isInstance = isInstance && "mstItemItf" in value;
    isInstance = isInstance && "mstItemSdp" in value;
    isInstance = isInstance && "mstItemReplaceOrgItemCode" in value;
    isInstance = isInstance && "mstItemMakerGln" in value;
    isInstance = isInstance && "mstItemMakerName" in value;
    isInstance = isInstance && "mstItemOrgSupplierId" in value;
    isInstance = isInstance && "mstItemMinOrderQuantity" in value;
    isInstance = isInstance && "mstItemOrderUnitType" in value;
    isInstance = isInstance && "mstItemCaseLot" in value;

    return isInstance;
}

export function MstCenterStockItemFromJSON(json: any): MstCenterStockItem {
    return MstCenterStockItemFromJSONTyped(json, false);
}

export function MstCenterStockItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCenterStockItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': json['delete_flg'],
        'insertDatetime': (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': json['insert_user'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': json['update_user'],
        'deleteDatetime': (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': json['delete_user'],
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'orgCenterId': json['org_center_id'],
        'itemCode': json['item_code'],
        'orgItemCode': json['org_item_code'],
        'orgSupplierId': json['org_supplier_id'],
        'supplierCompanyId': json['supplier_company_id'],
        'recencyFlg': json['recency_flg'],
        'stockDaysSetting': json['stock_days_setting'],
        'safetyStockSetting': json['safety_stock_setting'],
        'stockDaysRank': json['stock_days_rank'],
        'safetyStockRank': json['safety_stock_rank'],
        'minStockQuantity': json['min_stock_quantity'],
        'orderUnitType': json['order_unit_type'],
        'caseLot': json['case_lot'],
        'minOrderQuantity': json['min_order_quantity'],
        'startDate': (json['start_date'] === null ? null : new Date(json['start_date'])),
        'stopShip': json['stop_ship'],
        'stopArrival': json['stop_arrival'],
        'mstItemItemName': json['mst_item_item_name'],
        'mstItemSalesStartdate': (json['mst_item_sales_startdate'] === null ? null : new Date(json['mst_item_sales_startdate'])),
        'mstItemSalesEnddate': (json['mst_item_sales_enddate'] === null ? null : new Date(json['mst_item_sales_enddate'])),
        'mstItemLongside': json['mst_item_longside'],
        'mstItemShortside': json['mst_item_shortside'],
        'mstItemHeight': json['mst_item_height'],
        'mstItemWeight': json['mst_item_weight'],
        'mstItemSpec': json['mst_item_spec'],
        'mstItemMaxPalletQuantity': json['mst_item_max_pallet_quantity'],
        'mstItemPalletQuantityFace': json['mst_item_pallet_quantity_face'],
        'mstItemPalletQuantityTier': json['mst_item_pallet_quantity_tier'],
        'mstItemJan': json['mst_item_jan'],
        'mstItemJanCase': json['mst_item_jan_case'],
        'mstItemGtin13': json['mst_item_gtin_13'],
        'mstItemGtin14': json['mst_item_gtin_14'],
        'mstItemItf': json['mst_item_itf'],
        'mstItemSdp': json['mst_item_sdp'],
        'mstItemReplaceOrgItemCode': json['mst_item_replace_org_item_code'],
        'mstItemMakerGln': json['mst_item_maker_gln'],
        'mstItemMakerName': json['mst_item_maker_name'],
        'mstItemOrgSupplierId': json['mst_item_org_supplier_id'],
        'mstItemMinOrderQuantity': json['mst_item_min_order_quantity'],
        'mstItemOrderUnitType': json['mst_item_order_unit_type'],
        'mstItemCaseLot': json['mst_item_case_lot'],
    };
}

export function MstCenterStockItemToJSON(value?: MstCenterStockItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'company_id': value.companyId,
        'center_id': value.centerId,
        'org_center_id': value.orgCenterId,
        'item_code': value.itemCode,
        'org_item_code': value.orgItemCode,
        'org_supplier_id': value.orgSupplierId,
        'supplier_company_id': value.supplierCompanyId,
        'recency_flg': value.recencyFlg,
        'stock_days_setting': value.stockDaysSetting,
        'safety_stock_setting': value.safetyStockSetting,
        'stock_days_rank': value.stockDaysRank,
        'safety_stock_rank': value.safetyStockRank,
        'min_stock_quantity': value.minStockQuantity,
        'order_unit_type': value.orderUnitType,
        'case_lot': value.caseLot,
        'min_order_quantity': value.minOrderQuantity,
        'start_date': (value.startDate === null ? null : value.startDate.toISOString().substring(0,10)),
        'stop_ship': value.stopShip,
        'stop_arrival': value.stopArrival,
        'mst_item_item_name': value.mstItemItemName,
        'mst_item_sales_startdate': (value.mstItemSalesStartdate === null ? null : value.mstItemSalesStartdate.toISOString()),
        'mst_item_sales_enddate': (value.mstItemSalesEnddate === null ? null : value.mstItemSalesEnddate.toISOString()),
        'mst_item_longside': value.mstItemLongside,
        'mst_item_shortside': value.mstItemShortside,
        'mst_item_height': value.mstItemHeight,
        'mst_item_weight': value.mstItemWeight,
        'mst_item_spec': value.mstItemSpec,
        'mst_item_max_pallet_quantity': value.mstItemMaxPalletQuantity,
        'mst_item_pallet_quantity_face': value.mstItemPalletQuantityFace,
        'mst_item_pallet_quantity_tier': value.mstItemPalletQuantityTier,
        'mst_item_jan': value.mstItemJan,
        'mst_item_jan_case': value.mstItemJanCase,
        'mst_item_gtin_13': value.mstItemGtin13,
        'mst_item_gtin_14': value.mstItemGtin14,
        'mst_item_itf': value.mstItemItf,
        'mst_item_sdp': value.mstItemSdp,
        'mst_item_replace_org_item_code': value.mstItemReplaceOrgItemCode,
        'mst_item_maker_gln': value.mstItemMakerGln,
        'mst_item_maker_name': value.mstItemMakerName,
        'mst_item_org_supplier_id': value.mstItemOrgSupplierId,
        'mst_item_min_order_quantity': value.mstItemMinOrderQuantity,
        'mst_item_order_unit_type': value.mstItemOrderUnitType,
        'mst_item_case_lot': value.mstItemCaseLot,
    };
}

