/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCenterSupplierUpdateBase
 */
export interface MstCenterSupplierUpdateBase {
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    deleteFlg?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterSupplierUpdateBase
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterSupplierUpdateBase
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    updateUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterSupplierUpdateBase
     */
    deleteDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    deleteUser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    centerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    orgCenterId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    orgSupplierId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    optimizeType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioMon?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioTue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioWed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioThu?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioFri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioSat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    arrivalRatioSun?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    stopArrival?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    minOrderQuantityOnce?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplierUpdateBase
     */
    orderLeadtime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplierUpdateBase
     */
    orderLimitTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdayMon?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdayTue?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdayWed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdayThu?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdayFri?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdaySat?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplierUpdateBase
     */
    enableShipdaySun?: boolean | null;
}

/**
 * Check if a given object implements the MstCenterSupplierUpdateBase interface.
 */
export function instanceOfMstCenterSupplierUpdateBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MstCenterSupplierUpdateBaseFromJSON(json: any): MstCenterSupplierUpdateBase {
    return MstCenterSupplierUpdateBaseFromJSONTyped(json, false);
}

export function MstCenterSupplierUpdateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCenterSupplierUpdateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : json['delete_flg'],
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
        'deleteDatetime': !exists(json, 'delete_datetime') ? undefined : (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': !exists(json, 'delete_user') ? undefined : json['delete_user'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'centerId': !exists(json, 'center_id') ? undefined : json['center_id'],
        'orgCenterId': !exists(json, 'org_center_id') ? undefined : json['org_center_id'],
        'orgSupplierId': !exists(json, 'org_supplier_id') ? undefined : json['org_supplier_id'],
        'optimizeType': !exists(json, 'optimize_type') ? undefined : json['optimize_type'],
        'arrivalRatioMon': !exists(json, 'arrival_ratio_mon') ? undefined : json['arrival_ratio_mon'],
        'arrivalRatioTue': !exists(json, 'arrival_ratio_tue') ? undefined : json['arrival_ratio_tue'],
        'arrivalRatioWed': !exists(json, 'arrival_ratio_wed') ? undefined : json['arrival_ratio_wed'],
        'arrivalRatioThu': !exists(json, 'arrival_ratio_thu') ? undefined : json['arrival_ratio_thu'],
        'arrivalRatioFri': !exists(json, 'arrival_ratio_fri') ? undefined : json['arrival_ratio_fri'],
        'arrivalRatioSat': !exists(json, 'arrival_ratio_sat') ? undefined : json['arrival_ratio_sat'],
        'arrivalRatioSun': !exists(json, 'arrival_ratio_sun') ? undefined : json['arrival_ratio_sun'],
        'stopArrival': !exists(json, 'stop_arrival') ? undefined : json['stop_arrival'],
        'minOrderQuantityOnce': !exists(json, 'min_order_quantity_once') ? undefined : json['min_order_quantity_once'],
        'orderLeadtime': !exists(json, 'order_leadtime') ? undefined : json['order_leadtime'],
        'orderLimitTime': !exists(json, 'order_limit_time') ? undefined : json['order_limit_time'],
        'enableShipdayMon': !exists(json, 'enable_shipday_mon') ? undefined : json['enable_shipday_mon'],
        'enableShipdayTue': !exists(json, 'enable_shipday_tue') ? undefined : json['enable_shipday_tue'],
        'enableShipdayWed': !exists(json, 'enable_shipday_wed') ? undefined : json['enable_shipday_wed'],
        'enableShipdayThu': !exists(json, 'enable_shipday_thu') ? undefined : json['enable_shipday_thu'],
        'enableShipdayFri': !exists(json, 'enable_shipday_fri') ? undefined : json['enable_shipday_fri'],
        'enableShipdaySat': !exists(json, 'enable_shipday_sat') ? undefined : json['enable_shipday_sat'],
        'enableShipdaySun': !exists(json, 'enable_shipday_sun') ? undefined : json['enable_shipday_sun'],
    };
}

export function MstCenterSupplierUpdateBaseToJSON(value?: MstCenterSupplierUpdateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': value.deleteDatetime === undefined ? undefined : (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'company_id': value.companyId,
        'center_id': value.centerId,
        'org_center_id': value.orgCenterId,
        'org_supplier_id': value.orgSupplierId,
        'optimize_type': value.optimizeType,
        'arrival_ratio_mon': value.arrivalRatioMon,
        'arrival_ratio_tue': value.arrivalRatioTue,
        'arrival_ratio_wed': value.arrivalRatioWed,
        'arrival_ratio_thu': value.arrivalRatioThu,
        'arrival_ratio_fri': value.arrivalRatioFri,
        'arrival_ratio_sat': value.arrivalRatioSat,
        'arrival_ratio_sun': value.arrivalRatioSun,
        'stop_arrival': value.stopArrival,
        'min_order_quantity_once': value.minOrderQuantityOnce,
        'order_leadtime': value.orderLeadtime,
        'order_limit_time': value.orderLimitTime,
        'enable_shipday_mon': value.enableShipdayMon,
        'enable_shipday_tue': value.enableShipdayTue,
        'enable_shipday_wed': value.enableShipdayWed,
        'enable_shipday_thu': value.enableShipdayThu,
        'enable_shipday_fri': value.enableShipdayFri,
        'enable_shipday_sat': value.enableShipdaySat,
        'enable_shipday_sun': value.enableShipdaySun,
    };
}

