import type { Dispatch, FormEvent, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ERROR_RATE_LABEL } from 'constants/constants'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { ActionButton, CanselButton, MasterSearchFrom, MessageInfo } from 'components/commonparts'
import { Chart, SearchCondition } from 'components/parts/Dashboard'
import ShipExclusion from 'components/parts/Dashboard/ShipExclusion/ShipExclusion'
import { useMonthReport } from 'hooks/useMonthReport'
import { useStock } from 'hooks/useStock'
import { useSearchParamStore } from 'store/searchParamStore'
import { dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'
import { getInitCompanyId } from 'utils/getInitParam'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'

import type { GetStockStockGetRequest, ResponseResult, Stock } from 'api-client'
import type { LineCart } from 'components/parts/Dashboard/Chart/Chart'

export interface InitialChartState {
  left: string | undefined
  right: string | undefined
  refAreaLeft: string | undefined
  refAreaRight: string | undefined
}

export const initialChartState: InitialChartState = {
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
}

function Dashboard() {
  const shipChart: LineCart = {
    height: 500,
    xAxis: 'recDateToNumber',
    yAxisLabel: 'ケース',
    line: [
      {
        chartType: 'SimpleLine',
        name: '出荷実績',
        type: 'monotone',
        dataKey: 'shipQuantityRec',
        stroke: '#f68700',
      },
      {
        chartType: 'SimpleLine',
        name: '出荷予測',
        type: 'monotone',
        dataKey: 'shipQuantityExpected',
        stroke: '#ffcc8f',
      },
      {
        chartType: 'SimpleLine',
        name: ERROR_RATE_LABEL,
        type: 'monotone',
        dataKey: 'errorRate',
        // NOTE: 暫定対応 凡例のiconType="plainline"にすると legendType='none'が効かないため色をwhiteにして擬似的に非表示にする
        stroke: 'white',
        hide: true,
      },
    ],
  }

  const orderChart: LineCart = {
    height: 500,
    xAxis: 'recDateToNumber',
    yAxisLabel: 'ケース',
    line: [
      {
        chartType: 'SimpleLine',
        name: '発注実績',
        type: 'monotone',
        dataKey: 'orderQuantityRec',
        stroke: '#0bb7df',
      },
      {
        chartType: 'SimpleLine',
        name: '発注予測',
        type: 'monotone',
        dataKey: 'orderQuantityRecommend',
        stroke: '#a0e9fa',
      },
    ],
  }

  const stockoutChart: LineCart = {
    height: 500,
    xAxis: 'recDateToNumber',
    yAxisLabel: '日',
    line: [
      {
        chartType: 'SimpleLine',
        name: '欠品率実績',
        type: 'monotone',
        dataKey: 'stockoutRate',
        stroke: '#5cb743',
      },
      // {
      //   chartType: 'SimpleLine',
      //   name: '欠品率予測',
      //   type: 'monotone',
      //   dataKey: 'stockoutExpectedRate',
      //   stroke: '#cae8c2',
      // },
      {
        chartType: 'SimpleLine',
        name: '目標',
        type: 'monotone',
        dashedLine: true,
        dataKey: 'safetyStockRank',
        stroke: '#ffc000',
        dot: false,
      },
    ],
  }

  const stockDaysChart: LineCart = {
    height: 500,
    xAxis: 'recDateToNumber',
    yAxisLabel: '日',
    line: [
      {
        chartType: 'SimpleLine',
        name: '在庫日数',
        type: 'monotone',
        dataKey: 'stockDays',
        stroke: '#6150ba',
      },
      // {
      //   chartType: 'SimpleLine',
      //   name: '在庫日数予測',
      //   type: 'monotone',
      //   dataKey: 'stockDaysExpected',
      //   stroke: '#bab1e9',
      // },
      {
        chartType: 'SimpleLine',
        name: '目標',
        type: 'monotone',
        dashedLine: true,
        dataKey: 'stockDaysRank',
        stroke: '#ffc000',
        dot: false,
      },
    ],
  }

  const { loginUserInfo } = useAuthContext()

  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const { stock, fetchStock, isLoading: isStockLoading, result } = useStock()
  const { monthReport, fetchMonthReport, isLoading: isMonthReportLoading, result: monthReportResult } = useMonthReport()

  // 自社の検索条件をローカルストレージから取得する
  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCompanyId = localSearchParam?.dashboard.companyId || undefined
  const localCenterId = localSearchParam?.dashboard.centerId || undefined
  const localItemCode = localSearchParam?.dashboard.itemCode || ''
  const localDateRange = localSearchParam?.dashboard.dateRange || 'year'

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
    centerId: Number(query.get('center_id')),
    itemCode: query.get('item_code'),
    dateRange: query.get('date_range'),
  }

  const initSearchParam: GetStockStockGetRequest = {
    // ユーザーの企業ID
    companyId: getInitCompanyId(trQueryParam.companyId, localCompanyId, loginUserInfo!.companyId),
    centerId: trQueryParam.centerId ? trQueryParam.centerId : localCenterId,
    itemCode: trQueryParam.itemCode ? trQueryParam.itemCode : localItemCode,
    dateRange: trQueryParam.dateRange ? trQueryParam.dateRange : localDateRange,
  }

  const [isOpen, setIsOpen] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)
  const [searchParam, setSearchParam] = useState<GetStockStockGetRequest>(initSearchParam)
  const [updateTrigger, setUpdateTrigger] = useState<boolean | undefined>(undefined);
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [shipChartState, setShipChartState] = useState<InitialChartState>(initialChartState)
  const [stockChartState, setStockChartState] = useState<InitialChartState>(initialChartState)
  const [stockoutChartState, setStockoutChartState] = useState<InitialChartState>(initialChartState)
  const [stockDaysChartState, setStockDaysChartState] = useState<InitialChartState>(initialChartState)
  const [plotSelectDate, setPlotSelectDate] = useState<Stock>()

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const navigate = useNavigate()
  const onSearch = (requestParam: GetStockStockGetRequest) => {
    // /stock API
    fetchStock({
      ...requestParam,
      viewUnitType: 'day',
      dateRange: 'year',
    })

    // /month-report API
    fetchMonthReport(requestParam)

    setSearchParam({ ...requestParam, viewUnitType: 'day', dateRange: 'year' })

    // 検索条件をローカルストレージに保存する
    if (loginUserInfo?.tenantId && loginUserInfo?.companyId) {
      setSearchParamState<GetStockStockGetRequest>(loginUserInfo?.tenantId, loginUserInfo?.companyId, 'Dashboard', {
        companyId: requestParam.companyId,
        centerId: requestParam.centerId,
        itemCode: requestParam.itemCode,
        viewUnitType: 'day',
        dateRange: 'year',
      })
    }

    // 検索実行時に検索条件をクエリパラメータとして付与する
    navigate(
      `?company_id=${requestParam.companyId}&center_id=${requestParam.centerId}&item_code=${requestParam.itemCode}&date_range=year`
    )  
  }

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }
  // set data to onPlotSelectDate when click dot in line and isOpen = true
  const onPlotSelectDate = (data: any) => {
    if (!isOpen) return;

    if (data && data.recDate) {
      setPlotSelectDate(data);
    }
  };

  const handleRegister = () => {
    setUpdateTrigger((prev) => !prev);
  }
  // if no search condition set open to false
  useEffect(() => {
    if(searchParam.itemCode === '' && searchParam.centerId === undefined) {
      setIsOpen(false)
    }
  }, [searchParam])
  // Cancel Process
  const handleCancel = () => {
    fetchStock(searchParam);
    setPlotSelectDate(undefined);
  };

  // 数値に変換されたrecDateをd(日)となるように文字列を返す
  const stockRecDateFormatter = (trRecDateToNumber: number) => {
    const newRecDate = new Date(trRecDateToNumber)

    return dateToStr24HPad0DayOfWeek(newRecDate, 'YYYY/MM/DD (WW)', false)
  }

  // 数値に変換されたrecDateをm(月)となるように文字列を返す
  const monthReporRecDateFormatter = (trRecDateToNumber: number) => {
    const newRecDate = new Date(trRecDateToNumber)
    return dateToStr24HPad0DayOfWeek(newRecDate, 'MM月', false)
  }

  // グラフ上でマウスで範囲選択時に実行される関数
  // リアクティブなtrChartStateから範囲(refAreaLeft,refAreaRight)を元にleft,rightを更新する
  const chartZoom = (trChartState: InitialChartState, setChartState: Dispatch<SetStateAction<InitialChartState>>) => {
    let { refAreaLeft, refAreaRight } = trChartState

    if (refAreaLeft === refAreaRight || refAreaRight === '' || !refAreaLeft || !refAreaRight) {
      return setChartState((state) => ({
        ...state,
        refAreaLeft: '',
        refAreaRight: '',
      }))
    }

    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft]

    return setChartState((state) => ({
      ...state,
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
    }))
  }

  // 拡大されたグラフを縮小する関数
  const chartZoomOut = (setChartState: Dispatch<SetStateAction<InitialChartState>>) => {
    setChartState((state) => ({
      ...state,
      refAreaLeft: '',
      refAreaRight: '',
      left: 'dataMin',
      right: 'dataMax',
    }))
  }

  useEffect(() => {
    // クエリパラメータまたはローカルストレージに検索条件がある場合は初回レンダリング時に検索を実行する
    if ((trQueryParam.centerId && trQueryParam.itemCode) || (localCenterId && localItemCode)) {
      fetchStock({ ...searchParam, viewUnitType: 'day' })
      fetchMonthReport(searchParam)

      // 検索条件をローカルストレージに保存する
      if (loginUserInfo?.tenantId && loginUserInfo?.companyId) {
        setSearchParamState<GetStockStockGetRequest>(loginUserInfo?.tenantId, loginUserInfo?.companyId, 'Dashboard', {
          ...searchParam,
          viewUnitType: 'day',
        })
      }
    }
  }, [])

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        ダッシュボード
      </Typography>
      <Paper variant="outlined"> 
        <SearchCondition searchParam={initSearchParam} onSearch={onSearch} searchTrigger={searchTrigger}/>
      </Paper>
      <Box>
        <Typography component="div" variant="largeBold">
          出荷予測量
        </Typography>
        <Chart
          list={stock.list}
          chartDetail={shipChart}
          chartState={shipChartState}
          setChartState={setShipChartState}
          chartZoom={chartZoom}
          chartZoomOut={chartZoomOut}
          recDateFormatter={stockRecDateFormatter}
          isLoading={isStockLoading}
          // if accordion open -> get Data onPlotSelectDate
          onPlotSelectDate={isOpen ? onPlotSelectDate : undefined}
        />
        <Grid direction="row" container justifyContent="space-between" sx={{ marginBottom: 1.5 }}>
          <Grid item>
            <MessageInfo result={resultMessage} />
          </Grid>
        </Grid>
        <Accordion   
            expanded={isOpen}
            // set change if accordion open and close
            onChange={() => setIsOpen(prev => !prev)}
            >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
          <Typography variant="largeBold">計算除外日設定</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShipExclusion 
              list={stock}
              isLoading={isStockLoading}
              searchParam={searchParam}
              updateTrigger={updateTrigger}
              setHasGridEdited={setHasGridEdited}
              setResultMessage={setGridMessage}
              onReload={onReload}
              onPlotSelectDate={isOpen ? plotSelectDate : undefined}/>
            <Grid direction="row" container justifyContent="center" sx={{ margin: 1.5  }}>
              <Grid item>
                <ActionButton 
                  onClick={() => { 
                    handleRegister?.(); 
                  }} 
                  disabled={false} 
                />
                <CanselButton
                  onClick={() => {handleCancel?.()}}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
      </Accordion>
      </Box>
      <Box>
        <Typography component="div" variant="largeBold">
          発注推奨量
        </Typography>
        <Chart
          list={stock.list}
          chartDetail={orderChart}
          chartState={stockChartState}
          setChartState={setStockChartState}
          chartZoom={chartZoom}
          chartZoomOut={chartZoomOut}
          recDateFormatter={stockRecDateFormatter}
          isLoading={isStockLoading}
        />
        <Grid direction="row" container justifyContent="space-between" sx={{ marginBottom: 1.5 }}>
          <Grid item>
            <MessageInfo result={resultMessage} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography component="div" variant="largeBold">
          欠品率
        </Typography>
        <Chart
          list={monthReport.list}
          chartDetail={stockoutChart}
          chartState={stockoutChartState}
          setChartState={setStockoutChartState}
          chartZoom={chartZoom}
          chartZoomOut={chartZoomOut}
          recDateFormatter={monthReporRecDateFormatter}
          isLoading={isMonthReportLoading}
        />
        <Grid direction="row" container justifyContent="space-between" sx={{ marginBottom: 1.5 }}>
          <Grid item>
            <MessageInfo result={monthReportResult} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography component="div" variant="largeBold">
          在庫日数
        </Typography>
        <Chart
          list={monthReport.list}
          chartDetail={stockDaysChart}
          chartState={stockDaysChartState}
          setChartState={setStockDaysChartState}
          chartZoom={chartZoom}
          chartZoomOut={chartZoomOut}
          recDateFormatter={monthReporRecDateFormatter}
          isLoading={isMonthReportLoading}
        />
        <Grid direction="row" container justifyContent="space-between">
          <Grid item>
            <MessageInfo result={monthReportResult} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
export default Dashboard

/* 

       */
