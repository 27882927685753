import { useCallback, useEffect, useState, useRef, type default as React } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom, FileDownloadData } from 'components/commonparts'
import { DataList } from 'components/parts/MasterCenter';
import { useCenter } from 'hooks/useCenter';

import type { GetMstCentersCenterGetRequest, MstCenter, ResponseResult } from 'api-client';

function MasterCenter() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCentersCenterGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCenter[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')
  const [downloadColDef, setDownloadColDef] = useState<any[] | undefined>([]);

  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { result, isLoading, centerSearchResult, centerSearch ,fetchCenterSearch } = useCenter()
  const isFirstRender = useRef(true);
  const [uploadData, setUploadData] = useState<any[]>([])
  const [updateTrigger, setUpdateTrigger] = useState<boolean | undefined>(undefined);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)
  
  useEffect(() => {
    setResultMessage(centerSearchResult)
  }, [centerSearchResult])
  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  // const doSearch = useCallback(async (requestParam: GetMstCentersCenterGetRequest) => {
  //   if (hasGridEdited) {
  //     setDiscardChangeDialogOpen(true)
  //     return
  //   }
  //   try {
  //     fetchCenterSearch({
  //         ...requestParam,
  //       });
  //       // fetchSupplier({
  //       //   companyId: requestParam.companyId, 
  //       // });
  //       if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
  //         setSearchParamState<GetMstCentersCenterGetRequest>(
  //           loginUserInfo?.tenantId,
  //           loginUserInfo?.companyId,
  //           'Center',
  //           {
  //             ...requestParam,
  //           }
  //         );
  //       }
  
  //       setSearchParam(requestParam);
  //       setHasGridEdited(false)
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } 
  // }, [centerSearch]);

  const handleRegister = () => {
    setUpdateTrigger((prev) => !prev);
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    fetchCenterSearch({
      enabled: false,
      ...initSearchParam,
    })
    setHasGridEdited(false)
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender.current) {
        isFirstRender.current=false
        const itemsPromise = fetchCenterSearch({
          enabled: false,
          ...initSearchParam,
        });
        await Promise.all([itemsPromise]);
      }
    }
    firstTimeRender()
  }, [])

  const generateDownloadData = (): MstCenter[] => {
    if (!centerSearch?.list || centerSearch.list.length === 0) {
      return [{} as MstCenter]
    }
    const downloadData: MstCenter[] = [...centerSearch.list]
    return downloadData
  }
  
  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        自社センター管理
      </Typography>
      <MasterSearchFrom
          result={resultMessage}
          onDoUpdate={handleRegister}
          onReload={onReload}
          onImportData={handleUploadData}
          onFileDownLoad={handleFileDownload}
          discardChangeDialogOpen={discardChangeDialogOpen}
          setDownloadMode={setDownloadMode}
          hasDataEdited={hasGridEdited}
          importRequiredFields={["org_center_id"]}
          headerJp={["自社センターID"]}
          lastUpdateDatetime={centerSearch?.lastUpdateDatetime}
        />
      <FileDownloadData fileName="自社センターマスタ" downloadData={downloadGridRows} downloadMode={downloadMode} columnDefs={downloadColDef} />
      <DataList
          list={centerSearch}
          onReload={onReload}
          isLoading={isLoading}
          searchParam={initSearchParam}
          onUploadData={uploadData}
          updateTrigger={updateTrigger}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
          setDownloadColumnDefs={setDownloadColDef}
        />

    </Box>
          
  )
}

export default MasterCenter;
