/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Calendar } from './Calendar';
import {
    CalendarFromJSON,
    CalendarFromJSONTyped,
    CalendarToJSON,
} from './Calendar';
import type { ResponseResult } from './ResponseResult';
import {
    ResponseResultFromJSON,
    ResponseResultFromJSONTyped,
    ResponseResultToJSON,
} from './ResponseResult';

/**
 * 
 * @export
 * @interface ResponseCalendar
 */
export interface ResponseCalendar {
    /**
     * 
     * @type {ResponseResult}
     * @memberof ResponseCalendar
     */
    result: ResponseResult;
    /**
     * 
     * @type {Calendar}
     * @memberof ResponseCalendar
     */
    data: Calendar;
}

/**
 * Check if a given object implements the ResponseCalendar interface.
 */
export function instanceOfResponseCalendar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ResponseCalendarFromJSON(json: any): ResponseCalendar {
    return ResponseCalendarFromJSONTyped(json, false);
}

export function ResponseCalendarFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCalendar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ResponseResultFromJSON(json['result']),
        'data': CalendarFromJSON(json['data']),
    };
}

export function ResponseCalendarToJSON(value?: ResponseCalendar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ResponseResultToJSON(value.result),
        'data': CalendarToJSON(value.data),
    };
}

