import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'

import type { DlStockCsvDownloadCsvStockGetRequest, DlStockXlsxDownloadXlsxStockGetRequest, StockUpdateUploadStockUploadPostRequest } from 'api-client'

export const useStockFile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCsvLoading, setIsCsvLoading] = useState(false)
  const [isXlsxLoading, setIsXlsxLoading] = useState(false)

  const fetchStockCsvDownload = (filename: string, searchParam: DlStockCsvDownloadCsvStockGetRequest) => {
    setIsCsvLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.dlStockCsvDownloadCsvStockGetRaw(searchParam)
      })
      .then((response) => {
        response.raw.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}_${dateToStr24HPad0DayOfWeek(new Date(), 'YYYYMMDDhhmmss')}`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      })
      .finally(() => {
        setIsCsvLoading(false)
      })
  }

  const fetchStockXlsxDownload = (filename: string, searchParam: DlStockXlsxDownloadXlsxStockGetRequest) => {
    setIsXlsxLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.dlStockXlsxDownloadXlsxStockGetRaw(searchParam)
      })
      .then((response) => {
        response.raw.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}_${dateToStr24HPad0DayOfWeek(new Date(), 'YYYYMMDDhhmmss')}`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      })
      .finally(() => {
        setIsXlsxLoading(false)
      })
  }

   const fetchStockUpload = (searchParam: StockUpdateUploadStockUploadPostRequest) => {
      setIsLoading(true)
  
      return auth.currentUser
        ?.getIdToken(true)
        .then((token) => {
          const conf = new Configuration({
            basePath: process.env.REACT_APP_API_BASE_PATH,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          const api = new DefaultApi(conf)
          return api.stockUpdateUploadStockUploadPost(searchParam)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

  return {
    fetchStockCsvDownload,
    isCsvLoading,
    fetchStockXlsxDownload,
    isXlsxLoading,
    fetchStockUpload,
    isLoading,
  }
}
