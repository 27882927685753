import { useEffect, useState, type Dispatch, type SetStateAction, type default as React } from 'react';

import { Grid, Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchButton, SelectCenter } from 'components/commonparts'
import { ItemSearch } from 'components/dialog'

import type { GetMstCenterStockItemCenterStockItemGetRequest } from 'api-client'

interface SearchConditionProps {
  searchParam: GetMstCenterStockItemCenterStockItemGetRequest
  onSearch: (requestParam: GetMstCenterStockItemCenterStockItemGetRequest) => void
  hasDataEdited: boolean  // 編集中のデータがあるか？
  setDiscardChangeDialogOpen: Dispatch<SetStateAction<boolean>>,
  searchTrigger?: boolean // 親コンポーネントから検索実行された場合に値が変化する
}

function SearchCondition({
  searchParam, 
  onSearch, 
  hasDataEdited=false,
  setDiscardChangeDialogOpen,
  searchTrigger,
}: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [centerId, setCenterId] = useState<number | null | undefined>(searchParam.centerId)
  const [orgItemCode, setOrgItemCode] = useState<string | null | undefined>(searchParam.orgItemCode)

  const onSearchClick=()=>{
    if (hasDataEdited) {
      setDiscardChangeDialogOpen((prev) => !prev)
      return
    }

    onSearch({
      companyId: loginUserInfo!.companyId,
      centerId,
      orgItemCode,
    })  
  }

  useEffect(() => {
    // searchTriggerの変更を検知して、検索イベントを起こす
    // 親コンポーネントなどで検索を行いたい場合は、このトリガーを使用する
    onSearch({
      companyId: loginUserInfo!.companyId,
      centerId,
      orgItemCode,
    })   
  }, [searchTrigger])

  return (
        <Grid container>
            <SelectCenter
              trCompanyId={searchParam.companyId}
              centerId={centerId}
              setCenterId={setCenterId}
              setCenterInfo={() => {}}
              isRequired={false}
              setIsValid={() => {}}
              enabled={false}
            />
            <ItemSearch
              trCompanyId={searchParam.companyId}
              orgItemCode={orgItemCode}
              setOrgItemCode={setOrgItemCode}
              setItemInfo={() => {}}
              isRequred={false}
              setIsValid={() => {}}
              enabled={false}
            />
          <Box display="flex" justifyContent="center" alignItems="end" marginBottom='5px' marginRight='10px'>
              <SearchButton searchId="centerStockItem" onSearchClick={onSearchClick}/>
          </Box>
        </Grid>
  )
}

export default SearchCondition
