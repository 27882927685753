/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Daylist
 */
export interface Daylist {
    /**
     * 
     * @type {number}
     * @memberof Daylist
     */
    dateno: number | null;
    /**
     * 
     * @type {string}
     * @memberof Daylist
     */
    rectype: string | null;
    /**
     * 
     * @type {number}
     * @memberof Daylist
     */
    weekNo: number | null;
    /**
     * 
     * @type {string}
     * @memberof Daylist
     */
    colname: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Daylist
     */
    datevalue: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Daylist
     */
    rectypeName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Daylist
     */
    orderDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Daylist
     */
    newitemStartFlg: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Daylist
     */
    renewalNumber: number | null;
}

/**
 * Check if a given object implements the Daylist interface.
 */
export function instanceOfDaylist(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dateno" in value;
    isInstance = isInstance && "rectype" in value;
    isInstance = isInstance && "weekNo" in value;
    isInstance = isInstance && "colname" in value;
    isInstance = isInstance && "datevalue" in value;
    isInstance = isInstance && "rectypeName" in value;
    isInstance = isInstance && "orderDate" in value;
    isInstance = isInstance && "newitemStartFlg" in value;
    isInstance = isInstance && "renewalNumber" in value;

    return isInstance;
}

export function DaylistFromJSON(json: any): Daylist {
    return DaylistFromJSONTyped(json, false);
}

export function DaylistFromJSONTyped(json: any, ignoreDiscriminator: boolean): Daylist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateno': json['dateno'],
        'rectype': json['rectype'],
        'weekNo': json['week_no'],
        'colname': json['colname'],
        'datevalue': (json['datevalue'] === null ? null : new Date(json['datevalue'])),
        'rectypeName': json['rectype_name'],
        'orderDate': (json['order_date'] === null ? null : new Date(json['order_date'])),
        'newitemStartFlg': json['newitem_start_flg'],
        'renewalNumber': json['renewal_number'],
    };
}

export function DaylistToJSON(value?: Daylist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateno': value.dateno,
        'rectype': value.rectype,
        'week_no': value.weekNo,
        'colname': value.colname,
        'datevalue': (value.datevalue === null ? null : value.datevalue.toISOString()),
        'rectype_name': value.rectypeName,
        'order_date': (value.orderDate === null ? null : value.orderDate.toISOString().substring(0,10)),
        'newitem_start_flg': value.newitemStartFlg,
        'renewal_number': value.renewalNumber,
    };
}

