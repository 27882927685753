import { useState } from 'react'

import { useCurrentStatusStore } from 'store/currentStatusStore'
import { getAPI } from 'utils/getAPI'

import type { GetStockStockGetRequest, ResponseResult, Stocks } from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useStockData = () => {
  const [stockData, setStockData] = useState<Stocks>({
    datacount: null,
    maxcount: null,
    lastUpdateDatetime: null,
    list: [],
    daylist: [],
    renewalOrgItemList: null
  })
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const calcErraRate = (shipQuantityExpected: number | null, shipQuantityRec: number | null) => {
    if (shipQuantityRec == null) return null

    return (
      (shipQuantityExpected == null ? 0 : shipQuantityExpected - shipQuantityRec) /
      (shipQuantityRec === 0 ? 1 : shipQuantityRec)
    )
  }

  const fetchStockData = async (searchParam: GetStockStockGetRequest) => {
    setResult(undefined)
    setIsLoading(true)

    const api = await getAPI()
    if (!api) return

    try {
      const response = await api.getStockStockGet({
        ...searchParam,
        companyId: searchParam.companyId,
        centerId: searchParam.centerId || undefined,
        orgSupplierId: searchParam.orgSupplierId || undefined,
        optimizeRange: searchParam.optimizeRange,
        sort: searchParam.sort || undefined,
      })

      setStockData({
        ...response.data,
      })
      setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
    } catch (e) {
      const error = e as ErrorResponse
      setResult({
        status: 'error',
        message:
          error.response && error.response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    result,
    stockData,
    fetchStockData,
    isLoading,
  }
}
