/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnableShipdayMon1 } from './EnableShipdayMon1';
import {
    EnableShipdayMon1FromJSON,
    EnableShipdayMon1FromJSONTyped,
    EnableShipdayMon1ToJSON,
} from './EnableShipdayMon1';

/**
 * 
 * @export
 * @interface MstSupplierUpateBase
 */
export interface MstSupplierUpateBase {
    /**
     * 
     * @type {number}
     * @memberof MstSupplierUpateBase
     */
    companyId?: number | null;
    /**
     * 仕入先企業ID
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    orgSupplierId: string;
    /**
     * 仕入先企業名
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    supplierName: string;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    supplierHojinNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    supplierAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    supplierGln?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplierUpateBase
     */
    minOrderQuantityOnce?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplierUpateBase
     */
    orderLeadtime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    orderLimitTime?: string | null;
    /**
     * 
     * @type {EnableShipdayMon1}
     * @memberof MstSupplierUpateBase
     */
    enableShipdayMon?: EnableShipdayMon1 | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableShipdayTue?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableShipdayWed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableShipdayThu?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableShipdayFri?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableShipdaySat?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableShipdaySun?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdayMon?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdayTue?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdayWed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdayThu?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdayFri?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdaySat?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    enableOrderdaySun?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplierUpateBase
     */
    deleteFlg?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplierUpateBase
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplierUpateBase
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    updateUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplierUpateBase
     */
    deleteDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplierUpateBase
     */
    deleteUser?: string | null;
}

/**
 * Check if a given object implements the MstSupplierUpateBase interface.
 */
export function instanceOfMstSupplierUpateBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "supplierName" in value;

    return isInstance;
}

export function MstSupplierUpateBaseFromJSON(json: any): MstSupplierUpateBase {
    return MstSupplierUpateBaseFromJSONTyped(json, false);
}

export function MstSupplierUpateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstSupplierUpateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'orgSupplierId': json['org_supplier_id'],
        'supplierName': json['supplier_name'],
        'supplierHojinNo': !exists(json, 'supplier_hojin_no') ? undefined : json['supplier_hojin_no'],
        'supplierAddress': !exists(json, 'supplier_address') ? undefined : json['supplier_address'],
        'supplierGln': !exists(json, 'supplier_gln') ? undefined : json['supplier_gln'],
        'minOrderQuantityOnce': !exists(json, 'min_order_quantity_once') ? undefined : json['min_order_quantity_once'],
        'orderLeadtime': !exists(json, 'order_leadtime') ? undefined : json['order_leadtime'],
        'orderLimitTime': !exists(json, 'order_limit_time') ? undefined : json['order_limit_time'],
        'enableShipdayMon': !exists(json, 'enable_shipday_mon') ? undefined : EnableShipdayMon1FromJSON(json['enable_shipday_mon']),
        'enableShipdayTue': !exists(json, 'enable_shipday_tue') ? undefined : json['enable_shipday_tue'],
        'enableShipdayWed': !exists(json, 'enable_shipday_wed') ? undefined : json['enable_shipday_wed'],
        'enableShipdayThu': !exists(json, 'enable_shipday_thu') ? undefined : json['enable_shipday_thu'],
        'enableShipdayFri': !exists(json, 'enable_shipday_fri') ? undefined : json['enable_shipday_fri'],
        'enableShipdaySat': !exists(json, 'enable_shipday_sat') ? undefined : json['enable_shipday_sat'],
        'enableShipdaySun': !exists(json, 'enable_shipday_sun') ? undefined : json['enable_shipday_sun'],
        'enableOrderdayMon': !exists(json, 'enable_orderday_mon') ? undefined : json['enable_orderday_mon'],
        'enableOrderdayTue': !exists(json, 'enable_orderday_tue') ? undefined : json['enable_orderday_tue'],
        'enableOrderdayWed': !exists(json, 'enable_orderday_wed') ? undefined : json['enable_orderday_wed'],
        'enableOrderdayThu': !exists(json, 'enable_orderday_thu') ? undefined : json['enable_orderday_thu'],
        'enableOrderdayFri': !exists(json, 'enable_orderday_fri') ? undefined : json['enable_orderday_fri'],
        'enableOrderdaySat': !exists(json, 'enable_orderday_sat') ? undefined : json['enable_orderday_sat'],
        'enableOrderdaySun': !exists(json, 'enable_orderday_sun') ? undefined : json['enable_orderday_sun'],
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : json['delete_flg'],
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
        'deleteDatetime': !exists(json, 'delete_datetime') ? undefined : (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': !exists(json, 'delete_user') ? undefined : json['delete_user'],
    };
}

export function MstSupplierUpateBaseToJSON(value?: MstSupplierUpateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'org_supplier_id': value.orgSupplierId,
        'supplier_name': value.supplierName,
        'supplier_hojin_no': value.supplierHojinNo,
        'supplier_address': value.supplierAddress,
        'supplier_gln': value.supplierGln,
        'min_order_quantity_once': value.minOrderQuantityOnce,
        'order_leadtime': value.orderLeadtime,
        'order_limit_time': value.orderLimitTime,
        'enable_shipday_mon': EnableShipdayMon1ToJSON(value.enableShipdayMon),
        'enable_shipday_tue': value.enableShipdayTue,
        'enable_shipday_wed': value.enableShipdayWed,
        'enable_shipday_thu': value.enableShipdayThu,
        'enable_shipday_fri': value.enableShipdayFri,
        'enable_shipday_sat': value.enableShipdaySat,
        'enable_shipday_sun': value.enableShipdaySun,
        'enable_orderday_mon': value.enableOrderdayMon,
        'enable_orderday_tue': value.enableOrderdayTue,
        'enable_orderday_wed': value.enableOrderdayWed,
        'enable_orderday_thu': value.enableOrderdayThu,
        'enable_orderday_fri': value.enableOrderdayFri,
        'enable_orderday_sat': value.enableOrderdaySat,
        'enable_orderday_sun': value.enableOrderdaySun,
        'delete_flg': value.deleteFlg,
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': value.deleteDatetime === undefined ? undefined : (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
    };
}

