/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstTransportRelation
 */
export interface MstTransportRelation {
    /**
     * 
     * @type {number}
     * @memberof MstTransportRelation
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstTransportRelation
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstTransportRelation
     */
    orgCustomerId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstTransportRelation
     */
    orgCustomerCenterId: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstTransportRelation
     */
    leadtime: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstTransportRelation
     */
    maxTrafficVolume: number | null;
}

/**
 * Check if a given object implements the MstTransportRelation interface.
 */
export function instanceOfMstTransportRelation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "orgCustomerId" in value;
    isInstance = isInstance && "orgCustomerCenterId" in value;
    isInstance = isInstance && "leadtime" in value;
    isInstance = isInstance && "maxTrafficVolume" in value;

    return isInstance;
}

export function MstTransportRelationFromJSON(json: any): MstTransportRelation {
    return MstTransportRelationFromJSONTyped(json, false);
}

export function MstTransportRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstTransportRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'orgCenterId': json['org_center_id'],
        'orgCustomerId': json['org_customer_id'],
        'orgCustomerCenterId': json['org_customer_center_id'],
        'leadtime': json['leadtime'],
        'maxTrafficVolume': json['max_traffic_volume'],
    };
}

export function MstTransportRelationToJSON(value?: MstTransportRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'org_center_id': value.orgCenterId,
        'org_customer_id': value.orgCustomerId,
        'org_customer_center_id': value.orgCustomerCenterId,
        'leadtime': value.leadtime,
        'max_traffic_volume': value.maxTrafficVolume,
    };
}

