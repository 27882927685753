import type { FormEvent } from 'react'
import React, { useEffect, useState } from 'react'

import { Box, FormControl, TextField } from '@mui/material'
import { SearchFrom, SelectSupplier } from 'components/commonparts'

import type { GetMstItemsItemGetRequest, ResponseResult } from 'api-client'

interface SearchConditionProps {
  searchParam: GetMstItemsItemGetRequest
  onSearch: (requestParam: GetMstItemsItemGetRequest, e: FormEvent<HTMLFormElement>) => void
  result?: ResponseResult | undefined
  enabled?: boolean | undefined
}

function SearchCondition({ searchParam, onSearch, result, enabled }: SearchConditionProps) {
  const [barcode, setBarcode] = useState(searchParam.barcode)
  const [orgItemCode, setOrgItemCode] = useState(searchParam.orgItemCode)
  const [itemName, setItemName] = useState(searchParam.itemName)
  const [orgSupplierId, setOrgSupplierId] = useState(searchParam.orgSupplierId)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const onClear = () => {
    setBarcode('')
    setOrgItemCode('')
    setItemName('')
    setOrgSupplierId(undefined)
  }

  // 初回レンダリング時はローカルストレージの検索条件を反映させるため、￥￥実行しないようにする
  // companyIdが変更された場合は検索条件を初期化する
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
      return
    }

    setBarcode('')
    setOrgItemCode('')
    setItemName('')
    setOrgSupplierId(undefined)
  }, [searchParam.companyId])

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()

        onSearch({ companyId: searchParam.companyId, barcode, orgItemCode, itemName, orgSupplierId }, e)
      }}
    >
      <SearchFrom searchId="item" onClear={onClear} result={result}>
        <FormControl>
          <SelectSupplier
            trCompanyId={searchParam.companyId}
            orgSupplierId={orgSupplierId}
            setOrgSupplierId={setOrgSupplierId}
            setSupplierInfo={() => {}}
            isRequred={false}
            setIsValid={() => {}}
            enabled={enabled}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="text-item-name"
            label="商品名"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="text-barcode"
            label="バーコード"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="text-org-item-code"
            label="社内商品コード"
            value={orgItemCode}
            onChange={(e) => setOrgItemCode(e.target.value)}
          />
        </FormControl>
      </SearchFrom>
    </Box>
  )
}

export default SearchCondition
