import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider'
import { type CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { LoadingOverlay,TimeCellRenderer } from 'components/commonparts/index'
import { useGridCommon } from 'hooks/useGridCommon'
import { useSupplier } from 'hooks/useSupplier';
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes'
import { validateRequired } from 'utils/validation'

import type { ColDef, ColGroupDef } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import type { Supplier, MstSupplier, GetMstSuppliersSupplierGetRequest } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
    "org_supplier_id": "orgSupplierId",
    "supplier_name": "supplierName",
    "supplier_hojin_no" : "supplierHojinNo",
    "supplier_address" : "supplierAddress",
    "supplier_gln" : "supplierGln",
    "min_order_quantity_once" : "minOrderQuantityOnce",
    "order_leadtime" : "orderLeadtime",
    "order_limit_time" : "orderLimitTime",
    "enable_shipday_mon" : "enableShipdayMon",
    "enable_shipday_tue" : "enableShipdayTue",
    "enable_shipday_wed" : "enableShipdayWed",
    "enable_shipday_thu" : "enableShipdayThu",
    "enable_shipday_fri" : "enableShipdayFri",
    "enable_shipday_sat" : "enableShipdaySat",
    "enable_shipday_sun" : "enableShipdaySun",
    "enable_orderday_mon" : "enableOrderdayMon",
    "enable_orderday_tue" : "enableOrderdayTue",
    "enable_orderday_wed" : "enableOrderdayWed",
    "enable_orderday_thu" : "enableOrderdayThu",
    "enable_orderday_fri" : "enableOrderdayFri",
    "enable_orderday_sat" : "enableOrderdaySat",
    "enable_orderday_sun" : "enableOrderdaySun",
    "delete_flg" : "deleteFlg"
};
const primayKey = ['orgSupplierId']

interface DataListProps extends DataListPropsBase {
  list: Supplier
  searchParam: GetMstSuppliersSupplierGetRequest
}

interface MstSupplierWithId extends MstSupplier, RecordEditEx {}

function DataList({ 
  list, 
  isLoading, 
  searchParam, 
  onReload, 
  updateTrigger, 
  onUploadData,
  setHasGridEdited,
  setResultMessage,
  setDownloadColumnDefs,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {result,isUpdating, fetchSupplierUpdate, supplierUpdateResult, supplierSearchResult} = useSupplier()
  const gridRef = useRef<AgGridReact<MstSupplierWithId>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 250px)' }), [])
  const [editorDataReady, setEditorDataReady] = useState(false);
  const [row, setRow] = useState<MstSupplierWithId[]>(
    list.list.map((item) => ({ ...item, ...initRecordEditEx() }))
  )
  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    setEditorDataReady(false)
  }, [row]);

  const createBlankRow = useCallback((): MstSupplierWithId => ({
      companyId: loginUserInfo?.companyId || null,
      orgSupplierId: null,
      supplierCompanyId: null,
      supplierName: null,
      supplierHojinNo: null,
      supplierAddress: null,
      supplierGln: null,
      minOrderQuantityOnce: 1,
      orderLeadtime: 2,
      orderLimitTime: null,
      enableShipdayMon: true,
      enableShipdayTue: true,
      enableShipdayWed: true,
      enableShipdayThu: true,
      enableShipdayFri: true,
      enableShipdaySat: false,
      enableShipdaySun: false,
      enableOrderdayMon: true,
      enableOrderdayTue: true,
      enableOrderdayWed: true,
      enableOrderdayThu: true,
      enableOrderdayFri: true,
      enableOrderdaySat: false,
      enableOrderdaySun: false,
      deleteFlg: false,
      insertDatetime: null,
      updateDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      deleteDatetime: null,
      deleteUser: null,
      updateUser: null,
      ...initRecordEditEx(true)
    }
  ), [])

  // 入力チェック
  const fieldValidate = (pRow: MstSupplierWithId, field: string | undefined, newValue: any): boolean => {
    let hasErrors = false
    switch (field){
      case 'orgCenterId' :
      case "orgSupplierId": {
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue));
        if (!hasErrors) {
          hasErrors = duplicateCheck(pRow, newValue)
        }
        break;
      }

      case "supplierName":
      case "minOrderQuantityOnce":
      case "orderLeadtime":
        checkAndSetError(pRow, field, validateRequired(newValue));
        break;
      default:
        return false;
    }
    return hasErrors

  };

  
  const cellValueChanged = (event: CellValueChangedEvent<MstSupplierWithId>, hasError: boolean) => {
    const { data, colDef, newValue, oldValue, node } = event
    const { field } = colDef

    const updatedRow = { ...data }
    // 値の変更時に、APIのエラーを解消
    switch (field){
      case "orgSupplierId":
      case "supplierName":
        checkAndSetError(updatedRow, "orgSupplierId", null);
        fieldValidate(updatedRow, "orgSupplierId", updatedRow.orgSupplierId);
        break;
      default:
    }
  };

  const updateMstSupplier = useCallback(
    async (updatedRows: MstSupplierWithId[]) => {
      const datalist = updatedRows.map(({ isEdited, orgSupplierId, supplierName, editedFields, errors, ...rest }) => ({
        ...rest,
        orgSupplierId: orgSupplierId ??  '',
        supplierName: supplierName ?? ''
      }));
      
      try {
        await fetchSupplierUpdate({
          mstSupplierUpate: { body: datalist }
        })
        if (onReload) onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === "error") {
          const gridApi = gridRef.current?.api
          err.errors.forEach((errrow: any) => {
            const {rowdata}=errrow
            const orgSupplierId = (rowdata as { org_supplier_id : string | null }).org_supplier_id;
            const errRow = updatedRows.find((rowdatas) => rowdatas.orgSupplierId === orgSupplierId)
            if (errRow) {
              // UpdateAPIのErrorは、PKをエラー表記して、サーバーから取得したエラーを表示する
              errRow.errors = { orgSupplierId: [errrow.errorMessage] }
              const rowNode = gridApi?.getRowNode(errRow.id || '')
              if (rowNode) {
                rowNode.setData(errRow)
              }
            }
          });
        }
      }
    }, [fetchSupplierUpdate, searchParam]);

  const handleImportExcel = (data: any[]) => {
    // console.log("handleImportExcel",  data)
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstSupplierWithId> = {
      };
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (['deleteFlg', 'enableShipdayMon', 'enableShipdayTue', 'enableShipdayWed', 'enableShipdayThu', 
            'enableShipdayFri', 'enableShipdaySat', 'enableShipdaySun', 'enableOrderdayMon', 'enableOrderdayTue', 
            'enableOrderdayWed', 'enableOrderdayThu', 'enableOrderdayFri', 'enableOrderdaySat', 'enableOrderdaySun'].includes(tableField)) {
            setvalue = parseBoolean(importvalue)

          } else if (['minOrderQuantityOnce', 'orderLeadtime'].includes(tableField)) {
            setvalue = parsePositiveInteger(importvalue);
          
          } else if (['orderLimitTime'].includes(tableField)) {
            setvalue = parseTime(importvalue);
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstSupplierWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstSupplierWithId;
    });
    return { mappedData, hasError }
  };

  const {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    parseStringValue,
    parseTime,
    timeParser,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    onReload,
    onUploadData,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateMstSupplier,
    handleImportExcel,
    createBlankRow,
    fieldValidate,
    cellValueChanged,
  })

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef )[] => 
  [
    {
      headerName: '基本情報',
      children: [
        { 
          headerName: '仕入先企業ID',
          field: 'orgSupplierId',
          editable: (params) => params.data && params.data.insertDatetime === null,
          cellClassRules: {
            ...getCellClassRules(),
            'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
          },
          rowDrag: true,
        },
        { 
          headerName: '仕入先プロダクト統一企業ID',
          field: 'supplierCompanyId',
        }, 
        { 
          headerName: '仕入先企業名',
          field: 'supplierName',
          editable: true,
          cellClassRules: getCellClassRules(),

        }, 
        { 
          headerName: '会社法人等番号（登記所）',
          field: 'supplierHojinNo',
          editable: true,
          cellClassRules: getCellClassRules(),
        },
        
        { 
          headerName: '本社住所',
          field: 'supplierAddress',
          editable: true,
          cellClassRules: getCellClassRules(),

        }, 
        { 
          headerName: 'GS1事業者コード',
          field: 'supplierGln',
          editable: true,
          cellClassRules: getCellClassRules(),
        }
      ],
      
    },
    {
      headerName: '発注条件',
        children: [
          { 
            headerName: '1回あたり最低発注数',
            field: 'minOrderQuantityOnce',
            editable: true,
            cellClass:'number-cell',
            cellEditor:'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0, 
            },
            valueParser: numberValueParser,
            valueFormatter: numberValueFormatter,
          },
          { 
            headerName: '発注リードタイム',
            field: 'orderLeadtime',
            editable: true,
            cellClass:'number-cell',
            cellEditor:'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0, 
            },
            valueParser: numberValueParser,
            valueFormatter: numberValueFormatter,
          },
          { 
            headerName: '締め時間',
            field: 'orderLimitTime',
            editable: true,
            cellClass:'number-cell',
            cellEditor:'agTextCellEditor',
            cellRenderer: TimeCellRenderer,
            valueParser: timeParser,
          }
        ]
    },
    {
      headerName: '出荷可能フラグ',
      children: [
        { 
          headerName: '月',
          field: 'enableShipdayMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableShipdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableShipdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableShipdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableShipdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableShipdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableShipdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        }
      ],
    },
    {
      headerName: '受注受付可能フラグ',
      children: [
        { 
          headerName: '月',
          field: 'enableOrderdayMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableOrderdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',

          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableOrderdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableOrderdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableOrderdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableOrderdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableOrderdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue, 
        },
      ],
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue, 
    },
  ];

  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])

  // ダウンロードファイル用のカラム定義
  useEffect(() => {
    if (setDownloadColumnDefs)
      setDownloadColumnDefs([
        { headerName: '仕入先企業ID', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }] },
        { headerName: '仕入先プロダクト統一企業ID', children: [{ headerName: 'supplier_company_id', field: 'supplierCompanyId', cellClass: 'stringType' }] },
        { headerName: '仕入先企業名', children: [{ headerName: 'supplier_name', field: 'supplierName', cellClass: 'stringType' }] },
        { headerName: '会社法人等番号（登記所）', children: [{ headerName: 'supplier_hojin_no', field: 'supplierHojinNo', cellClass: 'stringType' }] },
        { headerName: '本社住所', children: [{ headerName: 'supplier_address', field: 'supplierAddress', cellClass: 'stringType' }] },
        { headerName: 'GS1事業者コード', children: [{ headerName: 'supplier_gln', field: 'supplierGln', cellClass: 'stringType' }] },
        { headerName: '1回あたり最低発注数', children: [{ headerName: 'min_order_quantity_once', field: 'minOrderQuantityOnce', cellClass: 'numberType' }] },
        { headerName: '発注リードタイム', children: [{ headerName: 'order_leadtime', field: 'orderLeadtime', cellClass: 'numberType' }] },
        { headerName: '締め時間', children: [{ headerName: 'order_limit_time', field: 'orderLimitTime', cellClass: 'stringType' }] },
        { headerName: '出荷可能フラグ（月曜日）', children: [{ headerName: 'enable_shipday_mon', field: 'enableShipdayMon', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（火曜日）', children: [{ headerName: 'enable_shipday_tue', field: 'enableShipdayTue', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（水曜日）', children: [{ headerName: 'enable_shipday_wed', field: 'enableShipdayWed', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（木曜日）', children: [{ headerName: 'enable_shipday_thu', field: 'enableShipdayThu', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（金曜日）', children: [{ headerName: 'enable_shipday_fri', field: 'enableShipdayFri', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（土曜日）', children: [{ headerName: 'enable_shipday_sat', field: 'enableShipdaySat', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（日曜日）', children: [{ headerName: 'enable_shipday_sun', field: 'enableShipdaySun', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（月曜日）', children: [{ headerName: 'enable_orderday_mon', field: 'enableOrderdayMon', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（火曜日）', children: [{ headerName: 'enable_orderday_tue', field: 'enableOrderdayTue', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（水曜日）', children: [{ headerName: 'enable_orderday_wed', field: 'enableOrderdayWed', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（木曜日）', children: [{ headerName: 'enable_orderday_thu', field: 'enableOrderdayThu', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（金曜日）', children: [{ headerName: 'enable_orderday_fri', field: 'enableOrderdayFri', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（土曜日）', children: [{ headerName: 'enable_orderday_sat', field: 'enableOrderdaySat', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（日曜日）', children: [{ headerName: 'enable_orderday_sun', field: 'enableOrderdaySun', cellClass: 'booleanType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
      ])
  }, [setDownloadColumnDefs])

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...initRecordEditEx()})))
    }
  }, [list.list]);

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            key={`${row[0]?.companyId}-${editorDataReady}`}
            rowData={row}
            ref={gridRef}
            getRowId={(params) => params.data.id}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            autoSizeStrategy={autoSizeStrategy}
            loadingOverlayComponent={LoadingOverlay}
          />
        </div>
      </div>
    </div>
  );
}

export default DataList;
