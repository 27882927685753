import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { Button } from '@mui/material'

interface SearchButtonProps {
  onSearchClick?: () => void
  caption?: string
  searchId?: string
}

function SearchButton({ caption = '検索', searchId ,onSearchClick}: SearchButtonProps) {
  return (
    <Button type="submit" id={searchId} variant="contained" startIcon={<SearchIcon />} onClick={onSearchClick}>
      {caption}
    </Button>
  )
}

export default SearchButton
