import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  GetMstCenterSupplierCenterSupplierGetRequest,
  ResponseResult,
  ResponseUpdate,
  MstCenterSupplierUpdateCenterSupplierUpdatePostRequest,
  CenterSupplier,
  MstCenterSupplier,
} from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface MstCenterSupplierSeq extends MstCenterSupplier{
  id: string
}

export interface MstCenterSuppliersSeq extends CenterSupplier{
  list: MstCenterSupplierSeq[]
}

export const useCenterSupplier = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [result, setResult] = useState<ResponseResult>()
  
  const [centerSupplierSearch, setCenterSupplierSearch] = useState<CenterSupplier>(initRequestParam)
  const [centerSupplierSearchIsLoading, setCenterSupplierSearchIsLoading] = useState(false)
  const [centerSupplierSearchResult, setCenterSupplierSearchResult] = useState<ResponseResult | undefined>(undefined)
  const [centerSupplierUpdateResult, setCenterSupplierUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchCenterSupplierSearch = (searchParam: GetMstCenterSupplierCenterSupplierGetRequest) => {
    setCenterSupplierSearch(initRequestParam)
    setCenterSupplierSearchResult(undefined)
    setCenterSupplierSearchIsLoading(true)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstCenterSupplierCenterSupplierGet(searchParam)
      })
      .then((response) => {
        setCenterSupplierSearch({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            id: Math.random().toString(32).substring(2),
          }))
        })
        
        setCenterSupplierSearchResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setCenterSupplierSearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setCenterSupplierSearchIsLoading(false)
        setIsLoading(false)
      })
  }

  const fetchCenterSupplierUpdate = async (body: MstCenterSupplierUpdateCenterSupplierUpdatePostRequest): Promise<ResponseUpdate> => {
    setIsUpdating(true);
    setResult(undefined)
    try {
      const token = await auth.currentUser?.getIdToken(true);
      if (!token) throw new Error('Token not found');
      
      const conf = new Configuration({
        basePath: process.env.REACT_APP_API_BASE_PATH,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const api = new DefaultApi(conf);
      const response = await api.mstCenterSupplierUpdateCenterSupplierUpdatePost(body);
      
      setResult(response.result)
      setCenterSupplierUpdateResult(response);

      if (response.result.status !== 'success') {
        const error = new Error(response.result.message || '入力エラーがあります');
        Object.assign(error, {
            ...response
        });
        throw error;
      }
      return response;
    } catch (error) {
        if (error && typeof error === 'object' && 'result' in error) {
            const apiResponse = error as ResponseUpdate; 
            await new Promise<void>(resolve => {
                setCenterSupplierUpdateResult(apiResponse);
              resolve();
            });
            return await Promise.reject(apiResponse);
        }
        const { response } = error as any;
        const errorResponse = {
            errors: [],
            result: {
                status: 'error',
                message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
                systemDate: null,
                pageNo: 0,
                systemInfo: null,
            },
            updateCount: null,
        };
        await new Promise<void>(resolve => {
            setCenterSupplierUpdateResult(errorResponse);
            setResult(errorResponse.result)
            resolve();
        });
        return await Promise.reject(errorResponse);

    } finally {
      setIsUpdating(false);
    }
  };


  return {
  	result,
  	isLoading,
  	isUpdating,

    centerSupplierSearch,
    fetchCenterSupplierSearch,
    centerSupplierSearchResult,
    centerSupplierSearchIsLoading,

    fetchCenterSupplierUpdate,
    centerSupplierUpdateResult,
  }
}