/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCustomer
 */
export interface MstCustomer {
    /**
     * 
     * @type {number}
     * @memberof MstCustomer
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCustomer
     */
    customerCompanyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    orgCustomerId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    customerName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    customerHojinNo: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    customerAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    customerGln: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCustomer
     */
    insertDatetime: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCustomer
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    insertUser: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    updateUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCustomer
     */
    deleteDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomer
     */
    deleteUser: string | null;
}

/**
 * Check if a given object implements the MstCustomer interface.
 */
export function instanceOfMstCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "customerCompanyId" in value;
    isInstance = isInstance && "orgCustomerId" in value;
    isInstance = isInstance && "customerName" in value;
    isInstance = isInstance && "customerHojinNo" in value;
    isInstance = isInstance && "customerAddress" in value;
    isInstance = isInstance && "customerGln" in value;
    isInstance = isInstance && "insertDatetime" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "insertUser" in value;
    isInstance = isInstance && "updateUser" in value;
    isInstance = isInstance && "deleteDatetime" in value;
    isInstance = isInstance && "deleteUser" in value;

    return isInstance;
}

export function MstCustomerFromJSON(json: any): MstCustomer {
    return MstCustomerFromJSONTyped(json, false);
}

export function MstCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'customerCompanyId': json['customer_company_id'],
        'orgCustomerId': json['org_customer_id'],
        'customerName': json['customer_name'],
        'customerHojinNo': json['customer_hojin_no'],
        'customerAddress': json['customer_address'],
        'customerGln': json['customer_gln'],
        'insertDatetime': (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'insertUser': json['insert_user'],
        'updateUser': json['update_user'],
        'deleteDatetime': (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': json['delete_user'],
    };
}

export function MstCustomerToJSON(value?: MstCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'customer_company_id': value.customerCompanyId,
        'org_customer_id': value.orgCustomerId,
        'customer_name': value.customerName,
        'customer_hojin_no': value.customerHojinNo,
        'customer_address': value.customerAddress,
        'customer_gln': value.customerGln,
        'insert_datetime': (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_user': value.updateUser,
        'delete_datetime': (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
    };
}

