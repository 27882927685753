
import { useState, useEffect, useCallback, useMemo, useRef, type RefObject, type Dispatch, type SetStateAction } from 'react'

import { AgGridReact } from 'ag-grid-react';
import GridTooltip from 'components/commonparts/GridTooltip/GridTooltip'
import { format } from 'date-fns'
import { type DataListPropsBase} from 'types/dataGridTypes'
import { clearMessageInfo } from 'types/types'
import { AG_GRID_LOCALE } from 'utils/agGridLocale'
import { excelSerialToDate, convertExcelTimeToHHMM, isEqualDate, isDateType, normalizeTime } from 'utils/convertFromLocalDate'

import type {GridReadyEvent,
  CellValueChangedEvent,
  ColumnMenuTab,
  ColumnState,
  ExcelDataType,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  IMenuActionParams,
  MenuItemDef,
  ProcessDataFromClipboardParams,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  ValueFormatterParams,
  ValueParserParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'

// import type { ResponseResult } from 'api-client';

//  import {  } from 'ag-grid-community';
// to use myTheme in an application, pass it to the theme grid option
// const myTheme = themeQuartz
// 	.withParams({
//         browserColorScheme: "light",
//         headerFontSize: 14,
//         spacing: 4
//     });

interface GridProps extends DataListPropsBase {
  gridRef:RefObject<AgGridReact>
  isUpdating:boolean,
  columnMapping: Record<string, string>,
  primayKey: string[],
  updateData?: (updatedRows:any[]) => void,
  handleImportExcel?: (list: any[]) => {mappedData: any[], hasError :boolean},
  createBlankRow: () => any,
  fieldValidate: (pRow: any, field: string | undefined, newValue:any) => boolean,
  cellValueChanged? : (event: CellValueChangedEvent, haserror:boolean) => void,
  setRowChangeTriggerRowID?: Dispatch<SetStateAction<string | null>>, // 値変更時に、useStateの値を参照する処理を行う場合に使用
}

export const useGridCommon = ({
  gridRef,
  isLoading,
  onReload,
  onUploadData,
  // onReset,
  // setOnReset,
  // onTriggerAction,
  // setOnTriggerAction ,
  updateTrigger,
  setHasGridEdited,
  setResultMessage,
  isUpdating,
  columnMapping,
  primayKey,
  updateData,
  handleImportExcel,
  createBlankRow,
  fieldValidate,
  cellValueChanged,
  setRowChangeTriggerRowID,
}: GridProps) => {
  const [editing, setEditing] = useState(false)
  // const [gridReady, setGridReady] = useState(false)
  const gridReady = useRef(false);
  // const gridInited = useRef(false);
  // const isFirstRender = useRef(true);
  // const [gridInited, setGridInited] = useState(false)
  // const [gridApi, setGridApi] = useState<GridApi>()
  // const gridRef = useRef<AgGridReact>(null)

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //     return;
  //   }
  // }, []);

  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const autoSizeStrategy = useMemo<
    SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy
  >(() => ({ type: 'fitCellContents' }), [])

  // useEffect(() => {
  //   if (onReset) {
  //     setOnReset(false)
  //   }
  // }, [onReset, setOnReset])


  
  // useEffect(() => {
  //   if (onTriggerAction) {
  //       const updatedRows = beforeUpdate()
  //       if (updatedRows.length>0) updateData(updatedRows)
  // }, [onTriggerAction])
  useEffect(() => {
    if (updateTrigger === undefined || !updateData) return
    console.log("fook! updateTrigger", updateTrigger)
    const updatedRows = beforeUpdate()
    if (updatedRows.length>0) updateData(updatedRows)
  }, [updateTrigger])


  useEffect(() => {
    if (onUploadData && handleImportExcel) {
      const {mappedData, hasError} = handleImportExcel(onUploadData)
     
      afterImportExcel(mappedData, hasError)
    }
  }, [onUploadData])

  useEffect(() => {
    setHasGridEdited(editing)
  }, [editing, setEditing])

  useEffect(() => {
    if (!gridRef.current?.api || gridRef.current.api.isDestroyed()) return

    if (isLoading || isUpdating) {
      gridRef.current.api.showLoadingOverlay()
    } else {
      gridRef.current.api.hideOverlay()
    }
  }, [isLoading, isUpdating])

  const onGridReady = ((event: GridReadyEvent<any, any>) => {
    // console.log('fook! onGridReady', event.api)
    // setGridApi(event.api)
    gridReady.current=true
    // setGridReady(true);

    event.api.updateGridOptions({
      // localeText : localeText,
      onRowDataUpdated,
      onCellValueChanged,
      defaultColDef,
      enableRangeSelection: true,
      fillHandleDirection: 'xy',
      animateRows: true,
      tooltipShowDelay: 0,
      tooltipHideDelay: 3000,
      rowSelection: 'single',
      // autoSizeStrategy:autoSizeStrategy,
      rowDragManaged: true,
      rowHeight: 36,
      allowContextMenuWithControlKey: true,
      getContextMenuItems,
      // loadingOverlayComponent : LoadingOverlay,
      overlayNoRowsTemplate:
        '<span aria-live:"polite" aria-atomic:"true",pos>条件に合致する商品が見つかりません</span>',
      suppressLastEmptyLineOnPaste: true,
      processDataFromClipboard,
      // onGridPreDestroyed: () => { 
      //   console.log('fook! onGridPreDestroyed')
      // },
      // reactiveCustomComponents:true,
      // onFilterChanged:onFilterChanged,
    })
    // 以下のinitに設定されているプロパティは、DOMでしか設定できない模様
    // gridRef.current.api.setGridOption('localeText' as ManagedGridOptionKey, localeText)
    // gridRef.current.api.setGridOption('autoSizeStrategy' as ManagedGridOptionKey, autoSizeStrategy)
    // gridRef.current.api.setGridOption('loadingOverlayComponent' as ManagedGridOptionKey, LoadingOverlay)
    // gridRef.current.api.setGridOption('reactiveCustomComponents' as ManagedGridOptionKey, true)

  });

  const onRowDataUpdated = useCallback((event: RowDataUpdatedEvent) => {
    if (!event.api || event.api.isDestroyed()) return
    // setGridApi(event.api)

    if (!editing) {
      let isedit = false
      event.api.forEachNode((node) => {
        if (node.data) {
          isedit = node.data.isEdited || isedit
        }
      })

      setEditing(isedit)
    }
    // console.log('fook! Row Data Updated')
  }, [])

  // const onBtSaveSortState = useCallback(() => {
  //   if (!gridApi) return
  //   const allState = gridRef.current.api.getColumnState();
  //   const sortState = allState.map((state) => ({
  //     colId: state.colId,
  //     sort: state.sort,
  //     sortIndex: state.sortIndex,
  //   }));
  //   // window.sortState = sortState;
  //   console.log("sort state saved", sortState);
  // }, []);

  const getCellClassRules = () => ({
    'error-cell': (params: any) => {
      const rowData = params.data as typeof params.data
      const { field } = params.colDef
      const hasError = Array.isArray(rowData.errors[field]) && rowData.errors[field].length > 0;
      return hasError || false
    },
    'current-updated-cell': (params: any) => {
      const rowData = params.data as typeof params.data
      const { field } = params.colDef
      return (
        rowData.isEdited &&
        (!rowData.errors?.[field] || rowData.errors[field].length === 0) &&
        rowData.editedFields?.has(field)
      )
    },
    'editable-cell': (params: any) => {
      const { editable } = params.colDef
      let iseditable = false
      if (typeof editable === 'function') {
        iseditable = editable(params)
      } else {
        iseditable = editable
      }
      return iseditable
    },
  })

  const processDataFromClipboard = (
    params: ProcessDataFromClipboardParams,
  ): string[][] | null => {
    // Info表示をリセット
    setResultMessage(clearMessageInfo())
    const { api } = params
    if (!api) return null

    const data = [...params.data]
    const lastIndex = api.getDisplayedRowCount() - 1
    const focusedCell = api.getFocusedCell()
    const focusedIndex = focusedCell!.rowIndex

    // 一番下に空白行がある場合、削除する
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }
    // 一番最後のセルを選択してペーストした場合、一番下の行の下に、ペーストデータを新規で追加する
    // ペーストデータの1行目に、既存データをコピーすると、アクティブセルの下にペーストしたいデータが追加される
    // if (lastIndex === focusedIndex) {
    //   const focusdrow = gridRef.current.api.getDisplayedRowAtIndex(focusedIndex);
    //   const forcusrowdata = focusdrow?.data as MstCalendarWithId;
    //   const pasteColSize = data[0].length;
    //   const arrayEmpty = new Array(pasteColSize).fill("");
    //   let currentColumn: any = focusedCell!.column;
    //   for(let i = 0; i < pasteColSize; i += 1) {
    //     const { field } = currentColumn.colDef;
    //     arrayEmpty[i] = forcusrowdata[field as keyof typeof forcusrowdata]?.toString();
    //     currentColumn = api.getDisplayedColAfter(currentColumn);
    //   }
    //   // console.log("last row data",arrayEmpty)
    //   data.unshift(arrayEmpty);
    // }

    const pasteRowSize = data.length
    // console.log("processDataFromClipboard",lastIndex,focusedIndex,pasteRowSize,focusedCell,data,params)

    // add new row
    if (focusedIndex + pasteRowSize - 1 > lastIndex) {
      const rowsToAdd: any[] = []
      const resultLastIndex = focusedIndex + (pasteRowSize - 1)
      const numRowsToAdd = resultLastIndex - lastIndex
      for (let i = 0; i < numRowsToAdd; i += 1) {
        rowsToAdd.push(createBlankRow())
      }
      // console.log('fook! add row', numRowsToAdd, rowsToAdd)
      api.applyTransaction({ add: rowsToAdd })
    }
    return data
  }

  const tooltipValue = useCallback((params: any) => {
    const { value, data, colDef } = params
    const { field } = colDef

    if (data.errors && Array.isArray(data.errors[field]) && data.errors[field].length > 0) {
      return `${data.errors[field].join(', ')}`
    }
    return value ? `${value}` : 'No Value'
  }, [])

  const customMainMenuItems = useCallback((params: GetMainMenuItemsParams): (MenuItemDef | string)[] => {
    const menuItems: (MenuItemDef | string)[] = params.defaultItems.slice()

    menuItems.splice(1, 0, {
      name: '列非表示',
      subMenu: [
        {
          name: '左側の隠れた列を表示',
          action: () => {
            const allColumns = params.api.getColumns()
            if (!allColumns) return
            const currentIndex = allColumns.findIndex((col) => col.getColId() === params.column.getColId())
            for (let i = currentIndex - 1; i >= 0; i -= 1) {
              if (!allColumns[i].isVisible()) {
                params.api.setColumnVisible(allColumns[i].getColId(), true)
                break
              }
            }
          },
        },
        {
          name: '右側の隠れた列を表示',
          action: () => {
            const allColumns = params.api.getColumns()
            if (!allColumns) return
            const currentIndex = allColumns.findIndex((col) => col.getColId() === params.column.getColId())
            for (let i = currentIndex + 1; i < allColumns.length; i += 1) {
              if (!allColumns[i].isVisible()) {
                params.api.setColumnVisible(allColumns[i].getColId(), true)
                break
              }
            }
          },
        },
        {
          name: 'この列を非表示',
          action: () => {
            params.api.setColumnVisible(params.column.getColId(), false)
          },
        },
      ],
    } as MenuItemDef)

    menuItems.splice(2, 0, {
      name: '表示設定の保存',
      action: () => {
        const columnState: ColumnState[] = params.api.getColumnState()
        localStorage.setItem('gridColumnState', JSON.stringify(columnState))
      },
    } as MenuItemDef)

    const pinSubMenuIndex = menuItems.findIndex((item) => item === 'pinSubMenu')
    if (pinSubMenuIndex >= 0) {
      menuItems[pinSubMenuIndex] = {
        name: '列固定',
        subMenu: [
          {
            name: 'ピンなし',
            action: () => params.api.setColumnPinned(params.column.getColId(), null),
          },
          {
            name: '左にピン',
            action: () => params.api.setColumnPinned(params.column.getColId(), 'left'),
          },
          {
            name: '右にピン留め',
            action: () => params.api.setColumnPinned(params.column.getColId(), 'right'),
          },
        ],
      } as MenuItemDef
    }

    const autoSizeIndex = menuItems.findIndex((item) => item === 'autoSizeThis')
    if (autoSizeIndex >= 0) {
      menuItems.splice(autoSizeIndex, 1)
    }

    const autoSizeAllIndex = menuItems.findIndex((item) => item === 'autoSizeAll')
    if (autoSizeAllIndex >= 0) {
      menuItems.splice(autoSizeAllIndex, 1)
    }

    const resetColumnIndex = menuItems.findIndex((item) => item === 'resetColumns')
    if (resetColumnIndex >= 0) {
      menuItems[resetColumnIndex] = {
        name: '列をリセット',
        action: () => {
          params.api.resetColumnState()
          localStorage.removeItem('gridColumnState')
        },
      }
    }
    return menuItems
  }, [])

  const addRowAction = useCallback((actionParams: IMenuActionParams) => {
    // 右クリックの新規行追加選択時
    const { node, api } = actionParams
    // console.log("addRowAction", actionParams, api.isDestroyed())
    if (!node) return
    const { data, rowIndex } = node

    if (api && data && rowIndex !== null) {
      const newRow = createBlankRow()
      newRow.isEdited = true

      // console.log('fook!addRowAction', newRow.id, newRow)
      const displayedRowIndex = node.rowIndex
      if (displayedRowIndex !== null) {
        const addIndex = displayedRowIndex + 1

        api.applyTransaction({
          add: [newRow],
          addIndex,
        })
        api.redrawRows()

        if (setRowChangeTriggerRowID)setRowChangeTriggerRowID(newRow.id)
      }
    }
  }, [])

  const deleteRowAction = useCallback((actionParams: IMenuActionParams) => {
    // 右クリックの行削除選択時
    const { node, api } = actionParams
    if (!node) return
    const { data } = node

    console.log("fook! deleteRowAction", data)
    api?.applyTransaction({ remove: [data] })
  }, [])

  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const { node } = params
    if (!node) {
      return [
        {
          name: 'コピーする',
          action: () => params.api.copySelectedRangeToClipboard(),
        },
        'separator',
        {
          name: '貼り付ける',
          action: () => params.api.pasteFromClipboard(),
        },
      ]
    }
    const { data, rowIndex } = node

    const result: (string | MenuItemDef)[] = [
      {
        name: '新規行を追加する',
        action: addRowAction,
      },
      {
        name: '行を削除',
        action: deleteRowAction,
        disabled: data ? !data.isNewRecord: true,
      },
      //   ...(isNewRow
      //     ? [
      //         {
      //           name: "行を削除する",
      //           action: () => deleteRowAction
      //         },
      //       ]
      //     : []),
      'separator',
      {
        name: 'コピーする',
        action: () => params.api.copySelectedRangeToClipboard(),
      },
      {
        name: '行コピーする',
        action: () => params.api.copySelectedRowsToClipboard(),
      },
      'copyWithHeaders',
      'separator',
      {
        name: '貼り付ける',
        action: () => params.api.pasteFromClipboard(),
      },
    ]

    return result
  }, [])


  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressHeaderMenuButton: false,
    sortable: true,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'agTextColumnFilter',
        },
        {
          filter: 'agSetColumnFilter',
        },
      ],
    },
    menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
    mainMenuItems: customMainMenuItems,
    tooltipValueGetter: tooltipValue,
    tooltipComponent: GridTooltip,
    cellClassRules: getCellClassRules(),
  }),[])

  /**
   * すべての行のデータを配列で取得する
   * get All Row data Array
   */
  const getRowData = useCallback(() : any[] => {
    if (!gridRef.current?.api || gridRef.current.api.isDestroyed()) return []
    const rowData: any[] = []
    gridRef.current.api.forEachNode((node) => rowData.push(node.data))
    return rowData
  }, [])
  const checkAndSetError = useCallback((pRow: any, field: string, error: string | null): boolean => {
    if (!field) return false;
    if(!gridRef.current) return false;

    let hasErrors = false
    // const trRow = pRow
    const rowNode = gridRef.current.api.getRowNode( pRow.id || '' );
    const trRow = rowNode?.data ?? pRow;
    // console.log("fook!checkAndSetError",trRow, rowNode)
    if (error) {
      trRow.errors[field] = [error];
      trRow.editedFields.add(field)
    //   if (!trRow.editedFields.includes(field)) trRow.editedFields.push(field)
      hasErrors = true;
    } else if (trRow.errors && trRow.errors[field]) {
      delete trRow.errors[field]
    }
    rowNode?.updateData(trRow);
    return hasErrors
  }, [gridRef.current]); // gridApi を依存配列に追加

  const duplicateCheck = useCallback((pRow: any, newValue: any): boolean => {
    // キー重複エラーチェック
    if(!gridRef.current) return false;
    // console.log("fook!duplicateCheck",pRow, primayKey, isDateType(pRow[primayKey[0]]))
    let hasErrors = false;
    gridRef.current.api.forEachNode((node) => {
        if (node?.data && node.data?.id !== pRow.id) {
          const isSamePK = primayKey.every((field) => 
            isDateType(pRow[field]) ? isEqualDate(node.data?.[field], pRow[field]) : node.data?.[field] === pRow[field]
          )

          if (isSamePK) {
            // console.log("fook!duplicateCheckError",pRow,primayKey[0], node.data?.[primayKey[0]], pRow[primayKey[0]])
            hasErrors = true
            primayKey.forEach((field) => {
              checkAndSetError(pRow, field, '入力値はすでに登録されています')
            })
          }
        }
      })
    return hasErrors
  }, [])
  const colDataValidate = useCallback((pRow: any, field: string | undefined, newValue:any):boolean => {
    // console.log("hooks!colDataValidate", field, gridApi)
    if (!field) return false;
    if(!gridRef.current) return false;
    
    let hasErrors = false
    const rowNode = gridRef.current.api.getRowNode( pRow.id || '' );
    const trRow = rowNode?.data ?? pRow;

    checkAndSetError(trRow, field, "");
    hasErrors = fieldValidate(trRow, field, newValue);
    // console.log("colDataValidate", field, newValue, trRow, hasErrors)
    return hasErrors
  }, []);
  
  /**
   * 行に含まれる項目に入力チェックを実施
   * validate all cell in row
   * @param pRow 
   * @returns 
   */
  const rowDataValidate = (pRow:any):boolean => {
    if (!pRow) return false;
    const trRow = pRow
    let hasErrors = false
    trRow.errors = {};
    Object.keys(columnMapping).forEach((key) => {
      const fieldValue = trRow[columnMapping[key] as keyof typeof trRow];
      colDataValidate(trRow, columnMapping[key], fieldValue)
    });

    if (trRow.errors && Object.keys(trRow.errors).length > 0) {
      hasErrors = true;
    }
    return hasErrors
  } 

    /**
     * セルの変更イベントの共通処理
     * 個別処理が必要な場合は、cellValueChangedを定義すると、共通処理の後に個別処理が実施される
     * 
     */
  const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
    // Info表示をリセット
    setResultMessage(clearMessageInfo())
    const { data, colDef, newValue, oldValue, node, api } = event;
    const { field } = colDef;
    if (!field || !api || api.isDestroyed()) return;
    // setGridApi(event.api)
    // console.log("onCellValueChanged change", field, newValue, oldValue)

    const updatedRow = data  
    // 値の変更時に、APIのエラーを解消
    if (primayKey.indexOf(field) >= 0 ){
      primayKey.forEach((key) => {
          // haserror = checkAndSetError(updatedRow, key, updatedRow[key]) || haserror
          checkAndSetError(updatedRow, key, "")
      })
    }
    const haserror = colDataValidate(updatedRow, field, newValue)

    const isSameValue = isDateType(updatedRow[field]) ? isEqualDate(newValue, oldValue) : newValue === oldValue
    if (!isSameValue){
      setEditing(true)
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
      // console.log("onCellValueChanged change", field, newValue, oldValue, updatedRow, haserror)
    }

    node.setData(updatedRow);
    // DataList側でセル変更時の個別処理を実施
    // カスタムフックから直接コンポーネントの状態にアクセスすることはできない
    // useStateの値を参照する処理がある場合、setRowChangeTriggerRowIDを設定してEffectで処理する必要がある
    if (cellValueChanged)cellValueChanged(event, haserror)
    if (setRowChangeTriggerRowID)setRowChangeTriggerRowID(updatedRow.id)
  }, []);

  /**
   * データ更新の前処理
   * 入力チェックを実施し、変更対象行のみ返す
   */
  const beforeUpdate = useCallback((): any[] => {
    setResultMessage(clearMessageInfo())
    if (!gridRef.current?.api || !gridRef.current.api) return []
    let hasErrors = false

    const updatedRows: any[] = [];
    gridRef.current.api.forEachNode((node) => {
      if (node.data) {
        // console.log("beforeUpdate", node.data.isEdited, node.data.editedFields.size, node.data)
        // 変更がない行（追加しただけの新規行）はskip
        if (node.data.editedFields.size > 0){
          hasErrors = rowDataValidate(node.data) || hasErrors
          if (node.data.isEdited) updatedRows.push(node.data);
        }
      }
    })
    // console.log("fook!beforeUpdate",updatedRows, hasErrors)

    if (hasErrors) {
      // console.log("hasErrors", updatedRows)
      setResultMessage({
        status: 'error',
        message: '入力エラーがあります',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
      gridRef.current.api.applyTransaction({ update: updatedRows });
      gridRef.current.api.redrawRows();
      return [];
    }

    if (updatedRows.length === 0 ) {
      setResultMessage({
        status: 'warning',
        message: '更新対象のデータがありません',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
    }

    return updatedRows;
  }, [])

  /**
   * ファイルインポートの共通後処理
   * 各機能のコンポーネントで型変換などを済ませた後、グリッドに取り込む
   * その際、新規と更新の判定や、変更箇所のマークをつける
   */
  const afterImportExcel = useCallback((importList: any[], hasError: boolean) => {
    // console.log("handleImportExcel", hasError, importList)
    if (importList.length === 0) return;
    if (hasError) {
      setResultMessage({
        status: 'warning',
        message: '取り込めない値はスキップされました',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    } else {
      // Info表示をリセット
      setResultMessage(clearMessageInfo())
    }

    if (!gridRef.current?.api) return;
    const gridRows = getRowData();

    // console.log("fook!afterImportExcel",importList,gridRows)
    // const importUpdateRows = importList.filter((importRow) =>
    //     gridRows.some((row) =>
    //         primayKey.every((field) => importRow[field] === row[field])
    //       )
    // );
    const newRows: any[] = [];
    const editedRowsTemp: any[] = [];

    importList.forEach((importRow) =>{

      // console.log("for importList",importRow)
      const updateGridRow = gridRows.find((row) =>
          primayKey.every((field) => 
            isDateType(row[field]) ? isEqualDate(importRow[field], row[field]) : importRow[field] === row[field]
          )
      )
      // console.log("find",updateGridRow)
      if (updateGridRow) {
        // 既存データの更新
        let editCount=0
        Object.keys(importRow).forEach((field) => {
          const importedValue = importRow[field] ?? null;
          const existingValue = updateGridRow[field] ?? null;

          if (!Object.values(columnMapping).includes(field)) {
            // skip
          } else if (isDateType(existingValue)) {
            if (!isEqualDate(importedValue, existingValue)){
              updateGridRow.editedFields.add(field)
              editCount += 1
            }

          } else if (String(importedValue) !== String(existingValue)) {
            updateGridRow.editedFields.add(field)
            editCount += 1
          }                
          // if (String(importedValue) !== String(existingValue)) {
          //     updateGridRow.editedFields.add(field)
          // //   if (!updateGridRow.editedFields.includes(field)) updateGridRow.editedFields.push(field)
          //   editCount += 1
          // }
          });
          if (editCount > 0) {
            const updatedRow = {
              ...updateGridRow,
              ...importRow,
              isEdited: true,
              errors: {},
            };
            // 入力チェック
            rowDataValidate(updatedRow)
            // console.log("fook!afterImportExcel 既存データ",updatedRow)
            editedRowsTemp.push(updatedRow);
          }
      } else {
          // 新規データの追加
          const newRow = {
              ...createBlankRow(),
              ...importRow,
              isEdited: true,
              errors: {},
              // editedFields:Object.keys(importRow)
              editedFields:new Set(Object.values(columnMapping))
            };
            // console.log("fook!afterImportExcel 新規データの追加",newRow)
            rowDataValidate(newRow)
            newRows.push(newRow);
      } 
    });
    // console.log("fook!afterImportExcel after",newRows, editedRowsTemp)
    gridRef.current.api.applyTransaction({ add: newRows, update: editedRowsTemp });
    gridRef.current.api.refreshCells();
    console.log("fook!afterImportExcel end", getRowData())
  }, [])



  const isNonInputValue = (value: any): boolean => 
    value === null || value === undefined || value === '' || value === '-';
  
  
  const parseNumeric = useCallback((value: any): number | null => {
    if (isNonInputValue(value)) return null;
    const numericValue = Number(value);
    if (Number.isNaN(numericValue)) return null;
  
    return numericValue;
  }, [])
  
  const parsePositiveInteger = useCallback((value: any): number | null => {
    const numericValue = parseNumeric(value);
    if (numericValue === null) return null;
    if (numericValue < 0) return null;
    return Math.floor(numericValue);
  }, [])

  const parseNumericWithRange = useCallback((value: any): number | null => {
    if (isNonInputValue(value)) return null;
    const numericValue = Number(value);
    if (Number.isNaN(numericValue)) return null;
    if (numericValue < 1 || numericValue > 100) return null;
    return numericValue;
  }, []);
  
  const parseDate = useCallback((value: any): Date | null | undefined => {
    if (isNonInputValue(value)) return null;
    const parsedDate = typeof value === 'number' ? excelSerialToDate(value) : new Date(value);
    if (!parsedDate) return null;
    return Number.isNaN(parsedDate.getTime()) ? null : parsedDate;
  }, [])
  
  const parseDateValue = (params: ValueParserParams): Date | null | undefined => 
    parseDate(params.newValue)

  const dateFormatter = useCallback((params: ValueFormatterParams<Date | null>) => {
    const date = parseDate(params.value);
    return date ? format(date, 'yyyy/MM/dd') : '';
  }, [])
  
  const parseTime = useCallback((value: any): string | null | undefined => {
    if (isNonInputValue(value)) return null;
    let parsedTime = null;
    if (typeof value === 'number') {
      parsedTime = convertExcelTimeToHHMM(value)?.toString();
    } else if (typeof value === 'string') {
      parsedTime = normalizeTime(value)
    } else {
      parsedTime = null;
    }
    return parsedTime;
  }, [])

  const timeParser = (params: ValueParserParams): string | null | undefined => 
    parseTime(params.newValue)

  const parseStringValue = useCallback((params: ValueParserParams): string | null => {
    const { newValue } = params;
    if (isNonInputValue(newValue)) return null;
    return newValue.toString();
  }, [])
  
  const numberValueFormatter = (params: ValueFormatterParams): string => 
    isNonInputValue(params.value) ? '-' : params.value.toString();
  
  const numberValueParser = useCallback((params: ValueParserParams): number | null => {
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
    const parsedValue = parsePositiveInteger(newValue);
    if (node) node.setDataValue(field, parsedValue);
    return parsedValue;
  }, [])
  
  const numberRangeValueParser = useCallback((params: ValueParserParams): number | null => {
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
    const parsedValue = parseNumericWithRange(newValue);
    if (node) node.setDataValue(field, parsedValue);
    return parsedValue;
  }, [])
  
  const formatDecimalValue = useCallback((params: ValueFormatterParams, decimalPlaces = 2): string => {
    if (isNonInputValue(params.value)) return '-';
  
    const numericValue = parseNumeric(params.value);
    return numericValue === null ? params.value : numericValue.toFixed(decimalPlaces);
  }, [])
  
  const parseDecimalValue = useCallback((params: ValueParserParams, decimalPlaces = 2): number | null => {
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
  
    const numericValue = parseNumeric(newValue);
    if (numericValue === null) {
      if (node) node.setDataValue(field, null);
      return null;
    }
    const parsedValue = parseFloat(numericValue.toFixed(decimalPlaces));
    if (node) node.setDataValue(field, parsedValue);
    return parsedValue;
  }, [])
  
  const TRUE_VALUES = ['T', 't', 'TRUE', 'true', 1, '1', '１', true];
  
  const formatBooleanValue = useCallback((params: ValueFormatterParams): string => {
    const value = params.value ?? false;
    return value ? 'True' : 'False';
  }, [])
  
  const parseBoolean = useCallback((value: any): boolean => {
    let parsedValue = false;
    if (typeof value === 'boolean') {
      parsedValue = Boolean(value);
    } else if (isNonInputValue(value)) {
      parsedValue = false;
    } else {
      parsedValue = TRUE_VALUES.includes(String(value).toLowerCase());
    }
    return parsedValue;
  }, [])
  
  const parseBooleanValue = useCallback((params: ValueParserParams): boolean => {
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
    const parsedValue = parseBoolean(newValue);
  
    if (node) {
      node.setDataValue(field, parsedValue);
    }
    return parsedValue;
  }, [])
  
  const booleanFormatter = useCallback((params: ValueFormatterParams<boolean | null>) => {
    if (params.value === true) {
      return 'true';
    }
    if (params.value === false) {
      return 'false';
    }
    return '';
  }, [])
  
  const selectValueFormatter = useCallback((value: string, datalist: any[], keyField: string, nameField: string) => {
    if (isNonInputValue(value)) return '-';
  
    const vp = datalist.find((s) => s[keyField] === value);
    return vp ? `[${vp[keyField]}]${vp[nameField]}` : value;
  }, [])
  
  const selectValueParser = useCallback((params: ValueParserParams, datalist: any[], keyField: string): any | null => {
    const { newValue } = params;
    if (isNonInputValue(newValue)) return null;
  
    let vp = datalist.find((s) => s[keyField] === newValue);
  
    if (!vp && String(newValue).startsWith('[') && String(newValue).indexOf(']') > 1) {
      const codematch = String(newValue).match(/\[(.+)\]/);
      const code = codematch ? codematch[1] : '';
      vp = datalist.find((s) => s[keyField] === code);
    }
    return vp ? vp[keyField] : null;
  }, [])
  
  const typecodeFormatter = useCallback((value: string, datalist: any[]) => {
    if (isNonInputValue(value)) return '-';
  
    const vp = datalist.find((s) => s.typeCode === value);
    return vp ? `${vp.typeName}` : value;
  }, [])
  
  const typeCodeParser = useCallback((value: string, datalist: any[]) => {
    if (isNonInputValue(value)) return null

    console.debug("**********selectTypecodeValueParser", value)
    let vp = datalist.find((s) => s.typeCode === value)
    // typecodeの場合は、区分値も受け入れ可能
    if (!vp) {
        vp = datalist.find((s) => s.typeName === value)
    }
    
    if (!vp && String(value).startsWith('[') && String(value).indexOf(']') > 1) {
        const codematch = String(value).match(/\[(.+)\]/)
        const code = codematch ? codematch[1] : ''
        // console.debug("**********selectTypecodeValueParser code", code)
        vp = datalist.find((s) => s.typeCode === code)
    }
    return vp ? vp.typeCode : null

  }, [])
  
  const selectTypecodeValueParser = useCallback((params: ValueParserParams, datalist: any[]): any | null => {
    const { newValue } = params;
    return typeCodeParser(newValue, datalist);
  }, [])

  const excelStyles = [
    {
      id: 'numberType',
      numberFormat: {
        format: '#,##0',
      },
    },
    {
      id: 'decimalType',
      numberFormat: {
        format: '#,##0.000',
      },
    },
    {
      id: 'booleanType',
      dataType: 'Boolean' as ExcelDataType,
    },
    {
      id: 'stringType',
      dataType: 'String' as ExcelDataType,
    },
    {
      id: 'dateType',
      dataType: 'DateTime' as ExcelDataType,
      numberFormat: {
        format: 'yyy-mm-ddThh:mm:ss',
      },
    },
  ]

  //   const onFilterChanged = useCallback(() => {
  //     if (gridApi) {
  //       const allColumns = gridRef.current.api.getColumns();
  //       if (allColumns) {
  //         allColumns.forEach((column) => {
  //           gridRef.current.api.getColumnFilterInstance(column.getColId())
  //             .then((filterInstance) => {
  //               if (filterInstance && 'refreshFilterValues' in filterInstance) {
  //                 (filterInstance as any).refreshFilterValues();
  //               }
  //             });
  //         });
  //       }
  //       gridRef.current.api.redrawRows();
  //     }
  //   }, []);

  return {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    parseNumericWithRange,
    parseStringValue,
    numberRangeValueParser,
    parseTime,
    timeParser,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
    getRowData
  }
}
