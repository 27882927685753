import { useState } from 'react'

import { initRequestParamWithDaylist } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetStockStockGetRequest, ResponseResult, ResponseUpdate, Stock, Stocks, StockUpdateStockUpdatePostRequest } from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface StockAdjust extends Stock {
  id: string
  systemDate: Date | null
  arrivalQuantityPrev: number | null
  shipQuantityPrev: number | null
  stockQuantityPrev: number | null
  orderQuantityReasonPrev: string | null
}

export interface StocksAdjust extends Stocks {
  list: StockAdjust[]
}

export const useStock = () => {
  const [stock, setStock] = useState<StocksAdjust>({...initRequestParamWithDaylist, renewalOrgItemList: null})
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const [stockUpdateResult, setStockUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const [isUpdating, setIsUpdating] = useState(false)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const calcErraRate = (shipQuantityExpected: number | null, shipQuantityRec: number | null) => {
    if (shipQuantityRec == null) return null

    return (
      (shipQuantityExpected == null ? 0 : shipQuantityExpected - shipQuantityRec) /
      (shipQuantityRec === 0 ? 1 : shipQuantityRec)
    )
  }

  const fetchStock = (searchParam: GetStockStockGetRequest) => {
    setStock({...initRequestParamWithDaylist, renewalOrgItemList: null})
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getStockStockGet({
          ...searchParam,
          companyId: searchParam.companyId,
          centerId: searchParam.centerId || undefined,
          orgSupplierId: searchParam.orgSupplierId || undefined,
          itemCode: searchParam.itemCode || undefined,
          optimizeRange: searchParam.optimizeRange,
          sort: searchParam.sort || undefined,
        })
      })
      .then((response) => {
        setStock({
          ...response.data,
          list: _.map(response.data.list, (value) => {
            const recDate = new Date(value.recDate as Date)
            return {
              ...value,
              // 用途:ダッシュボードページ
              // rechartsライブラリのdataKeyに合わせてnumberにする
              recDateToNumber: recDate.getTime(),
              errorRate: calcErraRate(value.shipQuantityExpected, value.shipQuantityRec),

              // 用途:入荷数修正ページ
              id: Math.random().toString(32).substring(2),
              systemDate: response.result.systemDate,
              arrivalQuantityPrev: value.arrivalQuantity,
              shipQuantityPrev: value.shipQuantity,
              stockQuantityPrev: value.stockQuantity,
              stockDaysPrev: value.stockDays,
              // DataGridのセレクターの型がstringのため、nullの場合は空文字を返す
              orderQuantityReasonPrev: value.orderQuantityReason ?? '',
              orderQuantityReason: value.orderQuantityReason ?? '',
            }
          }),
        })
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  // 製品のアップデートに使用されます
      const fetchUpdateStock = async (body: StockUpdateStockUpdatePostRequest): Promise<ResponseUpdate> => {
        setIsUpdating(true);
        setStockUpdateResult(undefined)
        setResult(undefined)
        try {
          const token = await auth.currentUser?.getIdToken(true);
          if (!token) throw new Error('Token not found');
          
          const conf = new Configuration({
            basePath: process.env.REACT_APP_API_BASE_PATH,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const api = new DefaultApi(conf);
          const response = await api.stockUpdateStockUpdatePost(body);
          
          setResult(response.result)
          setStockUpdateResult(response);
          
          if (response.result.status !== 'success') {
            const error = new Error(response.result.message || '入力エラーがあります');
            Object.assign(error, {
                ...response
            });
            throw error;
          }
          return response;
        } catch (error: unknown) {
          if (error && typeof error === 'object' && 'result' in error) {
              const apiResponse = error as ResponseUpdate; 
              await new Promise<void>(resolve => {
                setStockUpdateResult(apiResponse);
                resolve();
              });
              return await Promise.reject(apiResponse);
          }
          const { response } = error as any;
          const errorResponse = {
              errors: [],
              result: {
                  status: 'error',
                  message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
                  systemDate: null,
                  pageNo: 0,
                  systemInfo: null,
              },
              updateCount: null,
          };
          await new Promise<void>(resolve => {
              setStockUpdateResult(errorResponse);
              setResult(errorResponse.result)
              resolve();
          });
          return await Promise.reject(errorResponse);
        } finally {
          setIsUpdating(false);
        }
      };

  return {
    result,
    stock,
    fetchStock,
    isLoading,

    isUpdating, 
    setStockUpdateResult,
    fetchUpdateStock
  }
}
