import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  GetMstSuppliersSupplierGetRequest,
  ResponseResult,
  ResponseUpdate,
  MasterSupplierUpdateMasterSupplierUpdatePostRequest,
  Supplier,
  MstSupplier,
} from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface AutocompleteSupplier {
  label: string | null
  orgSupplierId: string | null
}

export interface MstSupplierSeq extends MstSupplier{
  id: string
}

export interface MstSuppliersSeq extends Supplier{
  list: MstSupplierSeq[]
}

export const useSupplier = () => {
  const [suppliers, setSuppliers] = useState<MstSupplier[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [result, setResult] = useState<ResponseResult>()
  
  const [autocompletSuppliers, setAutocompleteSuppliers] = useState<AutocompleteSupplier[]>([])

  const [supplierSearch, setSupplierSearch] = useState<Supplier>(initRequestParam)
  const [supplierSearchIsLoading, setSupplierSearchIsLoading] = useState(false)
  const [supplierSearchResult, setSupplierSearchResult] = useState<ResponseResult | undefined>(undefined)
  const [supplierUpdateResult, setSupplierUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchSupplierSearch = (searchParam: GetMstSuppliersSupplierGetRequest) => {
    setSupplierSearch(initRequestParam)
    setSupplierSearchResult(undefined)
    setSupplierSearchIsLoading(true)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstSuppliersSupplierGet(searchParam)
      })
      .then((response) => {
        setSupplierSearch({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            id: Math.random().toString(32).substring(2),
          }))
        })

        const centersMap = response.data.list.map((value) => ({
          label: value.supplierName,
          orgSupplierId: value.orgSupplierId,
        }))
        setAutocompleteSuppliers(centersMap)
        setSupplierSearchResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setSupplierSearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setSupplierSearchIsLoading(false)
        setIsLoading(false)
      })
  }

  const fetchSupplierUpdate = async (body: MasterSupplierUpdateMasterSupplierUpdatePostRequest): Promise<ResponseUpdate> => {
    setIsUpdating(true);
    setResult(undefined)
    try {
      const token = await auth.currentUser?.getIdToken(true);
      if (!token) throw new Error('Token not found');
      
      const conf = new Configuration({
        basePath: process.env.REACT_APP_API_BASE_PATH,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const api = new DefaultApi(conf);
      const response = await api.masterSupplierUpdateMasterSupplierUpdatePost(body);
      
      setResult(response.result)
      setSupplierUpdateResult(response);

      if (response.result.status !== 'success') {
        const error = new Error(response.result.message || '入力エラーがあります');
        Object.assign(error, {
            ...response
        });
        throw error;
      }
      return response;
    } catch (error) {
        if (error && typeof error === 'object' && 'result' in error) {
            const apiResponse = error as ResponseUpdate; 
            await new Promise<void>(resolve => {
              setSupplierUpdateResult(apiResponse);
              resolve();
            });
            return await Promise.reject(apiResponse);
        }
        const { response } = error as any;
        const errorResponse = {
            errors: [],
            result: {
                status: 'error',
                message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
                systemDate: null,
                pageNo: 0,
                systemInfo: null,
            },
            updateCount: null,
        };
        await new Promise<void>(resolve => {
          setSupplierUpdateResult(errorResponse);
            setResult(errorResponse.result)
            resolve();
        });
        return await Promise.reject(errorResponse);

    } finally {
      setIsUpdating(false);
    }
  };


  return {
  	result,
  	isLoading,
  	isUpdating,
    suppliers,
    autocompletSuppliers,

    supplierSearch,
    fetchSupplierSearch,
    supplierSearchResult,
    supplierSearchIsLoading,

    fetchSupplierUpdate,
    supplierUpdateResult,
  }
}