/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstSupplier
 */
export interface MstSupplier {
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    deleteFlg: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplier
     */
    insertDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    insertUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplier
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    updateUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplier
     */
    deleteDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    deleteUser: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    supplierCompanyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierHojinNo: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierGln: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    minOrderQuantityOnce: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    orderLeadtime: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    orderLimitTime: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdayMon: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdayTue: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdayWed: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdayThu: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdayFri: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdaySat: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableShipdaySun: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdayMon: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdayTue: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdayWed: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdayThu: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdayFri: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdaySat: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstSupplier
     */
    enableOrderdaySun: boolean | null;
}

/**
 * Check if a given object implements the MstSupplier interface.
 */
export function instanceOfMstSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deleteFlg" in value;
    isInstance = isInstance && "insertDatetime" in value;
    isInstance = isInstance && "insertUser" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "updateUser" in value;
    isInstance = isInstance && "deleteDatetime" in value;
    isInstance = isInstance && "deleteUser" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "supplierCompanyId" in value;
    isInstance = isInstance && "supplierName" in value;
    isInstance = isInstance && "supplierHojinNo" in value;
    isInstance = isInstance && "supplierAddress" in value;
    isInstance = isInstance && "supplierGln" in value;
    isInstance = isInstance && "minOrderQuantityOnce" in value;
    isInstance = isInstance && "orderLeadtime" in value;
    isInstance = isInstance && "orderLimitTime" in value;
    isInstance = isInstance && "enableShipdayMon" in value;
    isInstance = isInstance && "enableShipdayTue" in value;
    isInstance = isInstance && "enableShipdayWed" in value;
    isInstance = isInstance && "enableShipdayThu" in value;
    isInstance = isInstance && "enableShipdayFri" in value;
    isInstance = isInstance && "enableShipdaySat" in value;
    isInstance = isInstance && "enableShipdaySun" in value;
    isInstance = isInstance && "enableOrderdayMon" in value;
    isInstance = isInstance && "enableOrderdayTue" in value;
    isInstance = isInstance && "enableOrderdayWed" in value;
    isInstance = isInstance && "enableOrderdayThu" in value;
    isInstance = isInstance && "enableOrderdayFri" in value;
    isInstance = isInstance && "enableOrderdaySat" in value;
    isInstance = isInstance && "enableOrderdaySun" in value;

    return isInstance;
}

export function MstSupplierFromJSON(json: any): MstSupplier {
    return MstSupplierFromJSONTyped(json, false);
}

export function MstSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': json['delete_flg'],
        'insertDatetime': (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': json['insert_user'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': json['update_user'],
        'deleteDatetime': (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': json['delete_user'],
        'companyId': json['company_id'],
        'orgSupplierId': json['org_supplier_id'],
        'supplierCompanyId': json['supplier_company_id'],
        'supplierName': json['supplier_name'],
        'supplierHojinNo': json['supplier_hojin_no'],
        'supplierAddress': json['supplier_address'],
        'supplierGln': json['supplier_gln'],
        'minOrderQuantityOnce': json['min_order_quantity_once'],
        'orderLeadtime': json['order_leadtime'],
        'orderLimitTime': json['order_limit_time'],
        'enableShipdayMon': json['enable_shipday_mon'],
        'enableShipdayTue': json['enable_shipday_tue'],
        'enableShipdayWed': json['enable_shipday_wed'],
        'enableShipdayThu': json['enable_shipday_thu'],
        'enableShipdayFri': json['enable_shipday_fri'],
        'enableShipdaySat': json['enable_shipday_sat'],
        'enableShipdaySun': json['enable_shipday_sun'],
        'enableOrderdayMon': json['enable_orderday_mon'],
        'enableOrderdayTue': json['enable_orderday_tue'],
        'enableOrderdayWed': json['enable_orderday_wed'],
        'enableOrderdayThu': json['enable_orderday_thu'],
        'enableOrderdayFri': json['enable_orderday_fri'],
        'enableOrderdaySat': json['enable_orderday_sat'],
        'enableOrderdaySun': json['enable_orderday_sun'],
    };
}

export function MstSupplierToJSON(value?: MstSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'company_id': value.companyId,
        'org_supplier_id': value.orgSupplierId,
        'supplier_company_id': value.supplierCompanyId,
        'supplier_name': value.supplierName,
        'supplier_hojin_no': value.supplierHojinNo,
        'supplier_address': value.supplierAddress,
        'supplier_gln': value.supplierGln,
        'min_order_quantity_once': value.minOrderQuantityOnce,
        'order_leadtime': value.orderLeadtime,
        'order_limit_time': value.orderLimitTime,
        'enable_shipday_mon': value.enableShipdayMon,
        'enable_shipday_tue': value.enableShipdayTue,
        'enable_shipday_wed': value.enableShipdayWed,
        'enable_shipday_thu': value.enableShipdayThu,
        'enable_shipday_fri': value.enableShipdayFri,
        'enable_shipday_sat': value.enableShipdaySat,
        'enable_shipday_sun': value.enableShipdaySun,
        'enable_orderday_mon': value.enableOrderdayMon,
        'enable_orderday_tue': value.enableOrderdayTue,
        'enable_orderday_wed': value.enableOrderdayWed,
        'enable_orderday_thu': value.enableOrderdayThu,
        'enable_orderday_fri': value.enableOrderdayFri,
        'enable_orderday_sat': value.enableOrderdaySat,
        'enable_orderday_sun': value.enableOrderdaySun,
    };
}

