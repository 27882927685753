import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider';
import { AgGridReact } from 'ag-grid-react';
import { LoadingOverlay } from 'components/commonparts';
import { useGridCommon } from 'hooks/useGridCommon';
import { useStock } from 'hooks/useStock';
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes';
import { convertDateToRequestParam, isEqualDate } from 'utils/convertFromLocalDate';

import type { ColDef } from 'ag-grid-community';
import type { Stock, Stocks, StockUpdateBase } from 'api-client';

import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

interface ShipExclusionProps extends DataListPropsBase {
  list: Stocks
  onPlotSelectDate?: Stock
}

type StockWithId = StockUpdateBase & RecordEditEx

const columnMapping: Record<string, string> = {
  "rec_date": "recDate",
  "ship_exclusion_flg": "shipExclusionFlg"
}

const primayKey = ['recDate']

function ShipExclusion({
  list,
  isLoading, 
  onPlotSelectDate,
  searchParam,
  updateTrigger, 
  setHasGridEdited,
  setResultMessage,
  onReload,
}: ShipExclusionProps) {
  const { loginUserInfo } = useAuthContext()
  const containerStyle = useMemo(() => ({ width: '55%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ width: '55%', height: '100%' }), []);  
  const gridRef = useRef<AgGridReact<Stock>>(null)
  const [rowData, setRowData] = useState<StockUpdateBase[]>()
  const { isUpdating, fetchUpdateStock, result } = useStock()

  useEffect(() => {
    setResultMessage(result)
  }, [result])
  
  const createBlankRow = useCallback((): StockWithId => ({
        companyId: loginUserInfo?.companyId || 0,
        itemCode: searchParam.itemCode,
        centerId: searchParam.centerId,
        recDate: null,
        arrivalQuantityPlan: null,
        olditemShipStopFlg: null,
        orderQuantityReason: null,
        shipExclusionFlg: true,
        shipQuantityPlan: null,
        remarks: null,
        orderDate: null,
        ...initRecordEditEx(true)
      }
    ), [])
    const fieldValidate = (pRow: StockWithId, field: string | undefined, newValue: any): boolean => {
      let hasErrors = false
  
      switch (field) {
        case 'recDate':
          if (!hasErrors) {
            hasErrors = duplicateCheck(pRow, newValue)
          }
          break
        default:
          return false
      }
      return hasErrors
    }

  const updateStock = useCallback(
    async (updatedRows: StockWithId[]) => {
      const gridApi = gridRef.current?.api;
      if (!gridApi) return;
      // if ship_exclusion_flg === null set to false to prevent error update 500
      const datalist = updatedRows.map(({ isEdited, editedFields, recDate, shipExclusionFlg , errors, ...rest }) => ({
        ...rest,
        shipExclusionFlg: shipExclusionFlg === null ? false : shipExclusionFlg,
        recDate: recDate ? convertDateToRequestParam(new Date(recDate)) : null
      }));
      try {
        await fetchUpdateStock({
          stockUpdate: { body: datalist }
        })
        if (onReload) onReload()
      } catch (err: any) {
        setResultMessage(err.error);
      } 
    }, [fetchUpdateStock]);


  const {
    checkAndSetError,
    duplicateCheck,
    dateFormatter,
    formatBooleanValue,
    parseBooleanValue,
    parseDateValue,
    localeText,
    onGridReady,
    getCellClassRules,
    getRowData
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateStock,
    createBlankRow,
    fieldValidate,
  })  

  /**
  * カラム定義
  * @returns
  */
  const updateColumnDefs = (): (ColDef)[] => 
    [
      {
        headerName: '実績日',
        field: 'recDate',
        editable: (params) => params.data && params.data.arrivalQuantityPlan === null,
        cellClassRules: {
          ...getCellClassRules(),
          'editable-cell': (params) => params.data && params.data.arrivalQuantityPlan === null, 
        },
        flex: 7,
        cellEditor: 'agDateCellEditor',
        cellEditorParams: {
          minYear: 1900, 
          maxYear: 2050,
        },
        valueFormatter: dateFormatter,
        valueParser: parseDateValue,
      },           
      {
        headerName: '計算除外フラグ',
        field: 'shipExclusionFlg', 
        flex: 3,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        valueFormatter: formatBooleanValue,
        valueParser: parseBooleanValue,
      },
    ];


  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [rowData])

  useEffect(() => {
    const filtered = list.list.filter((item) => item.shipExclusionFlg === true)

    const existRow = filtered.map((item) => {
      const blank = createBlankRow()
      blank.recDate = item.recDate
      blank.isNewRecord = false
      return blank
    }) 

    if (existRow.length === 0) {
      const blankRow = createBlankRow()
      setRowData([blankRow])
    } else {
      setRowData(existRow)
    }
  }, [list.list, createBlankRow])

  useEffect(() => {
    if (!onPlotSelectDate || onPlotSelectDate.recDate === null) return;
  
    let updatedOnPlotSelectDate: Stock | null = onPlotSelectDate;
    const gridRows = getRowData();
  
    const sameDateRow = gridRows.find((row) =>
      isEqualDate(row.recDate, updatedOnPlotSelectDate?.recDate)
    );
    const blankRow = gridRows.find((row) => row.recDate === null);
  
    if (sameDateRow) {
      if (!sameDateRow.shipExclusionFlg) {
        sameDateRow.shipExclusionFlg = true;
        sameDateRow.isEdited = true;
        sameDateRow.editedFields.add("shipExclusionFlg");
        const updatedRows = gridRows.map((row) =>
          row.id === sameDateRow.id ? sameDateRow : row
        );
        setRowData(updatedRows);
      }
    } else if (blankRow) {
      blankRow.recDate = updatedOnPlotSelectDate.recDate;
      blankRow.shipExclusionFlg = true;
      blankRow.isEdited = true;
      blankRow.editedFields = new Set(["recDate", "shipExclusionFlg"]);
      const updatedRows = gridRows.map((row) =>
        row.id === blankRow.id ? blankRow : row
      );
      setRowData(updatedRows);
    } else {
      const newRow = {
        ...createBlankRow(),
        recDate: updatedOnPlotSelectDate.recDate,
        shipExclusionFlg: true,
        isEdited: true,
        editedFields: new Set(["recDate", "shipExclusionFlg"]),
        errors: {},
      };
      setRowData([...gridRows, newRow]);
    }
  
    updatedOnPlotSelectDate = null;
  }, [onPlotSelectDate]);

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '25vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            rowData={rowData}
            ref={gridRef}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            loadingOverlayComponent={LoadingOverlay}
          />
        </div>
      </div>
    </div>
  )
}

export default ShipExclusion
