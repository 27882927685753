import React from 'react'

import { Typography } from '@mui/material'
import { convertFromDateTime } from 'utils/convertFromLocalDate'

interface ShowLastUpdateDatetimeProps<T> {
  list: T[] | undefined | null
  lastUpdateDatetime: Date | null
}

function ShowLastUpdateDatetime<T>({
   list = null, 
   lastUpdateDatetime }: ShowLastUpdateDatetimeProps<T>) {
  return (
    <Typography variant="medium" justifyContent="flex-end" display="flex" color="royalblue" marginRight='10px'>
      { 
      // (list === null || list?.length > 0) && `最終更新日: ${convertFromDateTime(lastUpdateDatetime)}`
      lastUpdateDatetime !=null && `最終更新日: ${convertFromDateTime(lastUpdateDatetime)}`
      }
    </Typography>
  )
}

export default ShowLastUpdateDatetime
