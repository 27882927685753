/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeleteFlg } from './DeleteFlg';
import {
    DeleteFlgFromJSON,
    DeleteFlgFromJSONTyped,
    DeleteFlgToJSON,
} from './DeleteFlg';
import type { EnableArrivalFri } from './EnableArrivalFri';
import {
    EnableArrivalFriFromJSON,
    EnableArrivalFriFromJSONTyped,
    EnableArrivalFriToJSON,
} from './EnableArrivalFri';
import type { EnableArrivalMon } from './EnableArrivalMon';
import {
    EnableArrivalMonFromJSON,
    EnableArrivalMonFromJSONTyped,
    EnableArrivalMonToJSON,
} from './EnableArrivalMon';
import type { EnableArrivalSat } from './EnableArrivalSat';
import {
    EnableArrivalSatFromJSON,
    EnableArrivalSatFromJSONTyped,
    EnableArrivalSatToJSON,
} from './EnableArrivalSat';
import type { EnableArrivalSun } from './EnableArrivalSun';
import {
    EnableArrivalSunFromJSON,
    EnableArrivalSunFromJSONTyped,
    EnableArrivalSunToJSON,
} from './EnableArrivalSun';
import type { EnableArrivalThu } from './EnableArrivalThu';
import {
    EnableArrivalThuFromJSON,
    EnableArrivalThuFromJSONTyped,
    EnableArrivalThuToJSON,
} from './EnableArrivalThu';
import type { EnableArrivalTue } from './EnableArrivalTue';
import {
    EnableArrivalTueFromJSON,
    EnableArrivalTueFromJSONTyped,
    EnableArrivalTueToJSON,
} from './EnableArrivalTue';
import type { EnableArrivalWed } from './EnableArrivalWed';
import {
    EnableArrivalWedFromJSON,
    EnableArrivalWedFromJSONTyped,
    EnableArrivalWedToJSON,
} from './EnableArrivalWed';
import type { EnableOrderdayFri } from './EnableOrderdayFri';
import {
    EnableOrderdayFriFromJSON,
    EnableOrderdayFriFromJSONTyped,
    EnableOrderdayFriToJSON,
} from './EnableOrderdayFri';
import type { EnableOrderdayMon } from './EnableOrderdayMon';
import {
    EnableOrderdayMonFromJSON,
    EnableOrderdayMonFromJSONTyped,
    EnableOrderdayMonToJSON,
} from './EnableOrderdayMon';
import type { EnableOrderdaySat } from './EnableOrderdaySat';
import {
    EnableOrderdaySatFromJSON,
    EnableOrderdaySatFromJSONTyped,
    EnableOrderdaySatToJSON,
} from './EnableOrderdaySat';
import type { EnableOrderdaySun } from './EnableOrderdaySun';
import {
    EnableOrderdaySunFromJSON,
    EnableOrderdaySunFromJSONTyped,
    EnableOrderdaySunToJSON,
} from './EnableOrderdaySun';
import type { EnableOrderdayThu } from './EnableOrderdayThu';
import {
    EnableOrderdayThuFromJSON,
    EnableOrderdayThuFromJSONTyped,
    EnableOrderdayThuToJSON,
} from './EnableOrderdayThu';
import type { EnableOrderdayTue } from './EnableOrderdayTue';
import {
    EnableOrderdayTueFromJSON,
    EnableOrderdayTueFromJSONTyped,
    EnableOrderdayTueToJSON,
} from './EnableOrderdayTue';
import type { EnableOrderdayWed } from './EnableOrderdayWed';
import {
    EnableOrderdayWedFromJSON,
    EnableOrderdayWedFromJSONTyped,
    EnableOrderdayWedToJSON,
} from './EnableOrderdayWed';
import type { EnableShipdayFri } from './EnableShipdayFri';
import {
    EnableShipdayFriFromJSON,
    EnableShipdayFriFromJSONTyped,
    EnableShipdayFriToJSON,
} from './EnableShipdayFri';
import type { EnableShipdayMon } from './EnableShipdayMon';
import {
    EnableShipdayMonFromJSON,
    EnableShipdayMonFromJSONTyped,
    EnableShipdayMonToJSON,
} from './EnableShipdayMon';
import type { EnableShipdaySat } from './EnableShipdaySat';
import {
    EnableShipdaySatFromJSON,
    EnableShipdaySatFromJSONTyped,
    EnableShipdaySatToJSON,
} from './EnableShipdaySat';
import type { EnableShipdaySun } from './EnableShipdaySun';
import {
    EnableShipdaySunFromJSON,
    EnableShipdaySunFromJSONTyped,
    EnableShipdaySunToJSON,
} from './EnableShipdaySun';
import type { EnableShipdayThu } from './EnableShipdayThu';
import {
    EnableShipdayThuFromJSON,
    EnableShipdayThuFromJSONTyped,
    EnableShipdayThuToJSON,
} from './EnableShipdayThu';
import type { EnableShipdayTue } from './EnableShipdayTue';
import {
    EnableShipdayTueFromJSON,
    EnableShipdayTueFromJSONTyped,
    EnableShipdayTueToJSON,
} from './EnableShipdayTue';
import type { EnableShipdayWed } from './EnableShipdayWed';
import {
    EnableShipdayWedFromJSON,
    EnableShipdayWedFromJSONTyped,
    EnableShipdayWedToJSON,
} from './EnableShipdayWed';
import type { ShipPreviousStockFlg } from './ShipPreviousStockFlg';
import {
    ShipPreviousStockFlgFromJSON,
    ShipPreviousStockFlgFromJSONTyped,
    ShipPreviousStockFlgToJSON,
} from './ShipPreviousStockFlg';
import type { TodayShipRecDelayFlg } from './TodayShipRecDelayFlg';
import {
    TodayShipRecDelayFlgFromJSON,
    TodayShipRecDelayFlgFromJSONTyped,
    TodayShipRecDelayFlgToJSON,
} from './TodayShipRecDelayFlg';

/**
 * センターマスタ登録用
 * @export
 * @interface CenterUpdateIF
 */
export interface CenterUpdateIF {
    /**
     * 
     * @type {number}
     * @memberof CenterUpdateIF
     */
    centerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CenterUpdateIF
     */
    companyId?: number | null;
    /**
     * 自社センターID
     * @type {string}
     * @memberof CenterUpdateIF
     */
    orgCenterId: string;
    /**
     * センター名
     * @type {string}
     * @memberof CenterUpdateIF
     */
    centerName: string;
    /**
     * 
     * @type {string}
     * @memberof CenterUpdateIF
     */
    centerAddress?: string | null;
    /**
     * 
     * @type {EnableShipdayMon}
     * @memberof CenterUpdateIF
     */
    enableShipdayMon?: EnableShipdayMon | null;
    /**
     * 
     * @type {EnableShipdayTue}
     * @memberof CenterUpdateIF
     */
    enableShipdayTue?: EnableShipdayTue | null;
    /**
     * 
     * @type {EnableShipdayWed}
     * @memberof CenterUpdateIF
     */
    enableShipdayWed?: EnableShipdayWed | null;
    /**
     * 
     * @type {EnableShipdayThu}
     * @memberof CenterUpdateIF
     */
    enableShipdayThu?: EnableShipdayThu | null;
    /**
     * 
     * @type {EnableShipdayFri}
     * @memberof CenterUpdateIF
     */
    enableShipdayFri?: EnableShipdayFri | null;
    /**
     * 
     * @type {EnableShipdaySat}
     * @memberof CenterUpdateIF
     */
    enableShipdaySat?: EnableShipdaySat | null;
    /**
     * 
     * @type {EnableShipdaySun}
     * @memberof CenterUpdateIF
     */
    enableShipdaySun?: EnableShipdaySun | null;
    /**
     * 
     * @type {EnableArrivalMon}
     * @memberof CenterUpdateIF
     */
    enableArrivalMon?: EnableArrivalMon | null;
    /**
     * 
     * @type {EnableArrivalTue}
     * @memberof CenterUpdateIF
     */
    enableArrivalTue?: EnableArrivalTue | null;
    /**
     * 
     * @type {EnableArrivalWed}
     * @memberof CenterUpdateIF
     */
    enableArrivalWed?: EnableArrivalWed | null;
    /**
     * 
     * @type {EnableArrivalThu}
     * @memberof CenterUpdateIF
     */
    enableArrivalThu?: EnableArrivalThu | null;
    /**
     * 
     * @type {EnableArrivalFri}
     * @memberof CenterUpdateIF
     */
    enableArrivalFri?: EnableArrivalFri | null;
    /**
     * 
     * @type {EnableArrivalSat}
     * @memberof CenterUpdateIF
     */
    enableArrivalSat?: EnableArrivalSat | null;
    /**
     * 
     * @type {EnableArrivalSun}
     * @memberof CenterUpdateIF
     */
    enableArrivalSun?: EnableArrivalSun | null;
    /**
     * 
     * @type {string}
     * @memberof CenterUpdateIF
     */
    centerGroupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CenterUpdateIF
     */
    centerGln?: string | null;
    /**
     * 
     * @type {DeleteFlg}
     * @memberof CenterUpdateIF
     */
    deleteFlg?: DeleteFlg | null;
    /**
     * 
     * @type {EnableOrderdayMon}
     * @memberof CenterUpdateIF
     */
    enableOrderdayMon?: EnableOrderdayMon | null;
    /**
     * 
     * @type {EnableOrderdayTue}
     * @memberof CenterUpdateIF
     */
    enableOrderdayTue?: EnableOrderdayTue | null;
    /**
     * 
     * @type {EnableOrderdayWed}
     * @memberof CenterUpdateIF
     */
    enableOrderdayWed?: EnableOrderdayWed | null;
    /**
     * 
     * @type {EnableOrderdayThu}
     * @memberof CenterUpdateIF
     */
    enableOrderdayThu?: EnableOrderdayThu | null;
    /**
     * 
     * @type {EnableOrderdayFri}
     * @memberof CenterUpdateIF
     */
    enableOrderdayFri?: EnableOrderdayFri | null;
    /**
     * 
     * @type {EnableOrderdaySat}
     * @memberof CenterUpdateIF
     */
    enableOrderdaySat?: EnableOrderdaySat | null;
    /**
     * 
     * @type {EnableOrderdaySun}
     * @memberof CenterUpdateIF
     */
    enableOrderdaySun?: EnableOrderdaySun | null;
    /**
     * 
     * @type {ShipPreviousStockFlg}
     * @memberof CenterUpdateIF
     */
    shipPreviousStockFlg?: ShipPreviousStockFlg | null;
    /**
     * 
     * @type {TodayShipRecDelayFlg}
     * @memberof CenterUpdateIF
     */
    todayShipRecDelayFlg?: TodayShipRecDelayFlg | null;
    /**
     * 
     * @type {Date}
     * @memberof CenterUpdateIF
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CenterUpdateIF
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CenterUpdateIF
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CenterUpdateIF
     */
    updateUser?: string | null;
}

/**
 * Check if a given object implements the CenterUpdateIF interface.
 */
export function instanceOfCenterUpdateIF(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "centerName" in value;

    return isInstance;
}

export function CenterUpdateIFFromJSON(json: any): CenterUpdateIF {
    return CenterUpdateIFFromJSONTyped(json, false);
}

export function CenterUpdateIFFromJSONTyped(json: any, ignoreDiscriminator: boolean): CenterUpdateIF {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'centerId': !exists(json, 'center_id') ? undefined : json['center_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'orgCenterId': json['org_center_id'],
        'centerName': json['center_name'],
        'centerAddress': !exists(json, 'center_address') ? undefined : json['center_address'],
        'enableShipdayMon': !exists(json, 'enable_shipday_mon') ? undefined : EnableShipdayMonFromJSON(json['enable_shipday_mon']),
        'enableShipdayTue': !exists(json, 'enable_shipday_tue') ? undefined : EnableShipdayTueFromJSON(json['enable_shipday_tue']),
        'enableShipdayWed': !exists(json, 'enable_shipday_wed') ? undefined : EnableShipdayWedFromJSON(json['enable_shipday_wed']),
        'enableShipdayThu': !exists(json, 'enable_shipday_thu') ? undefined : EnableShipdayThuFromJSON(json['enable_shipday_thu']),
        'enableShipdayFri': !exists(json, 'enable_shipday_fri') ? undefined : EnableShipdayFriFromJSON(json['enable_shipday_fri']),
        'enableShipdaySat': !exists(json, 'enable_shipday_sat') ? undefined : EnableShipdaySatFromJSON(json['enable_shipday_sat']),
        'enableShipdaySun': !exists(json, 'enable_shipday_sun') ? undefined : EnableShipdaySunFromJSON(json['enable_shipday_sun']),
        'enableArrivalMon': !exists(json, 'enable_arrival_mon') ? undefined : EnableArrivalMonFromJSON(json['enable_arrival_mon']),
        'enableArrivalTue': !exists(json, 'enable_arrival_tue') ? undefined : EnableArrivalTueFromJSON(json['enable_arrival_tue']),
        'enableArrivalWed': !exists(json, 'enable_arrival_wed') ? undefined : EnableArrivalWedFromJSON(json['enable_arrival_wed']),
        'enableArrivalThu': !exists(json, 'enable_arrival_thu') ? undefined : EnableArrivalThuFromJSON(json['enable_arrival_thu']),
        'enableArrivalFri': !exists(json, 'enable_arrival_fri') ? undefined : EnableArrivalFriFromJSON(json['enable_arrival_fri']),
        'enableArrivalSat': !exists(json, 'enable_arrival_sat') ? undefined : EnableArrivalSatFromJSON(json['enable_arrival_sat']),
        'enableArrivalSun': !exists(json, 'enable_arrival_sun') ? undefined : EnableArrivalSunFromJSON(json['enable_arrival_sun']),
        'centerGroupName': !exists(json, 'center_group_name') ? undefined : json['center_group_name'],
        'centerGln': !exists(json, 'center_gln') ? undefined : json['center_gln'],
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : DeleteFlgFromJSON(json['delete_flg']),
        'enableOrderdayMon': !exists(json, 'enable_orderday_mon') ? undefined : EnableOrderdayMonFromJSON(json['enable_orderday_mon']),
        'enableOrderdayTue': !exists(json, 'enable_orderday_tue') ? undefined : EnableOrderdayTueFromJSON(json['enable_orderday_tue']),
        'enableOrderdayWed': !exists(json, 'enable_orderday_wed') ? undefined : EnableOrderdayWedFromJSON(json['enable_orderday_wed']),
        'enableOrderdayThu': !exists(json, 'enable_orderday_thu') ? undefined : EnableOrderdayThuFromJSON(json['enable_orderday_thu']),
        'enableOrderdayFri': !exists(json, 'enable_orderday_fri') ? undefined : EnableOrderdayFriFromJSON(json['enable_orderday_fri']),
        'enableOrderdaySat': !exists(json, 'enable_orderday_sat') ? undefined : EnableOrderdaySatFromJSON(json['enable_orderday_sat']),
        'enableOrderdaySun': !exists(json, 'enable_orderday_sun') ? undefined : EnableOrderdaySunFromJSON(json['enable_orderday_sun']),
        'shipPreviousStockFlg': !exists(json, 'ship_previous_stock_flg') ? undefined : ShipPreviousStockFlgFromJSON(json['ship_previous_stock_flg']),
        'todayShipRecDelayFlg': !exists(json, 'today_ship_rec_delay_flg') ? undefined : TodayShipRecDelayFlgFromJSON(json['today_ship_rec_delay_flg']),
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
    };
}

export function CenterUpdateIFToJSON(value?: CenterUpdateIF | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'center_id': value.centerId,
        'company_id': value.companyId,
        'org_center_id': value.orgCenterId,
        'center_name': value.centerName,
        'center_address': value.centerAddress,
        'enable_shipday_mon': EnableShipdayMonToJSON(value.enableShipdayMon),
        'enable_shipday_tue': EnableShipdayTueToJSON(value.enableShipdayTue),
        'enable_shipday_wed': EnableShipdayWedToJSON(value.enableShipdayWed),
        'enable_shipday_thu': EnableShipdayThuToJSON(value.enableShipdayThu),
        'enable_shipday_fri': EnableShipdayFriToJSON(value.enableShipdayFri),
        'enable_shipday_sat': EnableShipdaySatToJSON(value.enableShipdaySat),
        'enable_shipday_sun': EnableShipdaySunToJSON(value.enableShipdaySun),
        'enable_arrival_mon': EnableArrivalMonToJSON(value.enableArrivalMon),
        'enable_arrival_tue': EnableArrivalTueToJSON(value.enableArrivalTue),
        'enable_arrival_wed': EnableArrivalWedToJSON(value.enableArrivalWed),
        'enable_arrival_thu': EnableArrivalThuToJSON(value.enableArrivalThu),
        'enable_arrival_fri': EnableArrivalFriToJSON(value.enableArrivalFri),
        'enable_arrival_sat': EnableArrivalSatToJSON(value.enableArrivalSat),
        'enable_arrival_sun': EnableArrivalSunToJSON(value.enableArrivalSun),
        'center_group_name': value.centerGroupName,
        'center_gln': value.centerGln,
        'delete_flg': DeleteFlgToJSON(value.deleteFlg),
        'enable_orderday_mon': EnableOrderdayMonToJSON(value.enableOrderdayMon),
        'enable_orderday_tue': EnableOrderdayTueToJSON(value.enableOrderdayTue),
        'enable_orderday_wed': EnableOrderdayWedToJSON(value.enableOrderdayWed),
        'enable_orderday_thu': EnableOrderdayThuToJSON(value.enableOrderdayThu),
        'enable_orderday_fri': EnableOrderdayFriToJSON(value.enableOrderdayFri),
        'enable_orderday_sat': EnableOrderdaySatToJSON(value.enableOrderdaySat),
        'enable_orderday_sun': EnableOrderdaySunToJSON(value.enableOrderdaySun),
        'ship_previous_stock_flg': ShipPreviousStockFlgToJSON(value.shipPreviousStockFlg),
        'today_ship_rec_delay_flg': TodayShipRecDelayFlgToJSON(value.todayShipRecDelayFlg),
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
    };
}

