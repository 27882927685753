import React from 'react';

function GridTooltip(props: any) {
    const { value, data, colDef } = props;
    const { field, headerName } = colDef;

    if (data.errors && Array.isArray(data.errors[field]) && data.errors[field].length > 0) {
        const msg = String(data.errors[field][0])
        // メッセージの接頭語にヘッダーをつける仕様になっているが、つけたくない場合がある
        // 〇〇「は必須です」と、は、から始まるメッセージは、ヘッダーを出す（苦し紛れ！！）
        // todo：ヘッダーを出さなくてもいいのでは・・・？
        const prefix = msg.startsWith("は") ? headerName || field : ""
   
        return (
            <div className="custom-tooltip error-tooltip">
                <span className="icon-warning">⚠️</span>
                <span>{prefix}</span>
                {data.errors[field].join(', ')}
            </div>
        );
    }

    return (
        <div className="custom-tooltip">
            <span>{value || 'No value'}</span>
        </div>
    );
}
export default GridTooltip