import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import { LoadingOverlay } from 'components/commonparts/index'
import { useCenter } from 'hooks/useCenter'
import { useGridCommon } from 'hooks/useGridCommon'
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes'
import { validateRequired } from 'utils/validation'

import type { ColDef, ColGroupDef } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import type { Center, MstCenter, GetMstCentersCenterGetRequest } from 'api-client'
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
  org_center_id: 'orgCenterId',
  center_name: 'centerName',
  center_address: 'centerAddress',
  ship_previous_stock_flg: 'shipPreviousStockFlg',
  today_ship_rec_delay_flg: 'todayShipRecDelayFlg',
  enable_shipday_mon: 'enableShipdayMon',
  enable_shipday_tue: 'enableShipdayTue',
  enable_shipday_wed: 'enableShipdayWed',
  enable_shipday_thu: 'enableShipdayThu',
  enable_shipday_fri: 'enableShipdayFri',
  enable_shipday_sat: 'enableShipdaySat',
  enable_shipday_sun: 'enableShipdaySun',
  enable_arrival_mon: 'enableArrivalMon',
  enable_arrival_tue: 'enableArrivalTue',
  enable_arrival_wed: 'enableArrivalWed',
  enable_arrival_thu: 'enableArrivalThu',
  enable_arrival_fri: 'enableArrivalFri',
  enable_arrival_sat: 'enableArrivalSat',
  enable_arrival_sun: 'enableArrivalSun',
  enable_orderday_mon: 'enableOrderdayMon',
  enable_orderday_tue: 'enableOrderdayTue',
  enable_orderday_wed: 'enableOrderdayWed',
  enable_orderday_thu: 'enableOrderdayThu',
  enable_orderday_fri: 'enableOrderdayFri',
  enable_orderday_sat: 'enableOrderdaySat',
  enable_orderday_sun: 'enableOrderdaySun',
  center_group_name: 'centerGroupName',
  center_gln: 'centerGln',
  delete_flg: 'deleteFlg',
}
const primayKey = ['orgCenterId']

interface DataListProps extends DataListPropsBase {
  list: Center
  searchParam: GetMstCentersCenterGetRequest
}

interface MstCenterWithId extends MstCenter, RecordEditEx {}

function DataList({
  list,
  isLoading,
  searchParam,
  onReload,
  updateTrigger,
  onUploadData,
  setHasGridEdited,
  setResultMessage,
  setDownloadColumnDefs,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const { fetchCenterUpdate, isUpdating, result } = useCenter()
  const gridRef = useRef<AgGridReact<MstCenterWithId>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 250px)' }), [])
  const [row, setRow] = useState<MstCenterWithId[]>(list.list.map((item) => ({ ...item, ...initRecordEditEx() })))

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  const createBlankRow = useCallback((): MstCenterWithId => {
    const blankRow: Partial<MstCenterWithId> = {
      companyId: loginUserInfo?.companyId || null,
      orgCenterId: null,
      centerId: null,
      centerName: null,
      centerAddress: null,
      shipPreviousStockFlg: false,
      todayShipRecDelayFlg: false,
      enableShipdayMon: true,
      enableShipdayTue: true,
      enableShipdayWed: true,
      enableShipdayThu: true,
      enableShipdayFri: true,
      enableShipdaySat: true,
      enableShipdaySun: false,
      enableArrivalMon: true,
      enableArrivalTue: true,
      enableArrivalWed: true,
      enableArrivalThu: true,
      enableArrivalFri: true,
      enableArrivalSat: true,
      enableArrivalSun: false,
      enableOrderdayMon: true,
      enableOrderdayTue: true,
      enableOrderdayWed: true,
      enableOrderdayThu: true,
      enableOrderdayFri: true,
      enableOrderdaySat: false,
      enableOrderdaySun: false,
      centerGroupName: null,
      centerGln: null,
      deleteFlg: false,
      insertDatetime: null,
      updateDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      deleteDatetime: null,
      deleteUser: null,
      updateUser: null,
      ...initRecordEditEx(true),
    }
    return blankRow as MstCenterWithId
  }, [])

  // 入力チェック
  const fieldValidate = (pRow: MstCenterWithId, field: string | undefined, newValue: any): boolean => {
    let hasErrors = false
    switch (field) {
      case 'orgCenterId': {
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue))
        if (!hasErrors) {
          hasErrors = duplicateCheck(pRow, newValue)
        }
        break
      }
      case 'centerName': {
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue))
        break
      }
      default:
        return false
    }
    // console.log('fieldValidate', field, pRow, hasErrors)
    return hasErrors
  }

  const updateMstCenter = useCallback(
    async (updatedRows: MstCenterWithId[]) => {
      const datalist = updatedRows.map(({ isEdited, orgCenterId, centerName, editedFields, errors, ...rest }) => ({
        ...rest,
        orgCenterId: orgCenterId ?? '',
        centerName: centerName ?? '',
      }))

      try {
        await fetchCenterUpdate({
          centerUpdate: { body: datalist },
        })
        if (onReload) onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === 'error') {
          const gridApi = gridRef.current?.api

          err.errors.forEach((errrow: any) => {
            const { rowdata } = errrow
            const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id
            const errRow = updatedRows.find((rowdatas) => rowdatas.orgCenterId === orgCenterId)
            if (errRow) {
              // UpdateAPIのErrorは、PKをエラー表記して、サーバーから取得したエラーを表示する
              errRow.errors = { orgSupplierId: [errrow.errorMessage] }
              const rowNode = gridApi?.getRowNode(errRow.id || '')
              if (rowNode) {
                rowNode.setData(errRow)
              }
            }
          })
        }
      }
    },
    [fetchCenterUpdate, searchParam]
  )

  const handleImportExcel = (data: any[]) => {
    let hasError = false
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCenterWithId> = {}
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping]
          const importvalue = excelRow[key]
          let setvalue = importvalue || null

          if (
            [
              'deleteFlg',
              'shipPreviousStockFlg',
              'todayShipRecDelayFlg',
              'enableShipdayMon',
              'enableShipdayTue',
              'enableShipdayWed',
              'enableShipdayThu',
              'enableShipdayFri',
              'enableShipdaySat',
              'enableShipdaySun',
              'enableArrivalMon',
              'enableArrivalTue',
              'enableArrivalWed',
              'enableArrivalThu',
              'enableArrivalFri',
              'enableArrivalSat',
              'enableArrivalSun',
              'enableOrderdayMon',
              'enableOrderdayTue',
              'enableOrderdayWed',
              'enableOrderdayThu',
              'enableOrderdayFri',
              'enableOrderdaySat',
              'enableOrderdaySun',
            ].includes(tableField)
          ) {
            setvalue = parseBoolean(importvalue)
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!isNonInputValue(importvalue) && setvalue === null) hasError = true
          mappedRow[tableField as keyof MstCenterWithId] = setvalue as unknown as undefined
        }
      })
      return mappedRow as MstCenterWithId
    })
    return { mappedData, hasError }
  }

  const {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    parseStringValue,
    parseTime,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    onReload,
    onUploadData,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateMstCenter,
    handleImportExcel,
    createBlankRow,
    fieldValidate,
  })

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
      headerName: '基本情報',
      children: [
        {
          headerName: '自社センターID',
          field: 'orgCenterId',
          editable: (params) => params.data && params.data.insertDatetime === null,
          cellClassRules: {
            ...getCellClassRules(),
            'editable-cell': (params) => params.data && params.data.insertDatetime === null,
          },
          rowDrag: true,
        },
        {
          headerName: 'プロダクト統一センターID',
          field: 'centerId',
        },
        {
          headerName: 'センター名',
          field: 'centerName',
          width: 350,
          editable: true,
          cellClassRules: getCellClassRules(),
        },
        {
          headerName: 'センター所在地住所',
          field: 'centerAddress',
          editable: true,
          cellClassRules: getCellClassRules(),
        },
      ],
    },
    {
      headerName: '発注設定',
      children: [
        {
          headerName: '前日在庫引当フラグ',
          field: 'shipPreviousStockFlg',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '当日出荷数実績遅延フラグ',
          field: 'todayShipRecDelayFlg',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
      ],
    },
    {
      headerName: '出荷可能フラグ',
      children: [
        {
          headerName: '月',
          field: 'enableShipdayMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '火',
          field: 'enableShipdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '水',
          field: 'enableShipdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '木',
          field: 'enableShipdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '金',
          field: 'enableShipdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '土',
          field: 'enableShipdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '日',
          field: 'enableShipdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
      ],
    },
    {
      headerName: '納品（受入）可能フラグ',
      children: [
        {
          headerName: '月',
          field: 'enableArrivalMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '火',
          field: 'enableArrivalTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '水',
          field: 'enableArrivalWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '木',
          field: 'enableArrivalThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '金',
          field: 'enableArrivalFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '土',
          field: 'enableArrivalSat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '日',
          field: 'enableArrivalSun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
      ],
    },
    {
      headerName: '発注業務可能フラグ',
      children: [
        {
          headerName: '月',
          width: 70,
          field: 'enableOrderdayMon',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '火',
          field: 'enableOrderdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '水',
          field: 'enableOrderdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '木',
          field: 'enableOrderdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '金',
          field: 'enableOrderdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '土',
          field: 'enableOrderdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: '日',
          field: 'enableOrderdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
        {
          headerName: 'センターグループ名',
          field: 'centerGroupName',
          editable: true,
        },
        {
          headerName: 'GS1事業者コード',
          field: 'centerGln',
          editable: true,
        },
        {
          headerName: '削除フラグ',
          field: 'deleteFlg', // 削除フラグ
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
      ],
    },
  ]

  const columnDefs = useMemo<ColDef[]>(updateColumnDefs, [row])
  // ダウンロードファイル用のカラム定義
  useEffect(() => {
    if (setDownloadColumnDefs)
      setDownloadColumnDefs([
        {
          headerName: '自社センターID',
          children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }],
        },
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        {
          headerName: 'プロダクト統一センターID',
          children: [{ headerName: 'center_id', field: 'centerId', cellClass: 'stringType' }],
        },
        {
          headerName: 'センター名',
          children: [{ headerName: 'center_name', field: 'centerName', cellClass: 'stringType' }],
        },
        {
          headerName: 'センター所在地住所',
          children: [{ headerName: 'center_address', field: 'centerAddress', cellClass: 'stringType' }],
        },
        {
          headerName: '前日在庫引当フラグ',
          children: [
            { headerName: 'ship_previous_stock_flg', field: 'shipPreviousStockFlg', cellClass: 'booleanType' },
          ],
        },
        {
          headerName: '当日出荷数実績遅延フラグ',
          children: [
            { headerName: 'today_ship_rec_delay_flg', field: 'todayShipRecDelayFlg', cellClass: 'booleanType' },
          ],
        },
        {
          headerName: '出荷可能フラグ（月曜日）',
          children: [{ headerName: 'enable_shipday_mon', field: 'enableShipdayMon', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（火曜日）',
          children: [{ headerName: 'enable_shipday_tue', field: 'enableShipdayTue', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（水曜日）',
          children: [{ headerName: 'enable_shipday_wed', field: 'enableShipdayWed', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（木曜日）',
          children: [{ headerName: 'enable_shipday_thu', field: 'enableShipdayThu', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（金曜日）',
          children: [{ headerName: 'enable_shipday_fri', field: 'enableShipdayFri', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（土曜日）',
          children: [{ headerName: 'enable_shipday_sat', field: 'enableShipdaySat', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（日曜日）',
          children: [{ headerName: 'enable_shipday_sun', field: 'enableShipdaySun', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（月曜日）',
          children: [{ headerName: 'enable_arrival_mon', field: 'enableArrivalMon', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（火曜日）',
          children: [{ headerName: 'enable_arrival_tue', field: 'enableArrivalTue', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（水曜日）',
          children: [{ headerName: 'enable_arrival_wed', field: 'enableArrivalWed', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（木曜日）',
          children: [{ headerName: 'enable_arrival_thu', field: 'enableArrivalThu', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（金曜日）',
          children: [{ headerName: 'enable_arrival_fri', field: 'enableArrivalFri', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（土曜日）',
          children: [{ headerName: 'enable_arrival_sat', field: 'enableArrivalSat', cellClass: 'booleanType' }],
        },
        {
          headerName: '納品（受入）可能フラグ（日曜日）',
          children: [{ headerName: 'enable_arrival_sun', field: 'enableArrivalSun', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（月曜日）',
          children: [{ headerName: 'enable_orderday_mon', field: 'enableOrderdayMon', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（火曜日）',
          children: [{ headerName: 'enable_orderday_tue', field: 'enableOrderdayTue', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（水曜日）',
          children: [{ headerName: 'enable_orderday_wed', field: 'enableOrderdayWed', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（木曜日）',
          children: [{ headerName: 'enable_orderday_thu', field: 'enableOrderdayThu', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（金曜日）',
          children: [{ headerName: 'enable_orderday_fri', field: 'enableOrderdayFri', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（土曜日）',
          children: [{ headerName: 'enable_orderday_sat', field: 'enableOrderdaySat', cellClass: 'booleanType' }],
        },
        {
          headerName: '発注業務可能フラグ（日曜日）',
          children: [{ headerName: 'enable_orderday_sun', field: 'enableOrderdaySun', cellClass: 'booleanType' }],
        },
        {
          headerName: 'センターグループ名',
          children: [{ headerName: 'center_group_name', field: 'centerGroupName', cellClass: 'stringType' }],
        },
        {
          headerName: 'GS1事業者コード',
          children: [{ headerName: 'center_gln', field: 'centerGln', cellClass: 'stringType' }],
        },
        {
          headerName: '削除フラグ',
          children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }],
        },
      ])

  }, [setDownloadColumnDefs])

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow()
      setRow([blankRow])
    } else {
      setRow(list.list.map((item) => ({ ...item, ...initRecordEditEx() })))
    }
  }, [list.list])

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            key={`${row[0]?.companyId}`}
            rowData={row}
            ref={gridRef}
            getRowId={(params) => params.data.id}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            autoSizeStrategy={autoSizeStrategy}
            loadingOverlayComponent={LoadingOverlay}
          />
        </div>
      </div>
    </div>
  )
}

export default DataList
