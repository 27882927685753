import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider'
import { type CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { LoadingOverlay } from 'components/commonparts/index'
import { useGridCommon } from 'hooks/useGridCommon'
import { useItem } from 'hooks/useItems';
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes'
import { convertDateToRequestParam } from 'utils/convertFromLocalDate'
import { validate4to13Length, validate4to14Length, validate6to10Length, validateRequired } from 'utils/validation'

import type { ColDef, ValueFormatterParams,ValueParserParams,IRichCellEditorParams } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import type { Item, MstItem, MstSupplier, Groups, GetMstItemsItemGetRequest, Codes } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
    "item_code": "itemCode",
    "org_item_code": "orgItemCode",
    "item_name": "itemName",
    "sales_startdate": "salesStartdate",
    "sales_enddate": "salesEnddate",
    "longside": "longside",
    "shortside": "shortside",
    "height": "height",
    "weight": "weight",
    "spec": "spec",
    "max_pallet_quantity": "maxPalletQuantity",
    "pallet_quantity_face": "palletQuantityFace",
    "pallet_quantity_tier": "palletQuantityTier",
    "jan": "jan",
    "jan_case": "janCase",
    "gtin_13": "gtin13",
    "gtin_14": "gtin14",
    "itf": "itf",
    "sdp": "sdp",
    "replace_org_item_code": "replaceOrgItemCode",
    "maker_gln": "makerGln",
    "maker_name": "makerName",
    "org_supplier_id": "orgSupplierId",
    "order_unit_type": "orderUnitType",
    "case_lot": "caseLot",
    "min_order_quantity": "minOrderQuantity",
    "delete_flg": "deleteFlg"
};

const primayKey = ['orgItemCode']

interface DataListProps extends DataListPropsBase {
  list: Item
  searchParam: GetMstItemsItemGetRequest
  supplier: MstSupplier[];
  systype: Groups[];
}

interface MstItemWithId extends MstItem, RecordEditEx {}

function DataList({ 
  list, 
  isLoading, 
  supplier, 
  systype, 
  searchParam, 
  onReload, 
  updateTrigger, 
  onUploadData,
  setHasGridEdited,
  setResultMessage,
  setDownloadColumnDefs,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {result, fetchUpdateItem, isUpdating} = useItem()
  const gridRef = useRef<AgGridReact<MstItemWithId>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [editorDataReady, setEditorDataReady] = useState(false);
  const [row, setRow] = useState<MstItemWithId[]>(
    list.list.map((item) => ({ ...item, ...initRecordEditEx() }))
  )
  const [supplierSelect, setSupplierSelect] = useState<(string | null)[]>([])
  const [itemSelect, setItemSelect] = useState<(string | null)[]>([])
  const [orderUnitTypeSelect, setOrderUnitTypeSelect] = useState<(string | null)[]>([])
  const [orderUnitTypeList, setOrderUnitTypeList] = useState<Codes[]>([])
  
  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    if (itemSelect.length > 0 && orderUnitTypeSelect.length > 0 && supplierSelect.length > 0) {
      setEditorDataReady(true);
    }
  }, [itemSelect, orderUnitTypeSelect, supplierSelect]);

  useEffect(() => {
    const values = supplier?.map((r) => r.orgSupplierId) || []
    setSupplierSelect(values)
  }, [supplier]);
  useEffect(() => {
    const codelist = systype.find(r => r.typeGroup === 'order_unit_type')?.codes
    if (codelist){
      setOrderUnitTypeList(codelist)
      const values = codelist.map((r) => r.typeCode) || []
      setOrderUnitTypeSelect(values)    
    }
  }, [systype]);  
  useEffect(() => {
    // console.debug("useEffect row", row)
    setEditorDataReady(false)
    const values = row?.map((r) => r.orgItemCode) || []
    setItemSelect([...values])
  }, [row]);

  const createBlankRow = useCallback((): MstItemWithId => ({
      companyId: loginUserInfo?.companyId || null,
      orgItemCode: null,
      itemName: null,
      salesStartdate: null,
      salesEnddate: null,
      longside: null,
      shortside: null,
      height: null,
      weight: null,
      itemCode: null,
      janCase: null,
      jan: null,
      gtin13: null,
      gtin14: null,
      itf: null,
      sdp: null,
      replaceOrgItemCode: null,
      makerGln: null,
      orgSupplierId: null,
      supplierCompanyId: null,
      supplierName: null,
      deleteFlg: false,
      caseLot: null,
      minOrderQuantity: null,
      palletQuantityFace: null,
      palletQuantityTier: null,
      maxPalletQuantity: null,
      spec: null,
      orderUnitType: null,
      orderUnitTypeName: null,
      replaceItemCode: null,
      oldItemCode: null,
      makerName: null,
      insertDatetime: null,
      updateDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      deleteDatetime: null,
      deleteUser: null,
      updateUser: null,
      ...initRecordEditEx(true)
    }
  ), [])

  // 入力チェック
  const fieldValidate = (pRow: MstItemWithId, field: string | undefined, newValue: any): boolean => {
    let hasErrors = false
    switch (field){
      case "itemName":
      case "orgSupplierId":
        checkAndSetError(pRow, field, validateRequired(newValue));
        break;

      case "orgItemCode":{
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue));
        // 重複チェック
        if (!hasErrors){
          hasErrors = duplicateCheck(pRow, newValue)
        }
        break;
      }

      case "salesStartdate":
      case "salesEnddate":{
        const startDate = field === 'salesStartdate' ? parseDate(newValue) : pRow.salesStartdate || null;
        const endDate = field === 'salesEnddate' ? parseDate(newValue) : pRow.salesEnddate || null;
        // console.log("startDate err", field, startDate, endDate)
        if (startDate && endDate && startDate > endDate) {
          hasErrors = checkAndSetError(pRow, field, "は発売日より後の日付を指定してください。");
        } else {
          checkAndSetError(pRow, "salesStartdate", "");
          checkAndSetError(pRow, "salesEnddate", "");
        }
        break;
      }

      case "janCase": {
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue)) || 
        checkAndSetError(pRow, field, validate4to14Length(newValue).join(','))
        break;
      }
      case "gtin13":
      case "gtin14":
      case "itf":
      case "sdp":
        hasErrors = checkAndSetError(pRow, field, validate4to14Length(newValue).join(','))
        break;
      case "makerGln":
        hasErrors = checkAndSetError(pRow, field, validate6to10Length(newValue).join(','))
        break;
      case "jan":
        hasErrors = checkAndSetError(pRow, field, validate4to13Length(newValue).join(','))
        break;
      default:
        return false;
    }
    // console.debug("***colDataValidate ", hasErrors, field, newValue)
    return hasErrors

  };

  const cellValueChanged = (event: CellValueChangedEvent<MstItemWithId>, hasError: boolean) => {
    const { data, colDef } = event
    const { field } = colDef

    const updatedRow = { ...data }
    // 値の変更時に、APIのエラーを解消
    switch (field){
      case "replaceOrgItemCode":
      case "orgSupplierId":
      case "orgItemCode":
        fieldValidate(updatedRow, "orgItemCode", updatedRow.orgItemCode);
        break;
      default:
    }
  };

  const updateMstItem = useCallback(
    async (updatedRows: MstItemWithId[]) => {
      const datalist = updatedRows.map(({ isEdited, editedFields, salesStartdate, salesEnddate, errors, ...rest }) => ({
        ...rest,
        salesStartdate: salesStartdate ? convertDateToRequestParam(new Date(salesStartdate)) : null,
        salesEnddate: salesEnddate ? convertDateToRequestParam(new Date(salesEnddate)) : null,
      }));
      
      try {
        await fetchUpdateItem({
          masterItemUpdate: { body: datalist }
        })
        if (onReload) onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === "error") {
          const gridApi = gridRef.current?.api
          err.errors.forEach((errrow: any) => {

            const {rowdata}=errrow
            const orgItemCode = (rowdata as { org_item_code: string | null }).org_item_code;
            const errRow = updatedRows.find((rowdatas) => rowdatas.orgItemCode === orgItemCode); 
            if (errRow) {
              // UpdateAPIのErrorは、社内商品コードをエラー表記して、サーバーから取得したエラーを表示する
              errRow.errors = { orgItemCode: [errrow.errorMessage] }
              const rowNode = gridApi?.getRowNode(errRow.id || '')
              if (rowNode) {
                rowNode.setData(errRow)
              }
            }
          })
        }
      }
    }, [fetchUpdateItem, searchParam]);

  const handleImportExcel = (data: any[]) => {
    // console.log("handleImportExcel",  data)
    const dateFields: (keyof MstItemWithId)[] = ['salesStartdate', 'salesEnddate'];
    const flgFields: (keyof MstItemWithId)[] = ['deleteFlg'];
    const intFields: (keyof MstItemWithId)[] = ['caseLot','minOrderQuantity','maxPalletQuantity','palletQuantityFace','palletQuantityTier'];
    const numericFields: (keyof MstItemWithId)[] = ['longside','shortside','maxPalletQuantity','weight','height'];
    const typeCodeFields: (keyof MstItemWithId)[] = ['orderUnitType'];
    
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstItemWithId> = {};
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (flgFields.includes(tableField as keyof MstItemWithId)) {
            setvalue = parseBoolean(importvalue)
          } else if (typeCodeFields.includes(tableField as keyof MstItemWithId)) {
            const codevalue = typeCodeParser(importvalue, orderUnitTypeList,)
            setvalue = typeCodeParser(codevalue, orderUnitTypeList,)
            if (!codevalue && importvalue) hasError = true;
          } else if (dateFields.includes(tableField as keyof MstItemWithId)) {
            // 日付項目は型変換が必要
            setvalue = parseDate(importvalue) as unknown as undefined;;
          } else if (intFields.includes(tableField as keyof MstItemWithId)) {
            setvalue = parsePositiveInteger(importvalue);
          } else if (numericFields.includes(tableField as keyof MstItemWithId)) {
            setvalue = parseNumeric(importvalue);
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstItemWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstItemWithId;
    });
    return { mappedData, hasError }
  };


  const {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    parseStringValue,
    parseTime,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    onReload,
    onUploadData,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateMstItem,
    handleImportExcel,
    createBlankRow,
    fieldValidate,
    cellValueChanged,
  })  

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef)[] => 
  [
    {
      headerName: '社内商品コード',
      field: 'orgItemCode', // 社内商品コード
      editable: (params) => {
        if (params.colDef.field) {
          const { insertDatetime } = params.data || {};
          return insertDatetime === null;
        }
        return false;
      },
      rowDrag: true,
      valueParser: parseStringValue,
    },
    {
      headerName: '商品名称',
      field: 'itemName', // 商品名称
      editable: true,
      width: 350,
      valueParser: parseStringValue,
    },
    {
      headerName: '発売日',
      field: 'salesStartdate', // 発売日
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 250,
      cellEditor: 'agDateCellEditor',
      cellEditorParams: {
        minYear: 1900, 
        maxYear: 2050,
      },
      valueFormatter: dateFormatter,
      valueParser: parseDateValue,
    },
    {
      headerName: '終了日',
      field: 'salesEnddate', // 終了日
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 250,
      cellEditor: 'agDateCellEditor',
      cellEditorParams: {
        minYear: 1900, 
        maxYear: 2050,
      },
      valueFormatter: dateFormatter,
      valueParser: parseDateValue,
    },
    {
      headerName: 'ケースサイズ 長手（mm）',
      field: 'longside',
      editable: true,
      cellClass: 'number-cell',
      valueFormatter: (params) => formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => parseDecimalValue(params, 2)
    },
    {
      headerName: 'ケースサイズ 短手（mm）',
      field: 'shortside', // ケースサイズ 短手（mm）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: (params) => formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => parseDecimalValue(params, 2)
    },
    {
      headerName: 'ケースサイズ 高さ（mm）',
      field: 'height', // ケースサイズ 高さ（mm）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: (params) => formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => parseDecimalValue(params, 2)
    },
    {
      headerName: 'ケースサイズ 重量（g）',
      field: 'weight', // ケースサイズ 重量（g）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: (params) => formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => parseDecimalValue(params, 2)
    },
    {
      headerName: '規格',
      field: 'spec', // 規格
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'パレット最大積付数',
      field: 'maxPalletQuantity', // パレット最大積付数
      editable: true,
      cellClass:'number-cell',
      valueFormatter: numberValueFormatter,
      cellEditor:'agNumberCellEditor',
      valueParser: numberValueParser,
    },
    {
      headerName: 'パレット積載数（面）',
      field: 'palletQuantityFace', // パレット積載数（面）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: numberValueFormatter,
      cellEditor:'agNumberCellEditor',
      valueParser: numberValueParser,
    },
    {
      headerName: 'パレット積載数（段）',
      field: 'palletQuantityTier', // パレット積載数（段）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: numberValueFormatter,
      cellEditor:'agNumberCellEditor',
      valueParser: numberValueParser,
    },
    {
      headerName: 'JANコード（バラ）',
      field: 'jan', // JANコード（バラ）
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'JANコード（ケース）',
      field: 'janCase', // JANコード（ケース）
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'GTINコード（バラ）',
      field: 'gtin13', // GTINコード（バラ）
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'GTINコード（ケース）',
      field: 'gtin14', // GTINコード（ケース）
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'ITFコード',
      field: 'itf', // ITFコード
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'SDPコード',
      field: 'sdp', // SDPコード
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: '後継社内商品コード',
      field: 'replaceOrgItemCode', // 後継社内商品コード
      editable: true,
      width: 350,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => selectValueFormatter(params.value, row, "orgItemCode", "itemName"),
      valueParser: (params: ValueParserParams) => selectValueParser(params, row, "orgItemCode"),
      cellEditorParams: editorDataReady ? {
        values : [null, ...itemSelect],
        formatValue: (value: string) => selectValueFormatter(value, row, "orgItemCode", "itemName"),
        searchType: "matchAny",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams : {values: ['-']},
    },
    {
      headerName: 'メーカーGS1事業者コード',
      field: 'makerGln', // メーカーGS1事業者コード
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: 'メーカー名',
      field: 'makerName', // メーカー名
      editable: true,
      valueParser: parseStringValue,
    },
    {
      headerName: '仕入先ID',
      field: 'orgSupplierId', // 仕入先ID
      editable: true,
      width: 220,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => selectValueFormatter(params.value, supplier, "orgSupplierId", "supplierName"),
      valueParser: (params: ValueParserParams) => selectValueParser(params, supplier, "orgSupplierId"),
      cellEditorParams: editorDataReady ? {
        values : [null, ...supplierSelect],
        formatValue: (value: string) => selectValueFormatter(value, supplier, "orgSupplierId", "supplierName"),
        searchType: "match",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams : {values: ['-']},
    },
    {
      headerName: '発注単位',
      field: 'orderUnitType', // 発注単位
      suppressHeaderMenuButton: false,
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      // 区分値は、ドロップダウン後のリストはコード＋名称で、表記は名称のみ
      valueFormatter: (params: ValueFormatterParams) => typecodeFormatter(params.value, orderUnitTypeList),
      valueParser: (params: ValueParserParams) => selectTypecodeValueParser(params, orderUnitTypeList),
      cellEditorParams: editorDataReady ? {
        values : [null, ...orderUnitTypeSelect],
        formatValue: (value: string) => selectValueFormatter(value, orderUnitTypeList, "typeCode", "typeName")
      } as IRichCellEditorParams : {values: ['-']},
    },
    {
      headerName: 'ケースロット数',
      field: 'caseLot', // ケースロット数
      editable: true,
      valueFormatter: numberValueFormatter,
      cellClass:'number-cell',
      cellEditor:'agNumberCellEditor',
      valueParser: numberValueParser,
    },
    {
      headerName: '最低発注量',
      field: 'minOrderQuantity', // 最低発注量
      editable: true,
      valueFormatter: numberValueFormatter,
      cellClass:'number-cell',
      cellEditor:'agNumberCellEditor',
      valueParser: numberValueParser,
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue, 
    },
  ];

  const onFilterChanged = useCallback(() => {
    const gridApi = gridRef.current?.api;
  
    if (gridApi) {
      const allColumns = gridApi.getColumns();
      if (allColumns) {
        allColumns.forEach((column) => {
          gridApi.getColumnFilterInstance(column.getColId())
            .then((filterInstance) => {
              if (filterInstance && 'refreshFilterValues' in filterInstance) {
                (filterInstance as any).refreshFilterValues();
              }
            });
        });
      }
      gridApi.redrawRows();
    }
  }, []);

  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])

  // ダウンロードファイル用のカラム定義
  useEffect(() => {
    if (setDownloadColumnDefs)
      setDownloadColumnDefs([
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        { headerName: 'アイテムコード', children: [{ headerName: 'item_code', field: 'itemCode', cellClass: 'stringType' }] },
        { headerName: '社内商品コード', children: [{ headerName: 'org_item_code', field: 'orgItemCode', cellClass: 'stringType' }] },
        { headerName: '商品名称', children: [{ headerName: 'item_name', field: 'itemName', cellClass: 'stringType' }] },
        { headerName: '発売日', children: [{ headerName: 'sales_startdate', field: 'salesStartdate', valueFormatter: dateFormatter}] },
        { headerName: '終了日', children: [{ headerName: 'sales_enddate', field: 'salesEnddate', valueFormatter: dateFormatter }] },
        { headerName: 'ケースサイズ 長手（mm）', children: [{ headerName: 'longside', field: 'longside', cellClass: 'decimalType' }] },
        { headerName: 'ケースサイズ 短手（mm）', children: [{ headerName: 'shortside', field: 'shortside', cellClass: 'decimalType' }] },
        { headerName: 'ケースサイズ 高さ（mm）', children: [{ headerName: 'height', field: 'height', cellClass: 'decimalType' }] },
        { headerName: 'ケースサイズ 重量（g）', children: [{ headerName: 'weight', field: 'weight', cellClass: 'decimalType' }] },
        { headerName: '規格', children: [{ headerName: 'spec', field: 'spec', cellClass: 'stringType' }] },
        { headerName: 'パレット最大積付数', children: [{ headerName: 'max_pallet_quantity', field: 'maxPalletQuantity', cellClass: 'numberType' }] },
        { headerName: 'パレット積載数（面）', children: [{ headerName: 'pallet_quantity_face', field: 'palletQuantityFace', cellClass: 'numberType' }] },
        { headerName: 'パレット積載数（段）', children: [{ headerName: 'pallet_quantity_tier', field: 'palletQuantityTier', cellClass: 'numberType' }] },
        { headerName: 'JANコード（バラ）', children: [{ headerName: 'jan', field: 'jan', cellClass: 'stringType' }] },
        { headerName: 'JANコード（ケース）', children: [{ headerName: 'jan_case', field: 'janCase', cellClass: 'stringType' }] },
        { headerName: 'GTINコード（バラ）', children: [{ headerName: 'gtin_13', field: 'gtin13', cellClass: 'stringType' }] },
        { headerName: 'GTINコード（ケース）', children: [{ headerName: 'gtin_14', field: 'gtin14', cellClass: 'stringType' }] },
        { headerName: 'ITFコード', children: [{ headerName: 'itf', field: 'itf', cellClass: 'stringType' }] },
        { headerName: 'SDPコード', children: [{ headerName: 'sdp', field: 'sdp', cellClass: 'stringType' }] },
        { headerName: '後継社内商品コード', children: [{ headerName: 'replace_org_item_code', field: 'replaceOrgItemCode', cellClass: 'stringType' }] },
        { headerName: 'メーカーGS1事業者コード', children: [{ headerName: 'maker_gln', field: 'makerGln', cellClass: 'stringType' }] },
        { headerName: 'メーカー名', children: [{ headerName: 'maker_name', field: 'makerName', cellClass: 'stringType' }] },
        { headerName: '仕入先ID', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }] },
        { headerName: '仕入先名', children: [{ headerName: 'supplier_name', field: 'supplierName', cellClass: 'stringType' }] },
        { headerName: '発注単位', children: [{ headerName: 'order_unit_type', field: 'orderUnitType', cellClass: 'stringType' }] },
        { headerName: 'ケースロット数', children: [{ headerName: 'case_lot', field: 'caseLot', cellClass: 'numberType' }] },
        { headerName: '最低発注量', children: [{ headerName: 'min_order_quantity', field: 'minOrderQuantity', cellClass: 'numberType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
      ])
  }, [setDownloadColumnDefs])

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...initRecordEditEx()})))
    }
  }, [list.list]);

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            key={`${row[0]?.companyId}-${editorDataReady}`}
            rowData={row}
            ref={gridRef}
            getRowId={(params) => params.data.id}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            autoSizeStrategy={autoSizeStrategy}
            loadingOverlayComponent={LoadingOverlay}
          />
        </div>
      </div>
    </div>
  );
}

export default DataList;
