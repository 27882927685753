import React from 'react';

import { type ValueSetterParams, type ICellRendererParams } from 'ag-grid-community';

export default function TimeCellRenderer( params: ICellRendererParams){
    const {value} = params

    if(value) {
        return value
    }

    return ( 
    <span style = {{ color: '#ccc'}}>
        hh:mm
    </span>
    )
}



