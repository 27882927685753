import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider'
import { CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { LoadingOverlay, TimeCellRenderer } from 'components/commonparts/index'
import { useCenterSupplier } from 'hooks/useCenterSupplier'
import { useGridCommon } from 'hooks/useGridCommon'
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes'
import { validateRequired } from 'utils/validation'

import type { ColDef, ColGroupDef, IRichCellEditorParams, ValueFormatterParams, ValueParserParams } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import type { MstCenterSupplier, CenterSupplier, GetMstCenterSupplierCenterSupplierGetRequest, MstSupplier, Groups, MstCenter, Codes } from 'api-client'
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
  org_center_id: 'orgCenterId',
  org_supplier_id: 'orgSupplierId',
  optimize_type: 'optimizeType',
  stop_arrival: 'stopArrival',
  arrival_ratio_mon: 'arrivalRatioMon',
  arrival_ratio_tue: 'arrivalRatioTue',
  arrival_ratio_wed: 'arrivalRatioWed',
  arrival_ratio_thu: 'arrivalRatioThu',
  arrival_ratio_fri: 'arrivalRatioFri',
  arrival_ratio_sat: 'arrivalRatioSat',
  arrival_ratio_sun: 'arrivalRatioSun',
  min_order_quantity_once: 'minOrderQuantityOnce',
  order_leadtime: 'orderLeadtime',
  order_limit_time: 'orderLimitTime',
  enable_shipday_mon: 'enableShipdayMon',
  enable_shipday_tue: 'enableShipdayTue',
  enable_shipday_wed: 'enableShipdayWed',
  enable_shipday_thu: 'enableShipdayThu',
  enable_shipday_fri: 'enableShipdayFri',
  enable_shipday_sat: 'enableShipdaySat',
  enable_shipday_sun: 'enableShipdaySun',
  delete_flg: 'deleteFlg'
}
const primayKey = ['orgCenterId', 'orgSupplierId']

interface DataListProps extends DataListPropsBase {
  list: CenterSupplier
  supplier: MstSupplier[]
  systype: Groups[]
  center: MstCenter[]
  searchParam: GetMstCenterSupplierCenterSupplierGetRequest
}

interface MstCenterSupplierWithId extends MstCenterSupplier, RecordEditEx {}

function DataList({
  list,
  supplier,
  isLoading,
  systype,
  center,
  searchParam,
  onReload,
  updateTrigger,
  onUploadData,
  setHasGridEdited,
  setResultMessage,
  setDownloadColumnDefs,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const { fetchCenterSupplierUpdate, isUpdating, result } = useCenterSupplier()
  const gridRef = useRef<AgGridReact<MstCenterSupplierWithId>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [editorDataReady, setEditorDataReady] = useState(false);
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 250px)' }), [])
  const [row, setRow] = useState<MstCenterSupplierWithId[]>(list.list.map((item) => ({ ...item, ...initRecordEditEx() })))

  const [supplierSelect, setSupplierSelect] = useState<(string | null)[]>([])
  const [centerSelect, setCenterSelect] = useState<(string | null)[]>([])
  const [rowChangeTriggerRowID, setRowChangeTriggerRowID] = useState<string | null>(null);
  const [orderSysTypeCodeLists, setOrderSysTypeCodeLists] = useState<{ [key: string]: Codes[] }>({})
  const [orderSysTypeCodeSelects, setOrderSysTypeCodeSelects] = useState<{ [key: string]: (string | null)[] }>({})
  const orderUnitTypeGroups = ['extend_enable_flg', 'optimize_type']

  const tableFieldToGroup: { [key: string]: string } = {
    extendEnableFlg: 'extend_enable_flg',
    optimizeType: 'optimize_type',
    enableShipdayMon: 'extend_enable_flg',
    enableShipdayTue: 'extend_enable_flg',
    enableShipdayWed: 'extend_enable_flg',
    enableShipdayThu: 'extend_enable_flg',
    enableShipdayFri: 'extend_enable_flg',
    enableShipdaySat: 'extend_enable_flg',
    enableShipdaySun: 'extend_enable_flg',
  }

  useEffect(() => {
    setResultMessage(result)
  }, [result])
  
  useEffect(() => {
    const allSelected = orderUnitTypeGroups.every((group) => orderSysTypeCodeSelects[group]?.length > 0)
    if (centerSelect.length > 0 && allSelected && supplierSelect.length > 0) {
      setEditorDataReady(true)
    }
  }, [centerSelect, orderSysTypeCodeSelects, supplierSelect]);

  
  
  useEffect(() => {
    const values = supplier?.map((r) => r.orgSupplierId) || []
    setSupplierSelect(values)
  }, [supplier]);
  useEffect(() => {
    setEditorDataReady(false)

    const values = center?.map((r) => r.orgCenterId) || []
    setCenterSelect([...values])
  }, [center])

  useEffect(() => {
    const newLists: { [key: string]: Codes[] } = {}
    const newSelects: { [key: string]: (string | null)[] } = {}

    orderUnitTypeGroups.forEach((group) => {
      const codes = systype.find((r) => r.typeGroup === group)?.codes || []
      newLists[group] = codes
      newSelects[group] = codes.map((r) => r.typeCode)
    })

    setOrderSysTypeCodeLists(newLists)
    setOrderSysTypeCodeSelects(newSelects)
  }, [systype])
  useEffect(() => {
    if (!rowChangeTriggerRowID) return;
    const updatedRows: MstCenterSupplierWithId[] = [];
    
    gridRef.current?.api?.forEachNode((node) => {
      if (node?.data) {
        const trRow = node.data as MstCenterSupplierWithId;
        
        if (trRow.isEdited || trRow.id === rowChangeTriggerRowID) {
          setMstCenterSupplierCol(trRow, trRow.orgCenterId, trRow.orgSupplierId);
          updatedRows.push(trRow);
        }
      }
    });
    
    if (updatedRows.length > 0) {
      gridRef.current?.api?.applyTransaction({ update: updatedRows });
    }
    
    setRowChangeTriggerRowID(null);
  }, [rowChangeTriggerRowID, supplier, center]);

  const createBlankRow = useCallback((): MstCenterSupplierWithId => ({
    companyId: loginUserInfo?.companyId || null,
    orgCenterId: null,
    orgSupplierId: null,
    centerId: searchParam.centerId || null,
    optimizeType: null,
    stopArrival: false,
    arrivalRatioMon: 100,
    arrivalRatioTue: 100,
    arrivalRatioWed: 100,
    arrivalRatioThu: 100,
    arrivalRatioFri: 100,
    arrivalRatioSat: 100,
    arrivalRatioSun: 100,
    minOrderQuantityOnce: null,
    orderLeadtime: null,
    orderLimitTime: null,
    enableShipdayMon: null,
    enableShipdayTue: null,
    enableShipdayWed: null,
    enableShipdayThu: null,
    enableShipdayFri: null,
    enableShipdaySat: null,
    enableShipdaySun: null,
    deleteFlg: false,
    insertDatetime: null,
    updateDatetime: null,
    insertUser: loginUserInfo?.userId || null,
    deleteDatetime: null,
    deleteUser: null,
    updateUser: null,
    ...initRecordEditEx(true),
  }
  ), [loginUserInfo?.companyId, searchParam.centerId])

  const setMstCenterSupplierCol = (pRow: MstCenterSupplierWithId, orgCenterId?: string | null | undefined, orgSupplierId?: string | null | undefined ) => {
    if (pRow) {
      const selectedSupplier = {... supplier.find((item) => item.orgSupplierId === orgSupplierId)}
      const selectedCenter = {... center.find((item) => item.orgCenterId === orgCenterId)}
      // console.log("setMstItemCol", pRow, orgItemCode, items, selectedSupplier)
      Object.assign(pRow, {
        centerId: selectedCenter ? selectedCenter.centerId : (pRow.centerId || searchParam.centerId),        
        minOrderQuantityOnceSupplier: selectedSupplier ? selectedSupplier.minOrderQuantityOnce : null,
        orderLeadtimeSupplier: selectedSupplier ? selectedSupplier.orderLeadtime : null,
        orderLimitTimeSupplier: selectedSupplier ? selectedSupplier.orderLimitTime : null,
        enableShipdayMonSupplier: selectedSupplier ? selectedSupplier.enableShipdayMon : null,
        enableShipdayTueSupplier: selectedSupplier ? selectedSupplier.enableShipdayTue : null,
        enableShipdayWedSupplier: selectedSupplier ? selectedSupplier.enableShipdayWed : null,
        enableShipdayThuSupplier: selectedSupplier ? selectedSupplier.enableShipdayThu : null,
        enableShipdayFriSupplier: selectedSupplier ? selectedSupplier.enableShipdayFri : null,
        enableShipdaySatSupplier: selectedSupplier ? selectedSupplier.enableShipdaySat : null,
        enableShipdaySunSupplier: selectedSupplier ? selectedSupplier.enableShipdaySun : null,
      })
    }
  }

  // 入力チェック
  const fieldValidate = (pRow: MstCenterSupplierWithId, field: string | undefined, newValue: any): boolean => {
    let hasErrors = false
    switch (field) {
      case 'orgCenterId' : 
      case 'orgSupplierId' : {
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue))
        if (!hasErrors) {
          hasErrors = duplicateCheck(pRow, newValue)
        }
        break
      }
      default:
        return false
    }
    return hasErrors
  }

    const cellValueChanged = (event: CellValueChangedEvent<MstCenterSupplierWithId>, hasError: boolean) => {
      const { data, colDef, newValue, oldValue, node } = event
      const { field } = colDef
  
      if (field === 'orgSupplierId') {
        
        setRowChangeTriggerRowID(data.id);
      } else if (field === "orgCenterId") {
        const selectedCenter = center.find((item) => item.orgCenterId === newValue);
        const updatedRow = { ...data };
        setMstCenterSupplierCol(updatedRow, newValue, updatedRow.orgSupplierId)
        node.setData(updatedRow)
        // 仕入先コード変更時に、APIのエラーを解消
        checkAndSetError(updatedRow, "orgCenterId", null);
        checkAndSetError(updatedRow, "orgSupplierId", null);
        // API以外のエラーを再度チェック
        fieldValidate(updatedRow, "orgCenterId", updatedRow.orgCenterId)
        fieldValidate(updatedRow, "orgSupplierId", updatedRow.orgSupplierId)
      }
    }

  const updateMstCenterSupplier = useCallback(
    async (updatedRows: MstCenterSupplierWithId[]) => {
      const datalist = updatedRows.map(rowData => {
        const { isEdited, editedFields, errors, ...rest } = rowData;
        
        const selectedCenter = rowData.orgCenterId ? 
          center.find((item) => item.orgCenterId === rowData.orgCenterId) : null;
        const finalCenterId = selectedCenter?.centerId || rowData.centerId || searchParam.centerId;
        
        return {
          ...rest,
          orgCenterId: rest.orgCenterId ?? '',
          centerId: finalCenterId, 
        };
      });
      try {
        await fetchCenterSupplierUpdate({
          mstCenterSupplierUpdate: { body: datalist },
        })
        if(onReload) onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === 'error') {
          const gridApi = gridRef.current?.api

          err.errors.forEach((errrow: any) => {
            const { rowdata } = errrow
            const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id
            const orgSupplierId = (rowdata as { org_supplier_id: string | null }).org_supplier_id
            const errRow = updatedRows.find(
              (rowdatas) => rowdatas.orgCenterId === orgCenterId && rowdatas.orgSupplierId === orgSupplierId)
            if (errRow) {
              // UpdateAPIのErrorは、PKをエラー表記して、サーバーから取得したエラーを表示する
              errRow.errors = { 
                orgSupplierId: [errrow.errorMessage],
                orgCenterId: [errrow.errorMessage],
              }
              const rowNode = gridApi?.getRowNode(errRow.id || '')
              if (rowNode) {
                rowNode.setData(errRow)
              }
            }
          })
        }
      } 
    },
    [fetchCenterSupplierUpdate, searchParam]
  )

  const handleImportExcel = (data: any[]) => {
    let hasError = false
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCenterSupplierWithId> = {}
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping]
          const importvalue = excelRow[key]
          let setvalue = importvalue || null
          if (['deleteFlg', 'stopArrival'].indexOf(tableField) >= 0) {
            setvalue = parseBoolean(excelRow[key])
          } else if (['arrivalRatioMon', 'arrivalRatioTue', 'arrivalRatioWed', 'arrivalRatioThu', 
            'arrivalRatioFri', 'arrivalRatioSat', 'arrivalRatioSun'].includes(tableField)) {
              setvalue = parsePositiveInteger(importvalue)
          } else if (['optimizeType', 'enableShipdayMon', 'enableShipdayTue', 'enableShipdayWed', 'enableShipdayThu', 
            'enableShipdayFri', 'enableShipdaySat', 'enableShipdaySun'].includes(tableField)) {
              const group = tableFieldToGroup[tableField]
              const typeList = orderSysTypeCodeLists[group]
  
              const codevalue = typeCodeParser(importvalue, typeList)
              setvalue = typeCodeParser(codevalue, typeList)
              if (!codevalue && importvalue) hasError = true      
          } else if (['minOrderQuantityOnce','orderLeadtime',].includes(tableField)) {
            setvalue = parsePositiveInteger(importvalue)
          } else if (['orderLimitTime'].includes(tableField)) {
            setvalue = parseTime(importvalue);
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!isNonInputValue(importvalue) && setvalue === null) hasError = true
          mappedRow[tableField as keyof MstCenterSupplierWithId] = setvalue as unknown as undefined
        }
      })
      return mappedRow as MstCenterSupplierWithId
    })
    return { mappedData, hasError }
  }

  const {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    numberRangeValueParser,
    parseStringValue,
    parseTime,
    timeParser,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    onReload,
    onUploadData,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateMstCenterSupplier,
    handleImportExcel,
    createBlankRow,
    fieldValidate,
    cellValueChanged,
    setRowChangeTriggerRowID
  })
  // console.log(systype)
  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
      headerName: '自社センターID',
      field: 'orgCenterId',
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null,
      },
      rowDrag: true,
      pinned: 'left',
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => selectValueFormatter(params.value, center, "orgCenterId", "centerName"),
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) =>
          selectValueFormatter(params.value, center, 'orgCenterId', 'centerName'),
      }, 
      valueParser: (params: ValueParserParams) => selectValueParser(params, center, "orgCenterId"),
      cellEditorParams: editorDataReady ? ({
        values : [null, ...centerSelect],
        formatValue: (value: string) => selectValueFormatter(value, center, "orgCenterId", "centerName"),
        searchType: "matchAny",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams) : {values: ['-']},
    },
    {
      headerName: '仕入先企業ID',
      field: 'orgSupplierId',
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null,
      },
      pinned: 'left',
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => selectValueFormatter(params.value, supplier, "orgSupplierId", "supplierName"),
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) =>
          selectValueFormatter(params.value, supplier, 'orgSupplierId', 'supplierName'),
      }, 
      valueParser: (params: ValueParserParams) => selectValueParser(params, supplier, "orgSupplierId"),
      cellEditorParams: editorDataReady ? ({
        values : [null, ...supplierSelect],
        formatValue: (value: string) => selectValueFormatter(value, supplier, "orgSupplierId", "supplierName"),
        searchType: "matchAny",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams) : {values: ['-']},
    },
    {
      headerName: '発注推奨数算出',
      children: [
        {
          headerName: '平準化基準',
          field: 'optimizeType',
          width: 100,
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value, orderSysTypeCodeLists['optimize_type'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['optimize_type'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value, orderSysTypeCodeLists['optimize_type'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: [null, ...orderSysTypeCodeSelects['optimize_type'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['optimize_type'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
        {
          headerName: '入荷停止',
          field: 'stopArrival',
          width: 100,
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: formatBooleanValue,
          valueParser: parseBooleanValue,
        },
      ],
    },
    {
      headerName: '入荷比率設定（％）',
      children: [
        {
          headerName: '月',
          field: 'arrivalRatioMon',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
        {
          headerName: '火',
          field: 'arrivalRatioTue',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
        {
          headerName: '水',
          field: 'arrivalRatioWed',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
        {
          headerName: '木',
          field: 'arrivalRatioThu',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
        {
          headerName: '金',
          field: 'arrivalRatioFri',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
        {
          headerName: '土',
          field: 'arrivalRatioSat',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
        {
          headerName: '日',
          field: 'arrivalRatioSun',
          width: 70,
          cellClass: 'number-cell',
          editable: true,
          valueFormatter: numberValueFormatter,
          valueParser: numberRangeValueParser,
        },
      ],
    },
    {
      headerName: 'センター個別設定：発注条件',
      headerClass: 'parent-header',
      children: [
        {
          headerName: '1回あたり最低発注数',
          field: 'minOrderQuantityOnce',
          editable: true,
          cellClass:'number-cell',
          cellEditor:'agNumberCellEditor',
          cellEditorParams: {
            step: 1,
            min: 0, 
          },
          valueParser: numberValueParser,
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: '発注リードタイム',
          field: 'orderLeadtime',
          editable: true,
          cellClass:'number-cell',
          cellEditor:'agNumberCellEditor',
          cellEditorParams: {
            step: 1,
            min: 0, 
          },
          valueParser: numberValueParser,
          valueFormatter: numberValueFormatter,
        },
        {
          headerName: '締め時間',
          field: 'orderLimitTime',
          editable: true,
          cellClass:'number-cell',
          cellEditor:'agTextCellEditor',
          cellRenderer: TimeCellRenderer,
          valueParser: timeParser,
        }
      ],
    },
    {
      headerName: 'センター個別設定：出荷設定',
      children: [
        {
          headerName: '月',
          field: 'enableShipdayMon',
          width: 160,
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: ['', ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: [''] },
        },
        {
          headerName: '火',
          field: 'enableShipdayTue',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          width: 160,
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: ['', ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
        {
          headerName: '水',
          field: 'enableShipdayWed',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          width: 160,
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: [null, ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
        {
          headerName: '木',
          field: 'enableShipdayThu',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          width: 160,
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: [null, ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
        {
          headerName: '金',
          field: 'enableShipdayFri',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          width: 160,
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: [null, ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
        {
          headerName: '土',
          field: 'enableShipdaySat',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          width: 160,
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: [null, ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
        {
          headerName: '日',
          field: 'enableShipdaySun',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          width: 160,
          valueFormatter: (params: ValueFormatterParams) =>
          typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          valueParser: (params: ValueParserParams) =>
          selectTypecodeValueParser(params, orderSysTypeCodeLists['extend_enable_flg'!]),
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => 
              typecodeFormatter(params.value !== null ? String(params.value) : '', orderSysTypeCodeLists['extend_enable_flg'!]),
          },
          cellEditorParams: editorDataReady ? ({
            values: [null, ...orderSysTypeCodeSelects['extend_enable_flg'!]],
            formatValue: (value: string) =>
            selectValueFormatter(value, orderSysTypeCodeLists['extend_enable_flg'!], 'typeCode', 'typeName'),
            } as IRichCellEditorParams) : { values: ['-'] },
        },
      ],
    },
    {
      headerName: '仕入先マスタ 発注条件',
      headerClass: 'parent-header',
      children: [
        {
          headerName: '1回あたり最低発注数',
          field: 'minOrderQuantityOnceSupplier',
          cellClass:'number-cell',
          width: 160,
        },
        {
          headerName: '発注リードタイム',
          field: 'orderLeadtimeSupplier',
          cellClass:'number-cell',
          width: 160,
        },
        {
          headerName: '締め時間',
          field: 'orderLimitTimeSupplier',
          cellClass:'number-cell',
          width: 160,
        }
      ],
    },
    {
      headerName: '仕入先マスタ 出荷設定',
      headerClass: 'parent-header',
      children: [
        {
          headerName: '月',
          width: 70,
          field: 'enableShipdayMonSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
        {
          headerName: '火',
          width: 70,
          field: 'enableShipdayTueSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
        {
          headerName: '水',
          width: 70,
          field: 'enableShipdayWedSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
        {
          headerName: '木',
          width: 70,
          field: 'enableShipdayThuSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
        {
          headerName: '金',
          width: 70,
          field: 'enableShipdayFriSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
        {
          headerName: '土',
          width: 70,
          field: 'enableShipdaySatSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
        {
          headerName: '日',
          width: 70,
          field: 'enableShipdaySunSupplier',
          cellRenderer: 'agCheckboxCellRenderer',
          valueFormatter: formatBooleanValue,
        },
      ],
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue,
    },
  ]

  const columnDefs = useMemo<ColDef[]>(
    updateColumnDefs,
    [row, editorDataReady])

  // ダウンロードファイル用のカラム定義
  useEffect(() => {
    if (setDownloadColumnDefs)
      setDownloadColumnDefs([
        {
          headerName: '自社センターID',
          children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }],
        },
        {
          headerName: '仕入先企業ID',
          children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }],
        },
        {
          headerName: '平準化基準',
          children: [{ headerName: 'optimize_type', field: 'optimizeType', cellClass: 'stringType' }],
        },
        {
          headerName: '入荷停止',
          children: [{ headerName: 'stop_arrival', field: 'stopArrival', cellClass: 'booleanType' }],
        },
        {
          headerName: '入荷比率設定（％）月',
          children: [{ headerName: 'arrival_ratio_mon', field: 'arrivalRatioMon', cellClass: 'numberType' }],
        },
        {
          headerName: '入荷比率設定（％）火',
          children: [{ headerName: 'arrival_ratio_tue', field: 'arrivalRatioTue', cellClass: 'numberType' }],
        },
        {
          headerName: '入荷比率設定（％）水',
          children: [{ headerName: 'arrival_ratio_wed', field: 'arrivalRatioWed', cellClass: 'numberType' }],
        },
        {
          headerName: '入荷比率設定（％）木',
          children: [{ headerName: 'arrival_ratio_thu', field: 'arrivalRatioThu', cellClass: 'numberType' }],
        },
        {
          headerName: '入荷比率設定（％）金',
          children: [{ headerName: 'arrival_ratio_fri', field: 'arrivalRatioFri', cellClass: 'numberType' }],
        },
        {
          headerName: '入荷比率設定（％）土',
          children: [{ headerName: 'arrival_ratio_sat', field: 'arrivalRatioSat', cellClass: 'numberType' }],
        },
        {
          headerName: '入荷比率設定（％）日',
          children: [{ headerName: 'arrival_ratio_sun', field: 'arrivalRatioSun', cellClass: 'numberType' }],
        },
        {
          headerName: '1回あたり最低発注数',
          children: [{ headerName: 'min_order_quantity_once', field: 'minOrderQuantityOnce', cellClass: 'numberType' }],
        },
        {
          headerName: '発注リードタイム',
          children: [{ headerName: 'order_leadtime', field: 'orderLeadtime', cellClass: 'numberType' }],
        },
        {
          headerName: '締め時間',
          children: [{ headerName: 'order_limit_time', field: 'orderLimitTime', cellClass: 'stringType' }],
        },
        {
          headerName: '出荷可能フラグ（月曜日）',
          children: [{ headerName: 'enable_shipday_mon', field: 'enableShipdayMon', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（火曜日）',
          children: [{ headerName: 'enable_shipday_tue', field: 'enableShipdayTue', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（水曜日）',
          children: [{ headerName: 'enable_shipday_wed', field: 'enableShipdayWed', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（木曜日）',
          children: [{ headerName: 'enable_shipday_thu', field: 'enableShipdayThu', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（金曜日）',
          children: [{ headerName: 'enable_shipday_fri', field: 'enableShipdayFri', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（土曜日）',
          children: [{ headerName: 'enable_shipday_sat', field: 'enableShipdaySat', cellClass: 'booleanType' }],
        },
        {
          headerName: '出荷可能フラグ（日曜日）',
          children: [{ headerName: 'enable_shipday_sun', field: 'enableShipdaySun', cellClass: 'booleanType' }],
        },
        {
          headerName: '削除フラグ',
          children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }],
        },
      ])
  }, [setDownloadColumnDefs])

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow()
      setRow([blankRow])
    } else {
      const datalist = list.list.map((item) => ({ ...item, ...initRecordEditEx() }))
      if (supplier.length > 0 && center.length > 0) {
        datalist.forEach((item) => {
          setMstCenterSupplierCol(item as MstCenterSupplierWithId, item.orgCenterId, item.orgSupplierId)
        })
      }
      setRow(datalist)
    }
  }, [list.list])

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            key={`${row[0]?.companyId}-${editorDataReady}`}
            rowData={row}
            ref={gridRef}
            getRowId={(params) => params.data.id}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            autoSizeStrategy={autoSizeStrategy}
            loadingOverlayComponent={LoadingOverlay}
          />
        </div>
      </div>
    </div>
  )
}

export default DataList
