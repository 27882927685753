import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  GetMstCentersCenterGetRequest,
  ResponseResult,
  ResponseUpdate,
  MasterCenterUpdateMasterCenterUpdatePostRequest,
  Center,
  MstCenter,
} from 'api-client'
import type { ErrorResponse } from 'types/types'


export interface AutocompleteCenter {
  label: string | null
  centerId?: number | null
  orgCenterId?: string | null
}

export interface MstCenterSeq extends MstCenter{
  id: string
}

export interface MstCentersSeq extends Center{
  list: MstCenterSeq[]
}

export const useCenter = () => {
  const [centers, setCenters] = useState<MstCenter[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [result, setResult] = useState<ResponseResult>()
  const [autocompletCenters, setAutocompleteCenters] = useState<AutocompleteCenter[]>([])
  const [centerSearch, setCenterSearch] = useState<MstCentersSeq>(initRequestParam)
  // const [centerSearchIsLoading, setCenterSearchIsLoading] = useState(false)
  // const [centerUpdateIsLoading, setCenterUpdateIsLoading] = useState(false)
  const [centerSearchResult, setCenterSearchResult] = useState<ResponseResult | undefined>(undefined)
  const [updateResult, setUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
	
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchCenterSearch = (searchParam: GetMstCentersCenterGetRequest) => {
    setCenterSearch(initRequestParam)
    // setCenterSearchResult(undefined)
    // setCenterSearchIsLoading(true)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstCentersCenterGet(searchParam)
      })
      .then((response) => {
        setCenterSearch({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            id: Math.random().toString(32).substring(2),
          }))
        })
        // SelectCenterコンポーネントのデータバインド形式に合わせる
        const centersMap = response.data.list.map((value) => ({
          label: value.centerName,
          centerId: value.centerId,
          orgCenterId: value.orgCenterId
        }))
        setAutocompleteCenters(centersMap)
        setCenterSearchResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setCenterSearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchCenterUpdate = async (body: MasterCenterUpdateMasterCenterUpdatePostRequest): Promise<ResponseUpdate> => {
    setIsUpdating(true);
    setResult(undefined)
    // setCenterUpdateIsLoading(true);
    try {
      const token = await auth.currentUser?.getIdToken(true);
      if (!token) throw new Error('Token not found');
      
      const conf = new Configuration({
        basePath: process.env.REACT_APP_API_BASE_PATH,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const api = new DefaultApi(conf);
      const response = await api.masterCenterUpdateMasterCenterUpdatePost(body);
      
      setResult(response.result)
      setUpdateResult(response);

      if (response.result.status !== 'success') {
        const error = new Error(response.result.message || '入力エラーがあります');
        Object.assign(error, {
            ...response
        });
        throw error;
      }
      return response;
    } catch (error) {
        if (error && typeof error === 'object' && 'result' in error) {
            const apiResponse = error as ResponseUpdate; 
            await new Promise<void>(resolve => {
              setUpdateResult(apiResponse);
              resolve();
            });
            return await Promise.reject(apiResponse);
        }
        const { response } = error as any;
        const errorResponse = {
            errors: [],
            result: {
                status: 'error',
                message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
                systemDate: null,
                pageNo: 0,
                systemInfo: null,
            },
            updateCount: null,
        };
        await new Promise<void>(resolve => {
          setUpdateResult(errorResponse);
            setResult(errorResponse.result)
            resolve();
        });
        return await Promise.reject(errorResponse);

    } finally {
      // setCenterUpdateIsLoading(false);
      setIsUpdating(false);
    }
  };


  return {
  	result,
  	isLoading,
  	isUpdating,
    centers,
    autocompletCenters,
    
    centerSearch,
    fetchCenterSearch,
    centerSearchResult,
    // centerSearchIsLoading,

    fetchCenterUpdate,
    updateResult,
    // centerUpdateIsLoading,
  }
}