/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCenterSupplier
 */
export interface MstCenterSupplier {
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    centerId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    optimizeType: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioMon: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioTue: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioWed: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioThu: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioFri: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioSat: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    arrivalRatioSun: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    stopArrival: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    minOrderQuantityOnce: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenterSupplier
     */
    orderLeadtime: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    orderLimitTime: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdayMon: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdayTue: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdayWed: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdayThu: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdayFri: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdaySat: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    enableShipdaySun: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenterSupplier
     */
    deleteFlg: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterSupplier
     */
    insertDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    insertUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterSupplier
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    updateUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenterSupplier
     */
    deleteDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenterSupplier
     */
    deleteUser: string | null;
}

/**
 * Check if a given object implements the MstCenterSupplier interface.
 */
export function instanceOfMstCenterSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "optimizeType" in value;
    isInstance = isInstance && "arrivalRatioMon" in value;
    isInstance = isInstance && "arrivalRatioTue" in value;
    isInstance = isInstance && "arrivalRatioWed" in value;
    isInstance = isInstance && "arrivalRatioThu" in value;
    isInstance = isInstance && "arrivalRatioFri" in value;
    isInstance = isInstance && "arrivalRatioSat" in value;
    isInstance = isInstance && "arrivalRatioSun" in value;
    isInstance = isInstance && "stopArrival" in value;
    isInstance = isInstance && "minOrderQuantityOnce" in value;
    isInstance = isInstance && "orderLeadtime" in value;
    isInstance = isInstance && "orderLimitTime" in value;
    isInstance = isInstance && "enableShipdayMon" in value;
    isInstance = isInstance && "enableShipdayTue" in value;
    isInstance = isInstance && "enableShipdayWed" in value;
    isInstance = isInstance && "enableShipdayThu" in value;
    isInstance = isInstance && "enableShipdayFri" in value;
    isInstance = isInstance && "enableShipdaySat" in value;
    isInstance = isInstance && "enableShipdaySun" in value;
    isInstance = isInstance && "deleteFlg" in value;
    isInstance = isInstance && "insertDatetime" in value;
    isInstance = isInstance && "insertUser" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "updateUser" in value;
    isInstance = isInstance && "deleteDatetime" in value;
    isInstance = isInstance && "deleteUser" in value;

    return isInstance;
}

export function MstCenterSupplierFromJSON(json: any): MstCenterSupplier {
    return MstCenterSupplierFromJSONTyped(json, false);
}

export function MstCenterSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCenterSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'orgCenterId': json['org_center_id'],
        'orgSupplierId': json['org_supplier_id'],
        'optimizeType': json['optimize_type'],
        'arrivalRatioMon': json['arrival_ratio_mon'],
        'arrivalRatioTue': json['arrival_ratio_tue'],
        'arrivalRatioWed': json['arrival_ratio_wed'],
        'arrivalRatioThu': json['arrival_ratio_thu'],
        'arrivalRatioFri': json['arrival_ratio_fri'],
        'arrivalRatioSat': json['arrival_ratio_sat'],
        'arrivalRatioSun': json['arrival_ratio_sun'],
        'stopArrival': json['stop_arrival'],
        'minOrderQuantityOnce': json['min_order_quantity_once'],
        'orderLeadtime': json['order_leadtime'],
        'orderLimitTime': json['order_limit_time'],
        'enableShipdayMon': json['enable_shipday_mon'],
        'enableShipdayTue': json['enable_shipday_tue'],
        'enableShipdayWed': json['enable_shipday_wed'],
        'enableShipdayThu': json['enable_shipday_thu'],
        'enableShipdayFri': json['enable_shipday_fri'],
        'enableShipdaySat': json['enable_shipday_sat'],
        'enableShipdaySun': json['enable_shipday_sun'],
        'deleteFlg': json['delete_flg'],
        'insertDatetime': (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': json['insert_user'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': json['update_user'],
        'deleteDatetime': (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': json['delete_user'],
    };
}

export function MstCenterSupplierToJSON(value?: MstCenterSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'center_id': value.centerId,
        'org_center_id': value.orgCenterId,
        'org_supplier_id': value.orgSupplierId,
        'optimize_type': value.optimizeType,
        'arrival_ratio_mon': value.arrivalRatioMon,
        'arrival_ratio_tue': value.arrivalRatioTue,
        'arrival_ratio_wed': value.arrivalRatioWed,
        'arrival_ratio_thu': value.arrivalRatioThu,
        'arrival_ratio_fri': value.arrivalRatioFri,
        'arrival_ratio_sat': value.arrivalRatioSat,
        'arrival_ratio_sun': value.arrivalRatioSun,
        'stop_arrival': value.stopArrival,
        'min_order_quantity_once': value.minOrderQuantityOnce,
        'order_leadtime': value.orderLeadtime,
        'order_limit_time': value.orderLimitTime,
        'enable_shipday_mon': value.enableShipdayMon,
        'enable_shipday_tue': value.enableShipdayTue,
        'enable_shipday_wed': value.enableShipdayWed,
        'enable_shipday_thu': value.enableShipdayThu,
        'enable_shipday_fri': value.enableShipdayFri,
        'enable_shipday_sat': value.enableShipdaySat,
        'enable_shipday_sun': value.enableShipdaySun,
        'delete_flg': value.deleteFlg,
        'insert_datetime': (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
    };
}

