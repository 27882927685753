import type { FormEvent } from 'react'
import React, { useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { ClearButton, SearchButton, SearchFrom, SelectCenter } from 'components/commonparts'
import { ItemSearch } from 'components/dialog'

import type { GetShipExpectedShipExpectedGetRequest } from 'api-client'

interface SearchConditionProps {
  searchParam: GetShipExpectedShipExpectedGetRequest
  onSearch: (requestParam: GetShipExpectedShipExpectedGetRequest) => void
  searchTrigger?: boolean 
}

function SearchCondition({ searchParam, onSearch, searchTrigger = false }: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [centerIsValid, setCenterIsValid] = useState(true)
  const [itemIsValid, setItemIsValid] = useState(true)
  const [centerId, setCenterId] = useState<number | null | undefined>(searchParam.centerId)
  const [itemCode, setItemCode] = useState<string | null | undefined>(searchParam.itemCode)

  const onClear = () => {
    setItemCode('')
    setCenterId(undefined)
  }

  // Search using formEvent
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // Block call data if center and item is inValid
    if (!centerIsValid && !itemIsValid) {
      onSearch({
        companyId: loginUserInfo!.companyId,
        centerId,
        itemCode,
      })
    }
  }

  useEffect(() => {
    if(!centerIsValid && !itemIsValid){
      onSearch({
        companyId: loginUserInfo!.companyId,
        centerId,
        itemCode,
      })   
    }
  }, [searchTrigger])

  return (
    // Wrap into a box to call formEvent
    <Box component="form" onSubmit={handleFormSubmit}>
      <Grid item container>
        <Grid item xs={12}>
          <ItemSearch
            trCompanyId={loginUserInfo!.companyId}
            itemCode={itemCode}
            setItemCode={setItemCode}
            setItemInfo={() => {}}
            isRequred
            setIsValid={setItemIsValid}
          />
          <SelectCenter
            trCompanyId={loginUserInfo!.companyId}
            centerId={centerId}
            setCenterId={setCenterId}
            setCenterInfo={() => {}}
            isRequired
            setIsValid={setCenterIsValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end" marginTop="10px" marginRight="10px">
            <SearchButton searchId="item"/>
            <ClearButton onClick={onClear} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SearchCondition
