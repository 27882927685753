/* eslint-disable arrow-body-style */
/* eslint-disable-next-line spaced-comment */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'

import { useAuthContext } from 'AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import {
  type GetStockItemTreeStockItemTreeGetRequest,
  type Daylist,
  type Stock,
  type RenewalOrgItem,
  RenewalOrgItemFromJSONTyped,
  type StockUpdateBase,
} from 'api-client'
import clsx from 'clsx'
import { LoadingOverlay } from 'components/commonparts'
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar'
import { format, isSameDay } from 'date-fns'
import { ja } from 'date-fns/locale'
import { useStockData } from 'hooks/useStockData'
import _ from 'lodash'
import { AG_GRID_LOCALE } from 'utils/agGridLocale'
import { convertDateToRequestParam } from 'utils/convertDateToRequestParam'
import { getAPI } from 'utils/getAPI'

import ItemNameRenderer from '../StockGrid/ItemNameRenderer'
import StockCellEditor from '../StockGrid/StockCellEditor'
import {
  ROW_TYPE_ARRIVAL,
  ROW_TYPE_QUANTITY_STOP_SHIPPING,
  ROW_TYPE_REMARK,
  ROW_TYPE_SALES_ORDER,
  ROW_TYPE_SHIP,
  ROW_TYPE_SHIP_EXCLUSIVE,
  ROW_TYPE_STOCK,
  ROW_TYPE_STOCK_DAYS,
  ROW_TYPE_STOCK_FOLLOW_BY_PRODUCT,
  ROW_TYPE_STOCK_PRODUCT,
} from '../StockGrid/table-model'

import type { RenewalSimulatorCell, RenewalSimulatorRow } from '../StockGrid/table-model'
import type {
  CellClassParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-community'
import type { ColDef, ColGroupDef, IAggFuncParams, IRowNode } from 'ag-grid-enterprise'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

import '../StockGrid/stock-grid.scss'

interface ItemRenewalSimulatorProps {
  trCompanyId: any
  centerIdProps: any
  itemCodeProps: any
  onDialogOpen: boolean
  updateAccept: boolean
  setUpdateAccept: (value: boolean) => void
  optimizeRange: string | undefined
  unitType: 'case' | 'pallet'
  renewalFlgProps: boolean | null
  onDialogClose?: () => void
}

const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>()

  return useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => callback(...args), delay)
    },
    [callback, delay]
  )
}

function ItemRenewalSimulator({
  trCompanyId,
  centerIdProps,
  itemCodeProps,
  onDialogOpen = false,
  updateAccept,
  setUpdateAccept,
  optimizeRange,
  unitType,
  renewalFlgProps,
  onDialogClose,
}: ItemRenewalSimulatorProps) {
  const { loginUserInfo } = useAuthContext()
  const systemDate = useMemo(() => loginUserInfo?.systemDate, [loginUserInfo])
  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const gridRef = useRef<AgGridReact<RenewalSimulatorRow>>(null)
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: true,
      sortable: false,
      filter: false,
    }),
    []
  )
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 120px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 150px)' }), [])
  const [daylist, setDaylist] = useState<Daylist[]>([])
  const [rowData, setRowData] = useState<RenewalSimulatorRow[]>([])
  const [updating, setUpdating] = useState(false)
  const hasRunRef = useRef(false)
  const hasFetch = useRef(false)

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const { result, stockData, fetchStockData, isLoading } = useStockData()

  const simulatorFetching = () => {
    const requestParam: GetStockItemTreeStockItemTreeGetRequest = {
      companyId: trCompanyId,
      centerId: centerIdProps,
      itemCode: itemCodeProps,
      viewUnitType: unitType,
      optimizeRange,
      renewal: renewalFlgProps,
    }
    fetchStockData(requestParam)
  }
  useEffect(() => {
    if (onDialogOpen && !hasFetch.current) {
      simulatorFetching()
      hasFetch.current = true
    }
  }, [onDialogOpen, hasFetch])

  const HEADER_GROUP_REC = 'headerGroupRec'
  const HEADER_GROUP_TODAY = 'headerGroupToday'
  const HEADER_GROUP_EXPECTED = 'headerGroupExpected'
  const TOTAL_ROW_NAME = 'センター合計'

  const { typeCode } = useAuthContext()

  const reasonTypes = useMemo<{ code: number; value: string }[]>(() => {
    const reasonGroup = typeCode.find((rg) => rg.typeGroup === 'order_quantity_reason')
    if (!reasonGroup) return []

    return reasonGroup.codes.map((c) => ({
      code: parseInt(c.typeCode, 10),
      value: c.typeName,
    }))

    return []
  }, [typeCode])

  /**
   * 日付リストのすべての日付カラム名（col1, col2,...）を返す
   */
  const allDayHeaders: string[] = useMemo(() => {
    if (!daylist) return []

    const headerList = daylist
      .sort((a: Daylist, b: Daylist) => (a.dateno || 0) - (b.dateno || 0))
      .map((day) => day.colname)
    return headerList.filter((header): header is string => header !== null && header !== undefined)
  }, [daylist])

  function firstColName(): string {
    return allDayHeaders[0] || 'col1'
  }

  const textSort = (a: string, b: string) => {
    if (a === 'センター合計') {
      return -1
    }
    if (b === 'センター合計') {
      return 1
    }
    // カスタムソートロジック
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  /**
   * 指定された日付カラムを含む、全ての未来日付のカラム名を返す
   * @param col 日付カラム名
   * @returns
   */
  function allNextCols(col: string): string[] {
    if (!allDayHeaders) return []
    const index = allDayHeaders.indexOf(col)
    if (index !== -1) {
      return allDayHeaders.slice(index)
    }

    return []
  }

  /**
   * 指定された日付カラムの1日前のカラム名を返す
   *
   * @param col 日付カラム名
   * @returns
   */
  function beforeCol(col: string) {
    const index = parseInt(col.replace('col', ''), 10)
    return `col${index - 1}`
  }

  function afterCol(col: string) {
    const index = parseInt(col.replace('col', ''), 10)
    return `col${index + 1}`
  }

  /**
   * 指定された行が属する商品の全ての行（入荷数、出荷数、在庫数、在庫日数）のなかから、rowTypeCodeで指定された種別の行を返す
   *
   * @param rowNode
   * @param rowTypeCode
   * @returns
   */
  function getRowInGroup(
    rowNode: IRowNode<RenewalSimulatorRow>,
    rowTypeCode: string
  ): IRowNode<RenewalSimulatorRow> | undefined {
    const rowsInGroup = rowNode.parent?.childrenAfterGroup
    if (!rowsInGroup) return undefined
    return rowsInGroup.find((row) => row?.data?.rowType?.code === rowTypeCode)
  }

  /**
   * 指定されたセルの在庫数を再計算する
   * @param rowNode 行データ
   * @param field カラム名
   * @returns
   */
  function calcStock(rowNode: IRowNode<RenewalSimulatorRow>, field: string) {
    
    const trColData = rowNode.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
    const stockCell =
    (getRowInGroup(rowNode, 'stock')?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell) || {}

    // 通常の在庫計算以外のイレギュラー処理

    // 初日の在庫数はデフォルトのまま計算しない
    if ((field === firstColName()) || (rowNode.data?.renewalNumber === 0 && trColData.recType === 'rec')){
      return {
        ...stockCell,
        value: stockCell.value,
      }
    }
    // 旧商品のイレギュラー
    if (rowNode.data?.renewalNumber === 1) {
      // 出荷停止フラグがONの場合、在庫数は0
      const stopShipRow = getRowInGroup(rowNode, 'olditemShipStopFlg')
      const stopFlgCell = stopShipRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
      if (stopFlgCell?.value === true) {
        return {
          ...stockCell,
          value: 0,
        }
      }
    }

    // 前日の在庫数を取得（負の場合は0とする）
    const beforeRenewalSimulatorRow = getRowInGroup(rowNode, 'stock')
    const beforeCell = beforeRenewalSimulatorRow?.data?.[
      beforeCol(field) as keyof RenewalSimulatorRow
    ] as RenewalSimulatorCell
    const beforeStock = Math.max(beforeCell?.value || 0, 0)

    // 当日の入荷数を取得
    const arrivalRow = getRowInGroup(rowNode, 'arrival')
    const arrivalCell = arrivalRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
    const arrival = arrivalCell?.value ?? 0

    // 当日の出荷数を取得
    const shipRow = getRowInGroup(rowNode, 'ship')
    const shipCell = shipRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
    const ship = shipCell?.value ?? 0

    // 当日の在庫数（自分自身）を取得
    const rawStock = beforeStock + Number(arrival) - ship

    const finalStock = rowNode.data?.renewalNumber === 1 ? Math.max(0, rawStock) : rawStock

    // 在庫数を計算（在庫数 + 入荷数 - 出荷数）
    return {
      ...stockCell,
      value: finalStock,
    }
  }

  function findRowByTypeAndItem(api: any, rowId: string): IRowNode<RenewalSimulatorRow> | undefined {
    let foundRow: IRowNode<RenewalSimulatorRow> | undefined
    api.forEachNode((node: IRowNode<RenewalSimulatorRow>) => {
      if (node.data && node.data.rowid === rowId) {
        foundRow = node
      }
    })
    return foundRow
  }
  // Calculate total stock with formular = stockRenewalNewItemNode + stockRenewalOldItemNode + quantityStopNode
  function calcTotalStockForItem(api: any, field: string) {
    const stockRenewalOldItemNode = findRowByTypeAndItem(api, '1_stock')
    const stockRenewalNewItemNode = findRowByTypeAndItem(api, '2_stock')
    const quantityStopNode = findRowByTypeAndItem(api, '1_quantityStopShipping')

    const stockRenewalNewItem =
      (stockRenewalNewItemNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value ?? 0
    const stockRenewalOldItem =
      (stockRenewalOldItemNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value ?? 0
    const quantityStop =
      (quantityStopNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value ?? 0

    const total = stockRenewalNewItem + stockRenewalOldItem + quantityStop
    return total
  }

  // Set ship quantity for newItem and oldItem
  function setNewOrgItemShip(api: any, field: string, rowid: string) {
    let value
    const col = beforeCol(field)
    // find row to get data
    const initalShipNode = findRowByTypeAndItem(api, '0_ship')
    const oldItemArrivalNode = findRowByTypeAndItem(api, '1_arrival')
    const oldItemShipNode = findRowByTypeAndItem(api, '1_ship')
    const olditemShipStopFlgNode = findRowByTypeAndItem(api, '1_olditemShipStopFlg')
    const beforeStockNode = findRowByTypeAndItem(api, '1_stock')

    // get value from row
    const initalShip = (initalShipNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || 0
    const oldItemArrival = (oldItemArrivalNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || 0
    const oldItemShip = (oldItemShipNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || 0
    const olditemShipStopFlg = (olditemShipStopFlgNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || false

    const beforeStock =
      col === 'col0'
        ? initalShip
        : (beforeStockNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || 0

    if (rowid === '1_ship') {
      if (olditemShipStopFlg) {
        value = 0
      } else {
        value = beforeStock + oldItemArrival > initalShip ? initalShip : beforeStock + oldItemArrival
      }
    }

    if (rowid === '2_ship') {
      value = initalShip - oldItemShip
    }

    return value
  }

  // Calculate quantityStopShipping
  function calcQuantityStopShipping(api: any, field: string) {
    const olditemShipStopFlgNode = findRowByTypeAndItem(api, '1_olditemShipStopFlg')
    const stockQuantityNode = findRowByTypeAndItem(api, '1_stock')

    const stockQuantity =
      (stockQuantityNode?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || 0
    const olditemShipStopFlg =
      (olditemShipStopFlgNode?.data?.[afterCol(field) as keyof RenewalSimulatorRow] as RenewalSimulatorCell)?.value || false

    return olditemShipStopFlg ? stockQuantity * -1 : 0
  }

  /**
   * 指定されたセルの在庫日数を再計算する
   * @param rowNode 行データ
   * @param field カラム名
   * @returns
   */
  function calcStockDays(rowNode: IRowNode<RenewalSimulatorRow>, field: string) {
    // 当日の在庫数を取得
    const RenewalSimulatorRow = getRowInGroup(rowNode, 'stock')
    const stockCell = RenewalSimulatorRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
    const stock = stockCell?.value ?? 0

    // 同じグループの出荷行を取得
    const shipRow = getRowInGroup(rowNode, 'ship')

    // 当日の在庫日数（自分自身）を取得
    const stockDaysRow = getRowInGroup(rowNode, 'stockDays')
    const stockDaysCell = stockDaysRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell

    const blankData = {
      ...stockDaysCell,
      value: null,
    }

    // 当日を含めて7日以上のデータが無い（未来データが無い）場合は無効とする
    const futureColsWithToday = allNextCols(field)
    if (futureColsWithToday.length <= 7) {
      return blankData
    }

    const futureCols = futureColsWithToday
    const shipNums: number[] = []

    futureCols.forEach((col) => {
      const shipCell = shipRow?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
      if (shipCell?.enableData && shipCell?.value != null) {
        const normalize = Number(shipCell.value)
        shipNums.push(normalize)
      }
    })

    if (shipNums.length < 7) {
      return blankData
    }

    const total = shipNums.slice(1, 8).reduce((acc, cur) => acc + cur, 0)
    if (total === 0 && stock === 0) {
      return blankData
    }

    const stockDays = stock / (total / 7)

    return {
      ...stockDaysCell,
      value: stockDays,
    }
  }

  /**
   * Calculate total stock days with total 商品別在庫数合計 / (total / 7)
   * @param rowNode 行データ
   * @param field カラム名
   * @returns
   */
  function calcTotalStockDays(rowNode: IRowNode<RenewalSimulatorRow>, field: string) {
    // 当日の在庫数を取得
    const RenewalSimulatorRow = getRowInGroup(rowNode, 'stockTotal')
    const stockCell = RenewalSimulatorRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
    const stock = stockCell?.value ?? 0

    // 同じグループの出荷行を取得
    const shipRow = getRowInGroup(rowNode, 'ship')

    // 当日の在庫日数（自分自身）を取得
    const stockDaysRow = getRowInGroup(rowNode, 'stockTotalDays')
    const stockDaysCell = stockDaysRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell

    const blankData = {
      ...stockDaysCell,
      value: null,
    }

    // 当日を含めて7日以上のデータが無い（未来データが無い）場合は無効とする
    const futureColsWithToday = allNextCols(field)
    if (futureColsWithToday.length <= 7) {
      return blankData
    }

    const futureCols = futureColsWithToday
    const shipNums: number[] = []

    futureCols.forEach((col) => {
      const shipCell = shipRow?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
      if (shipCell?.enableData && shipCell?.value != null) {
        const normalize = Number(shipCell.value)
        shipNums.push(normalize)
      }
    })

    if (shipNums.length < 7) {
      return blankData
    }

    const total = shipNums.slice(1, 8).reduce((acc, cur) => acc + cur, 0)
    if (total === 0 && stock === 0) {
      return blankData
    }

    const stockDays = Math.max(0, stock / (total / 7))
    return {
      ...stockDaysCell,
      value: stockDays,
    }
  }

  const calcAllAfterDays = (calcStartColName:string) => {
    // 開始カラムに指定された日付以降の全ての日付について、シミュレーション計算を実行する

    const gridApi = gridRef.current?.api
    if (!gridApi) return

    allNextCols(calcStartColName).forEach((col) => {

      // １日分の計算
      const isValidColumn = (colId: string) => gridApi.getColumnDef(colId) !== null
      if (col && isValidColumn(col)) {
        const arrivalNode = gridApi.getRowNode('0_arrival')
        const arrivalCell = arrivalNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
        
        const arrivalOldNode = gridApi.getRowNode('1_arrival')
        const arrivalOldCell = arrivalOldNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
        if (arrivalCell.renewalNumberDay === arrivalOldCell.renewalNumberDay) {
            const newCell = arrivalOldCell ? { ...arrivalOldCell, value: arrivalCell.value } : { value: arrivalCell.value }
            arrivalOldNode?.setDataValue(col, newCell)
        }

        const arrivalNewNode = gridApi.getRowNode('2_arrival')
        const arrivalNewCell = arrivalNewNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
        if (arrivalCell.renewalNumberDay === arrivalNewCell.renewalNumberDay) {
            const newCell = arrivalNewCell ? { ...arrivalNewCell, value: arrivalCell.value } : { value: arrivalCell.value }
            arrivalNewNode?.setDataValue(col, newCell)
        }

        const shipNode = gridApi.getRowNode('0_ship');
        const shipCell = shipNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell

        const shipOldNode = gridApi.getRowNode('1_ship');
        const shipOldCell = shipOldNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell

        if (shipOldNode) {
          const newTotal = setNewOrgItemShip(gridApi, col, '1_ship')
          const newCell = shipOldCell ? { ...shipOldCell, value: newTotal } : { shipCell: newTotal }
          shipOldNode?.setDataValue(col, newCell)
        }

        const shipNewNode = gridApi.getRowNode('2_ship');
        const shipNewCell = shipOldNode?.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell

        if (shipCell.renewalNumberDay === 2) {
          const newTotal = setNewOrgItemShip(gridApi, col, '2_ship')
          const newCell = shipNewCell ? { ...shipNewCell, value: newTotal } : { shipCell: newTotal }
          shipNewNode?.setDataValue(col, newCell)
        } else{
          shipNewNode?.setDataValue(col, { ...shipNewCell, value: 0 })
        }

        let node = gridApi.getRowNode('0_stock')
        if (node) node.setDataValue(col, calcStock(node, col))

        // 在庫日数の計算は在庫数に依存するため、在庫数の計算が完了してから行う
        node = gridApi.getRowNode('0_stockDays')
        if (node) node.setDataValue(col, calcStockDays(node, col))

        node = gridApi.getRowNode('1_quantityStopShipping')
        if (node) {
          const newTotal = calcQuantityStopShipping(gridApi, col)
          const currentCell = (node.data as RenewalSimulatorRow)[col as keyof RenewalSimulatorRow] as
            | RenewalSimulatorCell
            | undefined
          const newCell = currentCell ? { ...currentCell, value: newTotal } : { value: newTotal }
          node.setDataValue(col, newCell)
        }
        node = gridApi.getRowNode('1_stock')
        if (node) node.setDataValue(col, calcStock(node, col))
        node = gridApi.getRowNode('1_stockDays')
        if (node) node.setDataValue(col, calcStockDays(node, col))
            
        node = gridApi.getRowNode('2_stock')
        if (node) node.setDataValue(col, calcStock(node, col))
        node = gridApi.getRowNode('2_stockDays')
        if (node) node.setDataValue(col, calcStockDays(node, col))

        node = gridApi.getRowNode('0_stockTotal')
        if (node) {
          const newTotal = calcTotalStockForItem(gridApi, col)
          const currentCell = (node.data as RenewalSimulatorRow)[col as keyof RenewalSimulatorRow] as
            | RenewalSimulatorCell
            | undefined
          const newCell = currentCell ? { ...currentCell, value: newTotal } : { value: newTotal }

          node.setDataValue(col, newCell)
        }

        node = gridApi.getRowNode('0_stockTotalDays')
        if (node) {
          node.setDataValue(col, calcTotalStockDays(node, col))
        }
      }
    })
  }
  /**
   * セルが編集可能かどうかを判定する
   */
  const isCellEditable = useCallback(
    (colEditable: boolean, rowTypeCode: string | undefined, obj: RenewalSimulatorCell | null | undefined): boolean => {
      if (!rowTypeCode || unitType === 'pallet') {
        return false
      }
      if (rowTypeCode === ROW_TYPE_REMARK.code || rowTypeCode === ROW_TYPE_SHIP_EXCLUSIVE.code) {
        return true
      }

      const rowEditable = ['arrival', 'ship', 'reason', 'olditemShipStopFlg', 'remark'].includes(rowTypeCode)
      const cellEditable = obj?.enableUpdate || false
      return rowEditable && colEditable && cellEditable
    },
    [unitType]
  )

  /**
   * セルの表示フォーマットを設定する
   */
  const valueFormatter = useCallback(
    (params: ValueFormatterParams<RenewalSimulatorRow, RenewalSimulatorCell>) => {
      const obj = params.value
      const rowTypeCode = params.data?.rowType?.code

      if (obj === null || obj === undefined) {
        return ''
      }

      if (unitType === 'case' && ['arrival', 'ship', 'stock'].includes(rowTypeCode || '')) {
        if (obj.value === null || obj.value === undefined) {
          return '-'
        }

        return Math.round(obj.value).toString()
      }

      if (rowTypeCode === 'stockDays' || rowTypeCode === 'stockTotalDays') {
        if (obj.value === null || obj.value === undefined) {
          return '-'
        }

        if (obj.value <= 0) {
          return '0'
        }

        if (obj.value > 999) {
          return '999 +'
        }

        return obj.value.toFixed(1)
      }

      if (obj.value === null || obj.value === undefined) {
        return '-'
      }

      return obj.value.toString()
    },
    [unitType]
  )

  /**
   * セルのクラスを設定する
   * @param colEditable 編集可能列かどうか
   * @param rectype 日付種別（rec, today, expected）
   * @param obj セル情報
   * @param rowTypeCode 行種別（arrival, ship, stock, stockDays）
   *
   */
  const cellClass = ({
    colEditable,
    rectype,
    obj,
    rowTypeCode,
    rowId,
    field,
    node,
  }: {
    colEditable: boolean
    rectype: string
    obj: RenewalSimulatorCell
    rowTypeCode: string
    rowId: string
    field: string | undefined
    node: IRowNode<RenewalSimulatorRow>
  }) => {
    const classes = ['number-cell']

    if (['0_arrival'].includes(rowId)) {
      if (obj?.orderDate && systemDate && isSameDay(obj?.orderDate, systemDate)) {
        classes.push('todays-order-cell')
      }
    }

    if (['stock'].includes(rowTypeCode)) {
      if (!obj?.value || obj.value <= 0) {
        classes.push('negative-value')
      }
    }

    if (['stockTotal'].includes(rowTypeCode)) {
      if (!obj?.value || obj.value <= 0) {
        classes.push('negative-value')
      }
    }

    if (['stockDays'].includes(rowTypeCode)) {
      const stockDaysSetting = node.data?.stockDaysSetting || 0
      if (!obj?.value || obj.value <= stockDaysSetting) {
        classes.push('stock-days-warning')
      }
    }

    // 本日以降、かつ出荷数が0以上、かつ前日の在庫数よりも多い場合、セルを赤文字にする
    if (['ship'].includes(rowTypeCode) && field) {
      const RenewalSimulatorRow = getRowInGroup(node, 'stock')

      if (RenewalSimulatorRow) {
        if (obj?.value != null && obj.value > 0 && ['today', 'expected'].includes(rectype)) {
          const prevStockCell = RenewalSimulatorRow.data?.[
            beforeCol(field) as keyof RenewalSimulatorRow
          ] as RenewalSimulatorCell
          if (prevStockCell?.value != null && obj.value > prevStockCell.value) {
            classes.push('over-stock-value')
          }
        }
      }
    }

    if (['salesOrder'].includes(rowTypeCode) && field) {
      const shipRow = getRowInGroup(node, 'ship')
      const shipCell = shipRow?.data?.[field as keyof RenewalSimulatorRow] as RenewalSimulatorCell

      if (shipCell && obj.value) {
        if (shipCell?.enableUpdate && obj.value > (shipCell.value || 0)) {
          classes.push('over-order-value')
        }
      }
    }

    if (isCellEditable(colEditable, rowTypeCode, obj)) {
      classes.push('editable-cell')
    }

    if (obj && obj.isCurrentUpdate) {
      classes.push('current-updated-cell')
    }

    if (['0_arrival', '0_ship'].includes(rowId)) {
      if (obj && obj.isUpdate) {
        classes.push('updated-cell')
      }
    }
    if (rectype === 'today') {
      classes.push('today-cell')
    }

    if (['arrival'].includes(rowTypeCode)) {
      if (obj?.enableUpdate && obj.fixFlg) {
        classes.push('future-arrival-exists')
      }
    }

    return classes
  }

  /**
   * セルの値を変更した際の処理
   * セル情報をオブジェクトとして保持しているため定義が必要
   */
  const valueParser = useCallback((params: ValueParserParams) => {
    let { newValue }: any = params;

    if (newValue === null || newValue === undefined || newValue === '') {
      newValue = null;
    }

    if (params.data.rowid === '0_ship' || params.data.rowid === '0_arrival') {
      if (typeof newValue === 'string') {
        const parsedValue = parseFloat(newValue);
        newValue = Number.isNaN(parsedValue) ? null : Math.max(parsedValue, 0);
      } else if (typeof newValue === 'number') {
        newValue = Math.max(newValue, 0);
      } else {
        newValue = null;
      }
    } 
    
    else if (params.data.rowid === '0_olditemShipStopFlg') {
      if (typeof newValue === 'string') {
        const lowerValue = newValue.toLowerCase();
        if (lowerValue === 'true' || lowerValue === '1') {
          newValue = true;
        } else if (lowerValue === 'false' || lowerValue === '0') {
          newValue = false;
        } else {
          newValue = null;
        }
      } else {
        newValue = null;
      }
    } 
    
    else if (params.data.rowid === '0_remark') {
      if (typeof newValue !== 'string') {
        newValue = null;
      }
    }

    return {
      ...params.oldValue,
      value: newValue,
      isCurrentUpdate: true, 
    };
  }, []);


  /**
   * 日付カラムの表示名を取得する
   * @param day
   * @returns
   */
  function getheaderName(day: Daylist) {
    return day.datevalue ? format(day.datevalue, 'M/d(E)', { locale: ja }) : ''
  }

  /**
   * 日付カラムのカラム定義を生成する
   * @param rectype 日付種別（rec, today, expected）
   * @param colEditable 編集可能列かどうか
   * @returns
   */
  const genDayCols = (rectype: string, colEditable: boolean) => {
    if (!daylist) return []

    return daylist
      .filter((day: Daylist) => (day.rectype === rectype && day.renewalNumber !== 0))
      .sort((a: Daylist, b: Daylist) => (a.dateno || 0) - (b.dateno || 0))
      .map((day: Daylist, idx: number) => ({
        headerName: getheaderName(day),
        field: day.colname ? day.colname : undefined,
        width: 110,
        type: 'rightAligned',
        headerClass: (params: any) => {
          const classes = clsx(`value-header-${rectype}`, {
            sunday: day.datevalue?.getDay() === 0,
            newitem_start_header: day.newitemStartFlg,
          })
          return classes
        },
        editable: (params: EditableCallbackParams<RenewalSimulatorRow>) => {
          const obj = params.data?.[params.colDef.field as keyof RenewalSimulatorRow] as RenewalSimulatorCell
          return isCellEditable(colEditable, params.data?.rowType?.code, obj)
        },
        // cellRendererParams: (params: ICellRendererParams<RenewalSimulatorRow>) => {
        //   if (params.data?.rowType?.code === 'olditemShipStopFlg') {
        //     return
        //   }
        //   return undefined;
        // },
        // ValueParserService,
        // cellRendererParams: {
        //   // Ensure checkbox gets only the value property
        //   getValue: (params: ICellRendererParams) => params.value?.value ?? false
        // },
        cellRendererSelector: (params: ICellRendererParams<RenewalSimulatorRow>) => {
          if (params.data?.rowType?.code === 'olditemShipStopFlg') {
            return {
              component: 'agCheckboxCellRenderer',
              params: {
                checked: params.value?.value || false,
                value: params.value?.value || false,
                disabled: false,
              },
            }
          }
          return undefined
        },
        valueFormatter,
        cellClass: (params: CellClassParams<RenewalSimulatorRow>) =>
          cellClass({
            colEditable,
            rectype,
            obj: params.value,
            rowTypeCode: params.data?.rowType?.code || '',
            rowId: params.data?.rowid || '',
            field: params.colDef.field,
            node: params.node,
          }),
        valueParser,
        cellEditorSelector: (params: ICellEditorParams<RenewalSimulatorRow>) => {
          switch (params.data?.rowType?.code) {
            case ROW_TYPE_REMARK.code:
              return {
                component: 'agLargeTextCellEditor',
                popup: true,
                params: {
                  maxLength: 1000,
                  value: params.value?.value || '',
                },
              }

            case 'arrival':
            case 'ship':
              return {
                component: StockCellEditor,
              }
            case 'reason': {
              const values = reasonTypes?.map((r) => r.code) || []
              return {
                component: 'agRichSelectCellEditor',
                params: {
                  values: [null, ...values],
                  formatValue: (value: number) => {
                    const reason = reasonTypes.find((r) => r.code === value)
                    return reason ? reason.value : ''
                  },
                },
              }
            }
            default:
              return {
                component: StockCellEditor,
              }
          }
        },
      }))
  }

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
      headerName: '',
      width: 320,
      aggFunc: (params: IAggFuncParams<RenewalSimulatorRow>) => params.rowNode?.allLeafChildren[0]?.data?.itemName,
      cellRenderer: ItemNameRenderer,
      field: 'itemName',
      pinned: true,
      suppressHeaderMenuButton: false,
      menuTabs: ['filterMenuTab'],
      cellRendererParams: {
        companyId: null,
        disableLink: true,
      },
    },
    {
      headerName: '',
      pinned: true,
      showRowGroup: 'itemCode',
      field: 'rowType.name',
    },
    {
      field: 'itemCode',
      rowGroup: true,
      filter: 'agTextColumnFilter',
      menuTabs: ['filterMenuTab'],
      hide: true,
    },
    {
      groupId: HEADER_GROUP_REC,
      headerName: '実績',
      headerClass: 'value-header-rec',
      children: genDayCols('rec', false),
    },
    {
      groupId: HEADER_GROUP_TODAY,
      headerName: '当日',
      headerClass: 'value-header-today',
      children: genDayCols('today', true),
    },
    {
      groupId: HEADER_GROUP_EXPECTED,
      headerName: '予定',
      headerClass: 'value-header-expected',
      children: genDayCols('expected', true),
    },
  ]

  const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(updateColumnDefs, [daylist])

  // Define row class using params
  const getRowClass = (params: any) => {
    if (!params.data) {
      return ''
    }

    const { rowType } = params.data

    const boldRowTypes = [
      ROW_TYPE_ARRIVAL,
      ROW_TYPE_SHIP,
      ROW_TYPE_SALES_ORDER,
      ROW_TYPE_STOCK,
      ROW_TYPE_STOCK_DAYS,
      ROW_TYPE_QUANTITY_STOP_SHIPPING,
      ROW_TYPE_SHIP_EXCLUSIVE,
    ]
    if (boldRowTypes.includes(rowType)) {
      return 'font-style'
    }

    if (rowType === ROW_TYPE_STOCK_FOLLOW_BY_PRODUCT || rowType === ROW_TYPE_STOCK_PRODUCT) {
      return 'bold-border-cell'
    }
    return ''
  }

  /**
   * Grid上で扱いやすくするため、Stockデータの入荷数・出荷数・在庫数・在庫日数をそれぞれ行に分割する
   *
   * @param dataList 商品リスト（Stock）
   * @param dayList  日付リスト（Daylist）
   * @returns 入荷数・出荷数・在庫数・在庫日数をそれぞれ行に分割したデータ
   */
  const resultToRows = (
    dataList: Array<Stock>,
    dayList: Array<Daylist>,
    renewalList: Array<RenewalOrgItem>
  ): RenewalSimulatorRow[] => {
    const dataByGroup = _.groupBy(dataList, 'itemCode')
    const rows: RenewalSimulatorRow[] = []
    /*
     * データ行作成
     */
    _.forEach(dataByGroup, (stocks, key) => {
      const {
        companyId,
        centerId,
        orgItemCode,
        itemCode,
        itemName,
        janCase,
        expectedRank,
        orderUnitTypeName,
        caseLot,
        minOrderQuantity,
        stockDaysSetting,
        safetyStockSetting,
        minStockQuantity,
        startDate,
        stopShip,
        stopArrival,
        renewalFlg,
        renewalNumber,
      } = stocks[0]

      if (!companyId || !centerId || !itemCode || !orgItemCode) {
        return
      }

      const parentData = {
        companyId,
        centerId,
        orgItemCode,
        itemCode,
        itemName,
        janCase,
        expectedRank,
        orderUnitTypeName,
        caseLot,
        minOrderQuantity,
        stockDaysSetting,
        safetyStockSetting,
        minStockQuantity,
        startDate,
        stopShip,
        stopArrival,
        renewalFlg,
        renewalNumber,
        isTotal: false,
      }

      // ↓↓↓↓Create a unique row ID in rowid
      const arrivalRow: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_ARRIVAL,
        rowid: `${renewalNumber}_${ROW_TYPE_ARRIVAL.code}`,
      }

      const shipRow: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_SHIP,
        rowid: `${renewalNumber}_${ROW_TYPE_SHIP.code}`,
      }
      const salesOrderRow: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_SALES_ORDER,
        rowid: `${renewalNumber}_${ROW_TYPE_SALES_ORDER.code}`,
      }
      const stockRow: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_STOCK,
        rowid: `${renewalNumber}_${ROW_TYPE_STOCK.code}`,
      }
      const stockDaysRow: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_STOCK_DAYS,
        rowid: `${renewalNumber}_${ROW_TYPE_STOCK_DAYS.code}`,
      }

      const stockTotal: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_STOCK_FOLLOW_BY_PRODUCT,
        rowid: `${renewalNumber}_${ROW_TYPE_STOCK_FOLLOW_BY_PRODUCT.code}`,
      }
      const stockTotalDays: RenewalSimulatorRow = {
        ...parentData,
        rowType: ROW_TYPE_STOCK_PRODUCT,
        rowid: `${renewalNumber}_${ROW_TYPE_STOCK_PRODUCT.code}`,
      }

      const remarksRow: RenewalSimulatorRow = {
        ...parentData,
        itemCode: '',
        itemName: '備考',
        rowid: '0_remark',
        rowType: ROW_TYPE_REMARK,
      }

      dayList.forEach((day) => {
        if (day.datevalue === null) {
          return
        }

        const matchedData = stocks.find(
          (stock) => stock.recDate && day.datevalue && isSameDay(stock.recDate, day.datevalue)
        )

        if (day.dateno !== null) {
          if (matchedData && matchedData.recDate) {
            const commonColumn = {
              recDate: matchedData.recDate,
              orderDate: matchedData.orderDate,
              isCurrentUpdate: false,
              oldValue: null,
              enableData: false,

              // ↓↓↓↓Setting renewal numbers for each date
              renewalNumberDay: matchedData.renewalNumber,
              colName : day.colname,
            }
            arrivalRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.arrivalQuantity,
              enableUpdate: matchedData.enableUpdateArrival || false,
              isUpdate: matchedData.isUpdatedArrival || false,
              recType: day.rectype,
              fixFlg: matchedData.arrivalFixFlg || false,
            }

            shipRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.shipQuantity,
              enableUpdate: matchedData.enableUpdateShip || false,
              isUpdate: matchedData.isUpdatedShip || false,
              recType: day.rectype,
              enableData: matchedData.enableShip || false,
            }

            salesOrderRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.salesorderQuantity,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }

            stockRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.stockQuantity,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }
            stockDaysRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.stockDays,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }
            stockTotal[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.stockQuantity,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }

            stockTotalDays[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.stockQuantity,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }
            remarksRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData?.remarks || '',
              enableUpdate: true,
              isUpdate: !!matchedData?.remarks,
              isCurrentUpdate: false,
              enableData: true,
              recType: day.rectype,
            }
          } else {
            arrivalRow[`col${day.dateno}`] = null
            shipRow[`col${day.dateno}`] = null
            stockRow[`col${day.dateno}`] = null
            salesOrderRow[`col${day.dateno}`] = null
            stockDaysRow[`col${day.dateno}`] = null
            stockTotal[`col${day.dateno}`] = null
            stockTotalDays[`col${day.dateno}`] = null
            remarksRow[`col${day.dateno}`] = null
          }
        }
      })

      rows.push(arrivalRow)
      rows.push(shipRow)
      rows.push(salesOrderRow)
      rows.push(stockRow)
      rows.push(stockDaysRow)
      rows.push(stockTotal)
      rows.push(stockTotalDays)
      rows.push(remarksRow)

      // ForEach oldItem and newItem
      renewalList.forEach((renewal) => {
        const dataJson = RenewalOrgItemFromJSONTyped(renewal, false)
        const renewalRow = {
          ...parentData,
          startDate: dataJson.startDate,
          itemCode: dataJson?.orgItemCode,
          itemName: dataJson?.itemName,
          renewalNumber: dataJson?.renewalNumber,
        }
        const arrivalRenewalRow: RenewalSimulatorRow = {
          ...renewalRow,
          rowType: ROW_TYPE_ARRIVAL,
          rowid: `${dataJson.renewalNumber}_${ROW_TYPE_ARRIVAL.code}`,
        }

        const shipRenewalRow: RenewalSimulatorRow = {
          ...renewalRow,
          rowType: ROW_TYPE_SHIP,
          rowid: `${dataJson.renewalNumber}_${ROW_TYPE_SHIP.code}`,
        }

        const stockRenewalItemRow: RenewalSimulatorRow = {
          ...renewalRow,
          rowType: ROW_TYPE_STOCK,
          rowid: `${dataJson.renewalNumber}_${ROW_TYPE_STOCK.code}`,
        }
        const stopExclusive: RenewalSimulatorRow = {
          ...renewalRow,
          rowType: ROW_TYPE_SHIP_EXCLUSIVE,
          rowid: `${dataJson.renewalNumber}_${ROW_TYPE_SHIP_EXCLUSIVE.code}`,
        }
        const quantityStopShipping: RenewalSimulatorRow = {
          ...renewalRow,
          rowType: ROW_TYPE_QUANTITY_STOP_SHIPPING,
          rowid: `${dataJson.renewalNumber}_${ROW_TYPE_QUANTITY_STOP_SHIPPING.code}`,
        }

        dayList.forEach((day: any) => {
          if (day.datevalue === null) {
            return
          }
          const matchedData = stocks.find(
            (stock) => stock.recDate && day.datevalue && isSameDay(stock.recDate, day.datevalue)
          )

          const matchedDataOldItem = stocks.find(
            (stock) =>
              stock.recDate && day.datevalue && isSameDay(stock.recDate, day.datevalue) && stock.renewalNumber === 1
          )

          const matchedDataNewItem = stocks.find(
            (stock) =>
              stock.recDate && day.datevalue && isSameDay(stock.recDate, day.datevalue) && stock.renewalNumber === 2
          )

          if (day.dateno !== null) {
            // Old Item
            if (matchedData && matchedData.recDate) {
              const commonColumn = {
                recDate: matchedData.recDate,
                orderDate: matchedData.orderDate,
                isCurrentUpdate: false,
                oldValue: null,
                enableData: false,
                renewalNumberDay: dataJson.renewalNumber,
              }

              if (dataJson.renewalNumber === 1) {
                stopExclusive[`col${day.dateno}`] = {
                  ...commonColumn,
                  value: matchedData?.olditemShipStopFlg ?? false,
                  enableUpdate: true,
                  isUpdate: false,
                  recType: day.rectype,
                }

                quantityStopShipping[`col${day.dateno}`] = {
                  ...commonColumn,
                  value: matchedData?.olditemShipStopFlg ? 0 : matchedData.stockQuantity,
                  enableUpdate: false,
                  isUpdate: false,
                  recType: day.rectype,
                }
                // 旧商品の在庫数のデフォルトにitem_codeの在庫数を設定
                //  リニューアル画面は、新商品の適用前から表示されるので、初日は必ず旧商品が表示されている
                //  そのため、初日の旧商品の在庫数は、ItemCode単位の在庫数と一致する
                stockRenewalItemRow[`col${day.dateno}`] = {
                  ...commonColumn,
                  value: matchedData?.olditemShipStopFlg ? 0 : matchedData.stockQuantity,
                  enableUpdate: false,
                  isUpdate: false,
                  recType: day.rectype,
                }
              } else {
                // 新商品のデフォルト在庫数は０
                stockRenewalItemRow[`col${day.dateno}`] = {
                  ...commonColumn,
                  value: 0,
                  enableUpdate: false,
                  isUpdate: false,
                  recType: day.rectype,
                }                
              }
              // todo:以下で設定しているデフォルトが意味わからない件
              arrivalRenewalRow[`col${day.dateno}`] = {
                ...commonColumn,
                value:
                  dataJson.renewalNumber === 1
                    ? matchedDataOldItem?.arrivalQuantity
                    : matchedDataNewItem?.arrivalQuantity,
                enableUpdate: false,
                isUpdate: matchedData.isUpdatedArrival || false,
                recType: day.rectype,
                fixFlg: matchedData.arrivalFixFlg || false,
              }

              shipRenewalRow[`col${day.dateno}`] = {
                ...commonColumn,
                value: matchedData?.shipQuantity,
                enableUpdate: false,
                isUpdate: matchedData.isUpdatedShip || false,
                recType: day.rectype,
                enableData: matchedData.enableShip || false,
              }

            } else {
              arrivalRenewalRow[`col${day.dateno}`] = null
              shipRenewalRow[`col${day.dateno}`] = null
              stockRenewalItemRow[`col${day.dateno}`] = null
              stopExclusive[`col${day.dateno}`] = null
              quantityStopShipping[`col${day.dateno}`] = null
            }
          }
        })

        rows.push(arrivalRenewalRow)
        rows.push(shipRenewalRow)
        rows.push(stockRenewalItemRow)
        if (dataJson.renewalNumber === 1) {
          rows.push(stopExclusive)
          rows.push(quantityStopShipping)
        }
      })
    })

    return rows
  }

  const handleOldItemShipStopFlagChange = (params: CellValueChangedEvent<RenewalSimulatorRow>, gridApi: any) => {
    const { field } = params.colDef
    const oldValue = params.oldValue.value ?? false
    const newValue = params.newValue.value === undefined ? params.newValue : params.newValue.value
    if (!field) return
    if (newValue === false) {
      allNextCols(field).forEach((col) => {
        const stockNode = gridApi.getRowNode('1_stock')
        if (stockNode) {
          const colData = stockNode.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
          if (colData?.renewalNumberDay === 1)
            return
          const newStockValue = calcStock(stockNode, col)
          stockNode.setDataValue(col, newStockValue)
        }

        const shipNode = gridApi.getRowNode('1_ship')
        if (shipNode) {
          const newShip = setNewOrgItemShip(gridApi, col, '1_ship')
          shipNode.setDataValue(col, {
            ...((shipNode.data?.[col as keyof RenewalSimulatorRow] as object) || {}),
            value: newShip,
          })
        }
      })
    }
    if (newValue === true) {
      const allColumns = params.api.getColumns()
      if (!allColumns) return

      allColumns.forEach((column) => {
        const colId = column.getColId()
        const cellData = params.node.data?.[colId as keyof RenewalSimulatorRow] as RenewalSimulatorCell
        if (colId !== field && cellData?.value === true) {
          params.node.setDataValue(colId, {
            ...((params.node.data?.[colId as keyof RenewalSimulatorRow] as object) || {}),
            value: false,
            isCurrentUpdate: true,
          })
        }
      })

      params.node.setDataValue(field, {
        ...params.oldValue,
        value: true,
        recDate: params.oldValue?.recDate,
        orderDate: params.oldValue?.orderDate,
        enableData: true,
      })
      if (params.node.data) {
        gridApi.applyTransaction({
          update: [params.node.data],
        })
      }
    }

    const node = params.api.getRowNode(params.data.rowid)
    if (!node) return

    node.setDataValue(field, {
      ...params.oldValue,
      recDate: params.oldValue?.recDate,
      orderDate: params.oldValue?.orderDate,
      enableData: true,
    })

    if (newValue !== oldValue) {
      node.setDataValue(field, {
        ...params.oldValue,
        value: newValue,
        isCurrentUpdate: true,
        recDate: params.oldValue?.recDate,
        orderDate: params.oldValue?.orderDate,
        enableData: true,
      })
      if (node.data) {
        gridApi.applyTransaction({
          update: [node.data],
        })
      }
    }
  };

  const handleRemarkChange = (params: CellValueChangedEvent<RenewalSimulatorRow>, gridApi: any) => {
    const { field } = params.colDef
    if (!field) return

    const oldValue = params.oldValue?.oldValue ?? null;
    let newValue = null;
    
    if (params.newValue !== null && params.newValue !== undefined) {
      newValue = params.newValue.value !== undefined ? params.newValue.value : params.newValue;
    } else {
      newValue = null;
    }
    
    if (!field || oldValue === newValue) return;

    const node = params.api.getRowNode(params.data.rowid)
    if (!node) return

    node.setDataValue(field, {
      ...params.oldValue,
      recDate: params.oldValue?.recDate,
      orderDate: params.oldValue?.orderDate,
      enableData: true,
    })

    if (newValue !== oldValue) {
      node.setDataValue(field, {
        ...params.oldValue,
        value: newValue,
        isCurrentUpdate: true,
        recDate: params.oldValue?.recDate,
        orderDate: params.oldValue?.orderDate,
        enableData: true,
      })
    }
  };

  /**
   * セルの値を変更した際の処理
   *
   * 無限ループを避けるため、編集の可能性がある入出荷行のセルのみを対象とする
   * 編集にともない、在庫数・在庫日数の再計算を行う
   */

  const onCellValueChangedBase = useCallback(
    (params: CellValueChangedEvent<RenewalSimulatorRow>) => {
      const rowType = params.data?.rowType?.code
      const gridApi = gridRef.current?.api

      if (['1_olditemShipStopFlg'].includes(params.data.rowid)) {
        if (!gridApi) return
        handleOldItemShipStopFlagChange(params, gridApi)
      }
      if (['0_remark'].includes(params.data.rowid)) {
        handleRemarkChange(params, gridApi)
      }

      if (['0_arrival', '0_ship'].includes(params.data.rowid) && !params.data.isTotal) {
        if (!gridApi) return

        if (!params.node.id || !params.colDef.field) return
        const { field } = params.colDef

        const rowsInGroup = params.node.parent?.childrenAfterGroup
        if (!rowsInGroup) return

        const thisRow = rowsInGroup.find((row) => row.id === params.node.id)
        const thisRowNode = gridApi.getRowNode(thisRow?.id || '')
        if (!thisRowNode) return

        if (params.newValue?.value == null) {
          thisRowNode.setDataValue(field, {
            ...params.oldValue,
            value: 0,
            isCurrentUpdate: true,
          })
          return
        }

        const RenewalSimulatorRow = rowsInGroup.find((row) => row.data?.rowType?.code === 'stock')
        if (!RenewalSimulatorRow || !RenewalSimulatorRow.id) return

        const RenewalSimulatorRowNode = gridApi.getRowNode(RenewalSimulatorRow.id)
        if (!RenewalSimulatorRowNode || !RenewalSimulatorRowNode.data) return
        allNextCols(field).forEach((col) => {
          const colData = RenewalSimulatorRowNode.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
          if (colData?.renewalNumberDay === 0)
            return
          RenewalSimulatorRowNode.setDataValue(col, calcStock(params.node, col))
        })

        const stockDaysRow = rowsInGroup.find((row) => row.data?.rowType?.code === 'stockDays')
        if (!stockDaysRow || !stockDaysRow.id) return
        allNextCols(field).forEach((col) => {
          const colData = RenewalSimulatorRowNode.data?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
          if (colData?.renewalNumberDay === 0)
            return
          stockDaysRow.setDataValue(col, calcStockDays(params.node, col))
        })

        gridApi.refreshCells({
          rowNodes: [RenewalSimulatorRowNode],
          force: false,
        })

        // 入出荷値を変更した場合、他のセルの在庫アラート状態が変わる可能性があるため
        // shipのスタイルを再計算するための行リフレッシュを行う
        if (['0_arrival', '0_ship'].includes(params.data.rowid) && !params.data.isTotal) {
          const refreshTarget: IRowNode<RenewalSimulatorRow>[] = []
          if (rowType === 'arrival') {
            const shipRow = getRowInGroup(thisRowNode, 'ship')
            if (shipRow) {
              refreshTarget.push(shipRow)
            }
          } else {
            refreshTarget.push(thisRowNode)
          }
          gridApi.applyTransaction({
            update: refreshTarget
              .map((row) => {
                if (!row.data) return row.data as unknown as RenewalSimulatorRow
                const currentField = row.data[field as keyof RenewalSimulatorRow]
                return {
                  ...row.data,
                  [field]: {
                    ...((currentField as object) || {}),
                    isCurrentUpdate: true,
                  },
                } as RenewalSimulatorRow
              })
              .filter((data): data is RenewalSimulatorRow => data !== undefined),
          })
        }

        if (rowType !== 'ship') {
          setRowData((prevRowData) =>
            prevRowData.map((row) => {
              if (
                row.itemCode === params?.node?.data?.orgItemCode &&
                row.rowid === '2_arrival' &&
                params.colDef.field
              ) {
                return { ...row, [params.colDef.field]: params.newValue }
              }
              return row
            })
          )
        } else {
          setRowData((prevRowData) =>
            prevRowData.map((row) => {
              if (row.itemCode === params?.node?.data?.orgItemCode && row.rowid === '2_ship' && params.colDef.field) {
                return { ...row, [params.colDef.field]: params.newValue }
              }
              return row
            })
          )
        }
      }
    },
    [daylist]
  )
  /**
   * 編集されたセルのデータ一覧を取得する
   * @returns
   */
  const onCellValueChanged = useDebounce(onCellValueChangedBase, 150)
  function getEditedCells(): StockUpdateBase[] {
    const gridApi = gridRef.current?.api
    if (!gridApi) return []

    const rowNodes: IRowNode<RenewalSimulatorRow>[] = []
    gridApi.forEachNode((node) => {
      if (
        node &&
        ['arrival', 'ship', 'remark'].includes(node.data?.rowType?.code || '') &&
        (node.data?.renewalNumber == null || node.data?.renewalNumber === 0)
      ) {
        rowNodes.push(node)
      }
      if (
        node &&
        ['olditemShipStopFlg'].includes(node.data?.rowType?.code || '') &&
        (node.data?.renewalNumber == null || node.data?.renewalNumber === 1)
      ) {
        rowNodes.push(node)
      }
    })

    const dataList: StockUpdateBase[] = []
    const allCols = allNextCols(firstColName())

    // 日付カラムの全てのセル情報を取得
    const allCells = rowNodes
      .filter((node) => !!node.data)
      .map((node) => {
        return allCols.map((col) => {
          const cellData = node.data![col as keyof RenewalSimulatorRow] as RenewalSimulatorCell

          return {
            ...cellData,
            companyId: node.data!.companyId,
            centerId: node.data!.centerId,
            itemCode: node.data!.itemCode,
            rowTypeCode: node.data?.rowType?.code,
            // Ensure recDate is carried forward
          }
        })
      })
      .flat()

    // 今回変更された内容をセルから取得し、リクエスト用のデータに変換する（セル単位からレコード単位に変換）
    const groupedData = allCells.reduce(
      (acc, cellData) => {
        if (cellData) {
          const key = `${cellData.recDate}_${cellData.orderDate}`
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(cellData)
        }
        return acc
      },
      {} as Record<string, any[]>
    )

    Object.values(groupedData).forEach((cellGroup) => {
      const baseData = {
        companyId: cellGroup[0].companyId,
        centerId: cellGroup[0].centerId,
        itemCode: itemCodeProps,
        recDate: cellGroup[0].recDate,
        orderDate: cellGroup[0].orderDate,
        shipExclusionFlg: null,
      }

      const existedData = dataList.find(
        (d) =>
          d.companyId === baseData.companyId &&
          d.centerId === baseData.centerId &&
          d.itemCode === baseData.itemCode &&
          isSameDay(d.recDate, baseData.recDate)
      )

      if (existedData) {
        cellGroup.forEach((cellData) => {
          if (cellData.isCurrentUpdate) {
            switch (cellData.rowTypeCode) {
              case 'arrival':
                existedData.arrivalQuantityPlan = cellData.value
                break
              case 'ship':
                existedData.shipQuantityPlan = cellData.value
                break
              case 'remark':
                existedData.remarks = cellData.value
                break
              case 'olditemShipStopFlg':
                existedData.olditemShipStopFlg = cellData.value
                break
              default:
                break
            }
          }
        })
      } else {
        const editedCells = cellGroup.filter(c => c.isCurrentUpdate);

        if (editedCells.length > 0) {
          const newData = {
            ...baseData,
            remarks: cellGroup.find(c => c.rowTypeCode === 'remark')?.value || '',
            arrivalQuantityPlan: editedCells.find(c => c.rowTypeCode === 'arrival')?.value,
            shipQuantityPlan: editedCells.find(c => c.rowTypeCode === 'ship')?.value,
            olditemShipStopFlg: cellGroup.find(c => c.rowTypeCode === 'olditemShipStopFlg')?.value,
          }

          if (Object.values(newData).some(value => value !== undefined)) {
            dataList.push(newData)
          }
        }
      }
    })

    const findSameRecord = (sub: StockUpdateBase, rowTypeCode: string, hasUpdateFlg: boolean) => {
      return allCells.find((c) => {
        const isSameDate =
          c.recDate && sub.recDate ? isSameDay(c.recDate, sub.recDate) : false;
    
        return (
          c.companyId === sub.companyId &&
          c.centerId === sub.centerId &&
          c.itemCode === sub.itemCode &&
          isSameDate &&
          c.rowTypeCode === rowTypeCode &&
          (hasUpdateFlg ? c.isUpdate : true)
        );
      });
    }

    // リクエスト内にすでに更新された内容が含まれない場合その内容が失われてしまうため、
    // 既存の更新内容が存在すればそれをセットする
    const requestDataList = dataList.map((dt) => {
      const d = { ...dt }
      if (d.arrivalQuantityPlan === undefined) {
        const updatedCell = findSameRecord(d, 'arrival', true)
        if (updatedCell) {
          d.arrivalQuantityPlan = updatedCell.value
        }
      }

      if (d.shipQuantityPlan === undefined) {
        const updatedCell = findSameRecord(d, 'ship', true)
        if (updatedCell) {
          d.shipQuantityPlan = updatedCell.value
        }
      }

      if (d.remarks === undefined) {
        const updatedCell = findSameRecord(d, 'remark', true)
        if (updatedCell) {
          d.remarks = updatedCell.value
        }
      }

      if (d.olditemShipStopFlg === undefined) {
        const updatedCell = findSameRecord(d, 'olditemShipStopFlg', true)
        if (updatedCell) {
          d.olditemShipStopFlg = updatedCell.value
          // d.recDate = updatedCell.recDate
          // d.orderDate = updatedCell.orderDate
        }
      }
      return d
    })
    // 自動的にUTC認識されて日付がずれるため、共用関数convertDateToRequestParamで調整する。
    return requestDataList.map((d) => ({
      ...d,
      recDate: d.recDate ? convertDateToRequestParam(d.recDate) : d.recDate,
      orderDate: d.orderDate ? convertDateToRequestParam(d.orderDate) : d.orderDate,
    }))
  }

  const updateRenewalSimulator = async () => {
    const datalist = getEditedCells()
    if (datalist.length === 0) {
      setUpdateAccept(false)
      return
    }

    setUpdating(true)

    const api = await getAPI()
    if (!api) return
    try {
      const response = await api.stockUpdateStockUpdatePost({
        stockUpdate: {
          body: datalist,
        },
      })

      if (response.result.status === 'error') {
        setSnackBarMessage(response.errors[0]?.errorMessage || 'エラーが発生しました')
        setSnackBarSeverity('error')
        setSnackBarOpen(true)
        setUpdateAccept(false)
        return
      }

      if (onDialogClose) {
        onDialogClose()
        setUpdateAccept(false)
      }
    } catch (e) {
      setSnackBarMessage('入荷数修正でエラーが発生しました。')
      setSnackBarSeverity('error')
      setSnackBarOpen(true)
      setUpdateAccept(false)
      return
    } finally {
      setUpdating(false)
    }
  }

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  useEffect(() => {
    if (updateAccept) {
      updateRenewalSimulator()
    }
  }, [updateAccept])

  /**
   * グリッドにデータが格納され準備ができた状態で呼び出すイベント
   * 以下の処理を行う
   *
   * - 在庫の計算
   * - 在庫日数の計算
   *
   * @returns
   */
  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    calcAllAfterDays(firstColName())

  }

  useEffect(() => {
    if (rowData.length > 0) {
      onModelUpdated()
    }
  }, [rowData])

  // using effect prevent jumping to today Column after edit
  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (hasRunRef.current) return
    if (!gridApi || !rowData?.length) return

    gridApi.forEachNode((node) => {
      allNextCols(firstColName()).forEach((col) => {
        const dt = rowData[0]
        const dtNode = dt?.[col as keyof RenewalSimulatorRow] as RenewalSimulatorCell
        if (col && node && dtNode.recType === 'today') {
          gridApi.ensureColumnVisible(col, 'middle')
        }
      })
    })

    hasRunRef.current = true
  }, [rowData])

  /**
   * 再検索等でデータが更新されたタイミングで呼び出すイベント
   * 以下の処理を行う
   *
   * - 日付リストから絡む定義を作成
   * - グリッド用のデータ加工
   *
   */
  useEffect(() => {
    setDaylist(stockData.daylist)
    setRowData(resultToRows(stockData.list, stockData.daylist, stockData.renewalOrgItemList!))
  }, [stockData])

  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    if (isLoading || updating) {
      gridApi.showLoadingOverlay()
    } else {
      gridApi.hideOverlay()
    }
  }, [isLoading, updating])

  return (
    <div style={containerStyle}>
      <div style={{ height: '100%', boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-quartz">
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            getRowId={(params) => params.data.rowid}
            columnDefs={columnDefs}
            localeText={localeText}
            rowData={rowData}
            getRowClass={getRowClass}
            groupDisplayType="custom"
            groupDefaultExpanded={1}
            suppressAggFuncInHeader
            rowHeight={36}
            reactiveCustomComponents
            onCellValueChanged={onCellValueChanged}
            onModelUpdated={onModelUpdated}
            loadingOverlayComponent={LoadingOverlay}
            enableRangeSelection
          />
        </div>
      </div>
      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </div>
  )
}

export default ItemRenewalSimulator
