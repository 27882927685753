import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'

import type { ColDef, ColGroupDef, ExcelDataType, GridApi, ValueFormatterParams } from 'ag-grid-community'
// import exp from 'constants'
// import type { MstItem } from 'api-client'

export const excelStyles = [
  {
      id: 'numberType',
      numberFormat: {
      format: '#,##0' 
      }
  },
  {
      id: 'decimalType',
      numberFormat: {
      format: '#,##0.000' 
      }
  },    
  {
      id: 'booleanType',
      dataType: 'Boolean' as ExcelDataType,  
  },
  {
      id: 'stringType',
      dataType: 'String' as ExcelDataType,
  },
  {
      id: 'dateType',
      dataType: 'DateTime' as ExcelDataType,
      numberFormat: {
          format: 'yyy-mm-ddThh:mm:ss'
      }
  },
  // {
  //     id: 'dateTimeType',
  //     dataType: 'DateTime' as ExcelDataType,
  //     numberFormat: {
  //         format: 'yyy-mm-ddThh:mm:ss'
  //     }
  // },    
  // {
  //     id: 'timeType',
  //     dataType: 'DateTime' as ExcelDataType,
  //     numberFormat: {
  //     format: 'hh:mm'
  //     }
  // },
]

interface FileDownloadDataProps {
  fileName: string
  downloadData: any[]
  downloadMode: string
  columnDefs?: (ColDef | ColGroupDef)[]
}

function FileDownloadData({ fileName, downloadData, downloadMode, columnDefs }: FileDownloadDataProps) {

  const [rowData, setRowData] = useState<any[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {

      const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName:dlfileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
      }

    }
  }
  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={excelStyles}
      />
    </div>
  )
}
export default FileDownloadData