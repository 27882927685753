import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AuthType } from 'constants/constants'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SupplierDetail, UploadButton } from 'components/commonparts'
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar'
import { SearchCondition } from 'components/parts/StockCenter'
import { DownloadGrid } from 'components/parts/StockCenter/StockGrid/DownloadGrid'
import { StockGrid } from 'components/parts/StockCenter/StockGrid/StockGrid'
import { format, isSameDay } from 'date-fns'
import { useStockData } from 'hooks/useStockData'
import { useStockFile } from 'hooks/useStockFile'
import { useSearchParamStore } from 'store/searchParamStore'
import { getInitCompanyId } from 'utils/getInitParam'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'

import type { GridApi } from 'ag-grid-community'
import type { GetStockItemTreeStockItemTreeGetRequest, MstSupplier, ResponseResult, Stock } from 'api-client'
import type { StockCell, StockCellWithPk, StockRow } from 'components/parts/StockCenter/StockGrid/table-model'

function StockCenter() {
  const { loginUserInfo } = useAuthContext()

  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
    centerId: Number(query.get('center_id')),
    orgSupplierId: query.get('org_supplier_id'),
    viewUnitType: query.get('view_unit_type'),
    optimizeRange: query.get('optimize_range'),
  }

  // 自社の検索条件をローカルストレージから取得する
  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCompanyId = localSearchParam?.stockCenter.companyId || undefined
  const localCenterId = localSearchParam?.stockCenter.centerId || undefined
  const localViewUnitType = localSearchParam?.stockCenter.viewUnitType || 'case'
  const localOrgSupplierId = localSearchParam?.stockCenter.orgSupplierId || ''
  const localOptimizeRange = localSearchParam?.stockCenter.optimizeRange || '14'

  const initSearchParam: GetStockItemTreeStockItemTreeGetRequest = {
    // ユーザーの企業ID
    companyId: getInitCompanyId(trQueryParam.companyId, localCompanyId, loginUserInfo ? loginUserInfo.companyId : 0), //  ストアのセンター情報
    //  ストアのセンター情報
    centerId: trQueryParam.centerId ? trQueryParam.centerId : localCenterId,
    viewUnitType: trQueryParam.viewUnitType ? trQueryParam.viewUnitType : localViewUnitType,
    orgSupplierId: trQueryParam.orgSupplierId ? trQueryParam.orgSupplierId : localOrgSupplierId,
    optimizeRange: trQueryParam.optimizeRange ? trQueryParam.optimizeRange : localOptimizeRange,
  }

  const { result, stockData, fetchStockData, isLoading } = useStockData()
  // const { fetchStockCsvDownload, isCsvLoading, fetchStockXlsxDownload, isXlsxLoading } = useStockFileDownload()
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>(false)
  const [isXlsxLoading, setIsXlsxLoading] = useState<boolean>(false)
  const { fetchStockUpload, isLoading: stockUploadIsLoading } = useStockFile()

  const [searchParam, setSearchParam] = useState<GetStockItemTreeStockItemTreeGetRequest>(initSearchParam)
  const [supplierInfo, setSupplierInfo] = useState<MstSupplier | undefined>(undefined)
  const [trCompanyId, setTrCompanyId] = useState<number>(
    getInitCompanyId(trQueryParam.companyId, localCompanyId, loginUserInfo ? loginUserInfo.companyId : 0)
  )
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [uploadResultMessage, setUploadResultMessage] = useState<ResponseResult | undefined>(undefined)

  // StockGridが1箇所でも編集されたかどうか
  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  // StockGridのデータ
  const [stockRows, setStockRows] = useState<StockRow[]>([])
  // ダウンロード専用Gridのデータ
  const [downloadGridRows, setDownloadGridRows] = useState<Stock[]>([])
  const [downloadMode, setDownloadMode] = useState<'csv' | 'xlsx'>('csv')
  const [forbiddenDownloadDialogOpen, setForbiddenDownloadDialogOpen] = useState<boolean>(false)
  const tableEditable = useMemo(
    (): boolean =>
      !!loginUserInfo && loginUserInfo.companyId === trCompanyId && loginUserInfo?.authType !== AuthType.ReadOnly,
    [trCompanyId]
  )

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const navigate = useNavigate()

  const onSearch = (requestParam: GetStockItemTreeStockItemTreeGetRequest) => {
    fetchStockData({
      ...requestParam,
    })

    setSearchParam(requestParam)

    // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
    if (
      loginUserInfo &&
      requestParam.companyId === loginUserInfo.companyId &&
      loginUserInfo?.tenantId &&
      loginUserInfo?.companyId
    ) {
      setSearchParamState<GetStockItemTreeStockItemTreeGetRequest>(
        loginUserInfo?.tenantId,
        loginUserInfo?.companyId,
        'StockCenter',
        {
          ...requestParam,
        }
      )
    }

    setTrCompanyId(requestParam.companyId)

    // 検索実行時に検索条件をクエリパラメータとして付与する
    navigate(
      `?company_id=${requestParam.companyId}&center_id=${requestParam.centerId}&org_supplier_id=${requestParam.orgSupplierId}&view_unit_type=${requestParam.viewUnitType}&optimize_range=${requestParam.optimizeRange}`
    )

    setHasGridEdited(false)
  }

  const matchCondition = (c: StockCellWithPk, stock: Stock, type: 'stock' | 'stockDays'): boolean =>
    c.companyId === stock.companyId &&
    c.centerId === stock.centerId &&
    c.itemCode === stock.itemCode &&
    c.rowType.code === type &&
    !!stock.recDate &&
    isSameDay(c.recDate, stock.recDate)

  const generateDownloadData = (): Stock[] => {
    if (!stockData?.list) {
      return []
    }
    // 最も古い日付から3週間分（21日分）を対象とする
    const dayCols: string[] = stockData.daylist
      .sort((da, db) => (da.datevalue?.getTime() || 0) - (db.datevalue?.getTime() || 0))
      .map((d) => d.colname || '')
      .slice(0, 21)

    const filteredStockRows = stockRows.filter(
      (row) => !row.isTotal && ['stock', 'stockDays'].includes(row.rowType?.code ?? '')
    )

    const targetCells: StockCellWithPk[] = []
    filteredStockRows.forEach((row) => {
      dayCols.forEach((dayCol) => {
        const cell = row[dayCol as keyof StockRow] as StockCell
        targetCells.push({
          companyId: row.companyId,
          centerId: row.centerId,
          itemCode: row.itemCode!,
          rowType: row.rowType!,
          ...cell,
        })
      })
    })

    const downloadData: Stock[] = []

    stockData.list.forEach((stock) => {
      const stockCell = targetCells.find((c) => matchCondition(c, stock, 'stock'))
      const stockDaysCell = targetCells.find((c) => matchCondition(c, stock, 'stockDays'))

      if (stockCell && stockDaysCell) {
        downloadData.push({
          ...stock,
          stockQuantity: stockCell.value,
          stockDays: stockDaysCell.value,
        })
      }
    })

    return downloadData
  }

  /**
   * フロント側でCSVファイルを作成しダウンロードする
   *
   * AgGridのExport機能を利用したいため、ダウンロードしたい形式と同じ形の専用Gridを作成し、そのAPIからExportを実行する。
   * このGridはダウンロード機能にのみ使用するため画面上には表示しない。
   *
   * fetchStockDataの結果（元データ）に対して、StockGridで計算された在庫・在庫日数を反映したデータを用いる。（generateDownloadData）
   *
   * グリッドにデータが反映された後にダウンロードを実行するため、実際のダウンロード実行はデータセット後のonModelUpdatedイベント経由で行う。
   */
  const onCsvDownLoad = () => {
    if (hasGridEdited) {
      setForbiddenDownloadDialogOpen(true)
      return
    }
    setResultMessage(undefined)
    setIsCsvLoading(true)
    setDownloadMode('csv')
    setDownloadGridRows(generateDownloadData())
  }

  const onExcelDownLoad = () => {
    if (hasGridEdited) {
      setForbiddenDownloadDialogOpen(true)
      return
    }
    setIsXlsxLoading(true)
    setResultMessage(undefined)
    setDownloadMode('xlsx')
    setDownloadGridRows(generateDownloadData())
  }
  const onStockFileUpload = async (file: File) => {
    try {
      const response = await fetchStockUpload({ postedFile: file })
      if (response?.result.status === 'success') {
        // 検索API実行
        fetchStockData({
          ...searchParam,
        })
      } else {
        setSnackBarMessage(response?.errors[0]?.errorMessage || 'エラーが発生しました')
        setSnackBarOpen(true)
      }
      setUploadResultMessage(response?.result)
    } catch (e) {
      setUploadResultMessage({
        status: 'error',
        message: 'ファイルアップロードでエラーが発生しました。',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    }
  }

  useEffect(() => {
    // クエリパラメータがある場合は初回レンダリング時に検索を実行する
    if (
      (trQueryParam.companyId && trQueryParam.centerId && trQueryParam.orgSupplierId) ||
      (localCenterId && localOrgSupplierId)
    )
      fetchStockData(searchParam)

    // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
    if (
      loginUserInfo &&
      searchParam.companyId === loginUserInfo.companyId &&
      loginUserInfo?.tenantId &&
      loginUserInfo?.companyId
    ) {
      setSearchParamState<GetStockItemTreeStockItemTreeGetRequest>(
        loginUserInfo?.tenantId,
        loginUserInfo?.companyId,
        'StockCenter',
        {
          ...searchParam,
        }
      )
    }
  }, [])

  // 検索メッセージ
  useEffect(() => {
    setResultMessage(result)
  }, [result])

  const onReload = () => {
    fetchStockData({
      ...searchParam,
    })
    setHasGridEdited(false)
  }

  const onStockGridChange = (edited: boolean, stockRowsFromGrid: StockRow[]) => {
    setHasGridEdited(edited)
    setStockRows(stockRowsFromGrid)
  }

  const onDownloadDataApply = (gridApi: GridApi | undefined) => {
    if (gridApi) {
      const fileName = `倉庫別発注商品一覧_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName, sheetName: 'データ' })
      }
    }
    setIsCsvLoading(false)
    setIsXlsxLoading(false)
  }

  const handleForbiddenDownloadDialogClose = () => {
    setForbiddenDownloadDialogOpen(false)
  }

  const gridUnitType = useMemo(() => {
    if (searchParam.viewUnitType === 'pallet') {
      return 'pallet'
    }

    return 'case'
  }, [searchParam.viewUnitType])

  const gridOptimizeRange = useMemo(() => {
    if (searchParam.optimizeRange) {
      return searchParam.optimizeRange 
    }
    return undefined;
  }, [searchParam.optimizeRange])

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        倉庫別発注商品一覧
      </Typography>
      <SearchCondition
        searchParam={initSearchParam}
        onSearch={onSearch}
        setSupplierInfo={setSupplierInfo}
        onCsvDownLoad={onCsvDownLoad}
        onExcelDownLoad={onExcelDownLoad}
        isCsvLoading={isCsvLoading}
        isXlsxLoading={isXlsxLoading}
        result={resultMessage}
        hasGridEdited={hasGridEdited}
      />
      <SupplierDetail supplier={supplierInfo} />
      {/* <DataList list={stockItemTree} isLoading={isLoading} /> */}

      <StockGrid
        trCompanyId={trCompanyId}
        data={stockData}
        isLoading={isLoading}
        tableEditable={tableEditable}
        optimizeRange={gridOptimizeRange}
        unitType={gridUnitType}
        onChange={onStockGridChange}
        onReload={onReload}
      />

      <DownloadGrid downloadData={downloadGridRows} onApply={onDownloadDataApply} />

      {/* 自社以外または権限区分が「0:閲覧のみ」以外は表示 */}
      {loginUserInfo && (loginUserInfo.companyId !== trCompanyId || loginUserInfo?.authType !== AuthType.ReadOnly) && (
        <Grid direction="row" container justifyContent="space-between">
          <Grid item>
            <UploadButton
              onClick={onStockFileUpload}
              isLoading={stockUploadIsLoading}
              inputWidth="20rem"
              placeholder="ファイルをアップロードして修正"
              result={uploadResultMessage}
            />
          </Grid>
        </Grid>
      )}
      <Dialog
        open={forbiddenDownloadDialogOpen}
        onClose={handleForbiddenDownloadDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ダウンロード</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            データを編集中はダウンロードできません。内容をクリアするか反映してから実行してください
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForbiddenDownloadDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <AppSnackbar message={snackBarMessage} open={snackBarOpen} onClose={onSnackbarClose} severity="error" />
    </Box>
  )
}

export default StockCenter
