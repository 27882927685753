/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCustomerCenter
 */
export interface MstCustomerCenter {
    /**
     * 
     * @type {number}
     * @memberof MstCustomerCenter
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerCenter
     */
    orgCustomerId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerCenter
     */
    orgCustomerCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerCenter
     */
    customerCenterName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerCenter
     */
    customerCenterAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCustomerCenter
     */
    customerCenterGln: string | null;
}

/**
 * Check if a given object implements the MstCustomerCenter interface.
 */
export function instanceOfMstCustomerCenter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgCustomerId" in value;
    isInstance = isInstance && "orgCustomerCenterId" in value;
    isInstance = isInstance && "customerCenterName" in value;
    isInstance = isInstance && "customerCenterAddress" in value;
    isInstance = isInstance && "customerCenterGln" in value;

    return isInstance;
}

export function MstCustomerCenterFromJSON(json: any): MstCustomerCenter {
    return MstCustomerCenterFromJSONTyped(json, false);
}

export function MstCustomerCenterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCustomerCenter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'orgCustomerId': json['org_customer_id'],
        'orgCustomerCenterId': json['org_customer_center_id'],
        'customerCenterName': json['customer_center_name'],
        'customerCenterAddress': json['customer_center_address'],
        'customerCenterGln': json['customer_center_gln'],
    };
}

export function MstCustomerCenterToJSON(value?: MstCustomerCenter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'org_customer_id': value.orgCustomerId,
        'org_customer_center_id': value.orgCustomerCenterId,
        'customer_center_name': value.customerCenterName,
        'customer_center_address': value.customerCenterAddress,
        'customer_center_gln': value.customerCenterGln,
    };
}

