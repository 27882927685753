import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetMstItemsItemGetRequest, Item, MstItem, MasterItemUpdateMasterItemUpdatePostRequest, ResponseResult, ResponseUpdate } from 'api-client' 
import type { ErrorResponse } from 'types/types'

export interface MstItemSeq extends MstItem{
  id: string
}

export interface MstItemsSeq extends Item{
  list: MstItemSeq[]
}

export const useItem = () => {
  const [items, setItems] = useState<MstItemsSeq>(initRequestParam)
  const [findItem, setFindItem] = useState<Item>(initRequestParam)
  const [isLoading, setIsLoading] = useState(false)
  const [isFindItemLoading, setIsFindItemLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult>()
  const [findItemresult, setFindItemResult] = useState<ResponseResult>()
  const [mstItemUpdateResult, setMstItemUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const [isUpdating, setIsUpdating] = useState(false)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchItems = (searchParam: GetMstItemsItemGetRequest) => {
    setItems(initRequestParam)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstItemsItemGet({
          ...searchParam,
          itemName: searchParam.itemName || undefined,
          orgItemCode: searchParam.orgItemCode || undefined,
          orgSupplierId: searchParam.orgSupplierId || undefined,
          itemCode: searchParam.itemCode || undefined,
          barcode: searchParam.barcode || undefined,
        })
      })
      .then((response) => {
        setItems({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            id: Math.random().toString(32).substring(2),
          }))
        })
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // 一つの商品を取得したい場合に使用
  const fetchFindItem = (searchParam: GetMstItemsItemGetRequest) => {
    setFindItem(initRequestParam)
    setFindItemResult(undefined)
    setIsFindItemLoading(true)
    setResult(undefined)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstItemsItemGet({
          companyId: searchParam.companyId,
          itemCode: searchParam.itemCode,
          orgItemCode: searchParam.orgItemCode
        })
      })
      .then((response) => {
        setFindItem(response.data)
        setFindItemResult(response.result)
      })
      .catch(({ response }: ErrorResponse) => {
        setFindItemResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsFindItemLoading(false)
      })
  }

    // 製品のアップデートに使用されます
    const fetchUpdateItem = async (body: MasterItemUpdateMasterItemUpdatePostRequest): Promise<ResponseUpdate> => {
      setIsUpdating(true);
      setMstItemUpdateResult(undefined)
      setResult(undefined)
      try {
        const token = await auth.currentUser?.getIdToken(true);
        if (!token) throw new Error('Token not found');
        
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const api = new DefaultApi(conf);
        const response = await api.masterItemUpdateMasterItemUpdatePost(body);
        
        setResult(response.result)
        setMstItemUpdateResult(response);
        
        if (response.result.status !== 'success') {
          const error = new Error(response.result.message || '入力エラーがあります');
          Object.assign(error, {
              ...response
          });
          throw error;
        }
        return response;
      } catch (error: unknown) {
        if (error && typeof error === 'object' && 'result' in error) {
            const apiResponse = error as ResponseUpdate; 
            await new Promise<void>(resolve => {
              setMstItemUpdateResult(apiResponse);
              resolve();
            });
            return await Promise.reject(apiResponse);
        }
        const { response } = error as any;
        const errorResponse = {
            errors: [],
            result: {
                status: 'error',
                message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
                systemDate: null,
                pageNo: 0,
                systemInfo: null,
            },
            updateCount: null,
        };
        await new Promise<void>(resolve => {
            setMstItemUpdateResult(errorResponse);
            setResult(errorResponse.result)
            resolve();
        });
        return await Promise.reject(errorResponse);
      } finally {
        setIsUpdating(false);
      }
    };

  return {
    result,
    items,
    fetchItems,
    isLoading,

    findItem,
    fetchFindItem,
    findItemresult,
    isFindItemLoading,

    fetchUpdateItem,
    mstItemUpdateResult,
    isUpdating
  }
}
