import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAuthContext } from 'AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import { LoadingOverlay } from 'components/commonparts/index'
import { useCalendar } from 'hooks/useCalendar';
import { useGridCommon } from 'hooks/useGridCommon'
import { initRecordEditEx, type DataListPropsBase, type RecordEditEx } from 'types/dataGridTypes'
import { convertDateToRequestParam, isEqualDate } from 'utils/convertFromLocalDate'
import { validateRequired } from 'utils/validation'

import type { ColDef, ValueFormatterParams,ValueParserParams,IRichCellEditorParams } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import type { Calendar, MstCalendar,  GetMstCalendarCalendarGetRequest, MstCenter } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
  "org_center_id": "orgCenterId",
  "calendar_date" : "calendarDate",
  "enable_ship" : "enableShip",
  "enable_order" : "enableOrder",
  "enable_arrival" : "enableArrival",
  "arrival_ratio" : "arrivalRatio",
  "delete_flg": "deleteFlg"
};

const primayKey = ['orgCenterId', 'calendarDate']

interface DataListProps extends DataListPropsBase {
  list: Calendar
  onReload: () => void
  searchParam: GetMstCalendarCalendarGetRequest
  center: MstCenter[]
}

interface MstCalendarWithId extends MstCalendar, RecordEditEx {}

function DataList({ 
  list, 
  isLoading, 
  searchParam, 
  center,
  onReload, 
  updateTrigger,
  onUploadData,
  setHasGridEdited,
  setResultMessage,
  setDownloadColumnDefs,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {fetchCalendarUpdate, result, isUpdating} = useCalendar()
  const gridRef = useRef<AgGridReact<MstCalendarWithId>>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [editorDataReady, setEditorDataReady] = useState(false);
  const [row, setRow] = useState<MstCalendar[]>(
    list.list.map((item) => ({ ...item, ...initRecordEditEx() }))
  )
  const [centerSelect, setCenterSelect] = useState<(string | null)[]>([])

  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    if (centerSelect.length > 0 && center) {
      setEditorDataReady(true);
    }
  }, [centerSelect, center]);
  useEffect(() => {
    // console.debug("useEffect row", row)
    setEditorDataReady(false)
    const values = center?.map((r) => r.orgCenterId) || []
    setCenterSelect([...values])
  }, [center]);

  const createBlankRow = useCallback((): MstCalendarWithId => {
    const currentCenter = center?.find(centerExist => centerExist.orgCenterId === searchParam.orgCenterId);
    const currentOrgCenterId = currentCenter?.orgCenterId || null;
  
    return {
      companyId: loginUserInfo?.companyId || null,
      orgCenterId: currentOrgCenterId,
      calendarDate: null,
      enableArrival: false,
      enableOrder: false,
      enableShip: false,
      deleteDatetime: null,
      updateUser: null,
      deleteUser: null,
      insertDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      updateDatetime: null,
      arrivalRatio: null,
      deleteFlg: false,
      ...initRecordEditEx(true)
    };
  }, [searchParam, center, loginUserInfo?.companyId, loginUserInfo?.userId]);
  
  // 入力チェック
  const fieldValidate = (pRow: MstCalendarWithId, field: string | undefined, newValue: any): boolean => {
    let hasErrors = false
    switch (field){
      case "orgCenterId":
      case "calendarDate":
        hasErrors = checkAndSetError(pRow, field, validateRequired(newValue));
        if (!hasErrors) {
          hasErrors = duplicateCheck(pRow, newValue)
        }
        break;
      // case "enableShip":
      // case "enableArrival":
      // case "enableOrder":
      //   hasErrors = checkAndSetError(pRow, field, validateRequired(newValue));
      //   break;

      default:
        return false;
    }
    return hasErrors
  };
  
  const updateMstCalendar = useCallback(
    async (updatedRows: MstCalendarWithId[]) => {
    const datalist = updatedRows.map(({ isEdited, orgCenterId, calendarDate, editedFields, errors, ...rest }) => ({
      ...rest,
      orgCenterId: orgCenterId ??  '',
      calendarDate: calendarDate ? convertDateToRequestParam(new Date(calendarDate)) : null,
    }));

    try {
      await fetchCalendarUpdate({
        mstCalendarUpdate: { body: datalist }
      })
      onReload()
    } catch (err: any) {
      if (err?.errors && err.result?.status === "error") {
        const gridApi = gridRef.current?.api
        err.errors.forEach((errrow: any) => {
          const {rowdata}=errrow
          const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id;
          const calendarDate = (rowdata as { calendar_date: string }).calendar_date;
          const errRow = updatedRows.find((rowdatas) => rowdatas.orgCenterId === orgCenterId && isEqualDate(rowdatas.calendarDate,calendarDate)); 
          // console.log("errRow", orgCenterId, calendarDate, errRow, updatedRows)
          if (errRow) {
            // UpdateAPIのErrorは、PKをエラー表記して、サーバーから取得したエラーを表示する
            errRow.errors = {
              orgCenterId: [errrow.errorMessage],
              calendarDate: [errrow.errorMessage],
            }
            const rowNode = gridApi?.getRowNode(errRow.id || '')
            if (rowNode) {
              rowNode.setData(errRow)
            }
          }
        })
      }
    }
  }, [fetchCalendarUpdate, searchParam]);

  const handleImportExcel = (data: any[]) => {
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCalendarWithId> = {};
  
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (['deleteFlg', 'enableShip', 'enableArrival', 'enableOrder'].indexOf(tableField) >= 0) {
            setvalue = parseBoolean(importvalue)
          } else if (tableField === 'calendarDate') {
            setvalue = parseDate(importvalue) as unknown as undefined;;
          } else if (tableField === 'arrivalRatio') {
            setvalue = parseNumeric(importvalue) as unknown as undefined;;
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstCalendarWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstCalendarWithId;
    });
    return { mappedData, hasError }
  };

  const {
    booleanFormatter,
    dateFormatter,
    excelStyles,
    selectValueFormatter,
    typecodeFormatter,
    formatBooleanValue,
    formatDecimalValue,
    isNonInputValue,
    numberValueFormatter,
    numberValueParser,
    parseBoolean,
    parseBooleanValue,
    parseDate,
    parseDateValue,
    parseDecimalValue,
    parseNumeric,
    parsePositiveInteger,
    parseStringValue,
    parseTime,
    selectTypecodeValueParser,
    selectValueParser,
    typeCodeParser,
    localeText,
    autoSizeStrategy,
    onGridReady,
    getCellClassRules,
    checkAndSetError,
    rowDataValidate,
    colDataValidate,
    duplicateCheck,
  } = useGridCommon({
    gridRef,
    isLoading,
    isUpdating,
    onReload,
    onUploadData,
    updateTrigger,
    setHasGridEdited,
    setResultMessage,
    columnMapping,
    primayKey,
    updateData: updateMstCalendar,
    handleImportExcel,
    createBlankRow,
    fieldValidate,
    // cellValueChanged,
  })

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef)[] => 
  [
    {
      headerName: '自社センターID',
      field: 'orgCenterId', // 自社センターID
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
      },
      width: 350,
      flex:3,
      rowDrag: true,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => selectValueFormatter(params.value, center, "orgCenterId", "centerName"),
      valueParser: (params: ValueParserParams) => selectValueParser(params, center, "orgCenterId"),
      cellEditorParams: editorDataReady ? {
        values : [null, ...centerSelect],
        formatValue: (value: string) => selectValueFormatter(value, center, "orgCenterId", "centerName"),
        searchType: "match",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams : {values: ['-']},
    },           
    {
      headerName: '日付',
      field: 'calendarDate', // 日付
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
      },
      width: 300,
      flex:2,
      cellEditor: 'agDateCellEditor',
      cellEditorParams: {
        minYear: 1900, 
        maxYear: 2050,
      },
      valueFormatter: dateFormatter, 
      valueParser: parseDateValue,
    },
    {
      headerName: '出荷可能フラグ',
      field: 'enableShip', // 出荷可能フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellClassRules: getCellClassRules(),
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue, 
    },
    {
      headerName: '受入可能フラグ',
      field: 'enableArrival', // 受入可能フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue, 
    },
    {
      headerName: '発注業務可能フラグ',
      field: 'enableOrder', // 発注業務可能フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue, 
    },
    {
      headerName: '入荷比率設定',
      field: 'arrivalRatio', // 入荷比率設定
      editable: true,
      flex:1,
      cellClass: 'number-cell',
      cellEditor:'agNumberCellEditor',
      valueFormatter: numberValueFormatter,
      valueParser: numberValueParser, 
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: formatBooleanValue,
      valueParser: parseBooleanValue, 
    },
  ];
  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])

  // ダウンロードファイル用のカラム定義
  useEffect(() => {
    if (setDownloadColumnDefs)
      setDownloadColumnDefs([
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        { headerName: '自社センターID', children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }] },
        { headerName: '日付', children: [{ headerName: 'calendar_date', field: 'calendarDate' }] },
        { headerName: '出荷可能フラグ', children: [{ headerName: 'enable_ship', field: 'enableShip', cellClass: 'booleanType' }] },
        { headerName: '受入可能フラグ', children: [{ headerName: 'enable_arrival', field: 'enableArrival', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ', children: [{ headerName: 'enable_order', field: 'enableOrder', cellClass: 'booleanType' }] },
        { headerName: '入荷比率設定', children: [{ headerName: 'arrival_ratio', field: 'arrivalRatio', cellClass: 'numberType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
      ])
  }, [setDownloadColumnDefs])

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...initRecordEditEx()})))
    }
  }, [list.list, createBlankRow]);

  return (
    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
      <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            key={`${row[0]?.companyId}-${editorDataReady}`}
            rowData={row}
            ref={gridRef}
            getRowId={(params) => params.data.id}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            localeText={localeText}
            autoSizeStrategy={autoSizeStrategy}
            loadingOverlayComponent={LoadingOverlay}
            />
        </div>
      </div>
    </div>
  );
}

export default DataList;
