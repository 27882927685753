import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  GetMstCalendarCalendarGetRequest,
  ResponseResult,
  ResponseUpdate,
  MasterCalendarUpdateMstCalendarUpdatePostRequest,
  Calendar,
  MstCalendar,
} from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface MstCalendarSeq extends MstCalendar{
  id: string
}

export interface MstCalendarsSeq extends Calendar{
  list: MstCalendarSeq[]
}

export const useCalendar = () => {
  const [calendarSearch, setCalendarSearch] = useState<MstCalendarsSeq>(initRequestParam)
  const [calendarSearchIsLoading, setCalendarSearchIsLoading] = useState(false)
  const [calendarSearchResult, setCalendarSearchResult] = useState<ResponseResult | undefined>(undefined)
  const [calendarUpdateResult, setCalendarUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [result, setResult] = useState<ResponseResult>()

  const fetchCalendarSearch = (searchParam: GetMstCalendarCalendarGetRequest) => {
    setCalendarSearch(initRequestParam)
    setCalendarSearchResult(undefined)
    setCalendarSearchIsLoading(true)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstCalendarCalendarGet({
          ...searchParam,
          companyId: searchParam.companyId,
          orgCenterId: searchParam.orgCenterId || undefined,
          sort: searchParam.sort || undefined,
        })
      })
      .then((response) => {
        setCalendarSearch({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            id: Math.random().toString(32).substring(2),
          }))
        })
        setCalendarSearchResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
        setResult(response.result)
      })
      .catch(({ response }: ErrorResponse) => {
        setCalendarSearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setCalendarSearchIsLoading(false)
        setIsLoading(false)
      })
  }

  const fetchCalendarUpdate = async (body: MasterCalendarUpdateMstCalendarUpdatePostRequest): Promise<ResponseUpdate> => {
    setIsUpdating(true);
    setResult(undefined)

    try {
      const token = await auth.currentUser?.getIdToken(true);
      if (!token) throw new Error('Token not found');
      
      const conf = new Configuration({
        basePath: process.env.REACT_APP_API_BASE_PATH,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const api = new DefaultApi(conf);
      const response = await api.masterCalendarUpdateMstCalendarUpdatePost(body);

      setCalendarUpdateResult(response);
      setResult(response.result)
      if (response.result.status !== 'success') {
        const error = new Error(response.result.message || '入力エラーがあります');
        Object.assign(error, {
            ...response
        });
        throw error;
      }
      return response;
    } catch (error) {
        if (error && typeof error === 'object' && 'result' in error) {
            const apiResponse = error as ResponseUpdate; 
            await new Promise<void>(resolve => {
              setCalendarUpdateResult(apiResponse);
              resolve();
            });
            return await Promise.reject(apiResponse);
        }
        const { response } = error as any;
        const errorResponse = {
            errors: [],
            result: {
                status: 'error',
                message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
                systemDate: null,
                pageNo: 0,
                systemInfo: null,
            },
            updateCount: null,
        };
        await new Promise<void>(resolve => {
          setCalendarUpdateResult(errorResponse);
          setResult(errorResponse.result)
          resolve();
        });
        return await Promise.reject(errorResponse);      

    } finally {
      setIsUpdating(false);
    }
  };


  return {
  	result,
  	isLoading,
  	isUpdating,
    calendarSearch,
    fetchCalendarSearch,
    calendarSearchResult,
    calendarSearchIsLoading,

    fetchCalendarUpdate,
    calendarUpdateResult,
  }
}
