import React, { useEffect } from 'react'

import { Button } from '@mui/material'
import { type GridColDef, type GridValueFormatterParams } from '@mui/x-data-grid'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import ShowLastUpdateDatetime from 'components/commonparts/ShowLastUpdateDatetime/ShowLastUpdateDatetime'

import type { Item, MstItem } from 'api-client'
import type { DataListStatus } from 'types/types'

interface DataListProps {
  list: Item
  isLoading: boolean
  listStatus: DataListStatus
  onListStatusChange: (status: DataListStatus) => void
  onRowSelect: (item: MstItem) => void
}

function DataList({ list, isLoading, listStatus, onListStatusChange, onRowSelect }: DataListProps) {
  const columns: GridColDef[] = [
    {
      headerName: '選択',
      field: 'choiceBtn',
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            onRowSelect(params.row)
          }}
        >
          選択
        </Button>
      ),
    },
    {
      headerName: '商品コード',
      field: 'itemCode',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '社内商品コード',
      field: 'orgItemCode',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '商品名称',
      field: 'itemName',
      minWidth: 250,
      type: 'string',
    },
    {
      headerName: 'メーカー名',
      field: 'makerName',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '仕入先企業ID',
      field: 'orgSupplierId',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '仕入先プロダクト統一企業ID',
      field: 'supplierCompanyId',
      minWidth: 150,
      type: 'number',
    },
    {
      headerName: '仕入先企業名',
      field: 'supplierName',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '発注単位',
      field: 'orderUnitTypeName',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: 'ケースロット数',
      field: 'caseLot',
      minWidth: 150,
      type: 'number',
    },
    {
      headerName: '最低発注量',
      field: 'minOrderQuantity',
      minWidth: 150,
      type: 'number',
      align: 'right',
      valueFormatter: (params: GridValueFormatterParams<number>) => Number(params.value).toLocaleString(),
    },
    {
      headerName: '発売日',
      field: 'salesStartdate',
      type: 'date',
      minWidth: 90,
    },
    {
      headerName: '終了日',
      field: 'salesEnddate',
      type: 'date',
      minWidth: 90,
    },
    {
      headerName: '規格',
      field: 'spec',
      type: 'string',
      minWidth: 150,
    },
    {
      headerName: 'ケースサイズ 長手（mm）',
      field: 'longside',
      type: 'number',
      minWidth: 220,
    },
    {
      headerName: 'ケースサイズ 短手（mm）',
      field: 'shortside',
      type: 'number',
      minWidth: 220,
    },
    {
      headerName: 'ケースサイズ 高さ（mm）',
      field: 'height',
      type: 'number',
      minWidth: 220,
    },
    {
      headerName: 'ケースサイズ 重量（g）',
      field: 'weight',
      type: 'number',
      minWidth: 200,
    },
    {
      headerName: 'パレット最大積付数',
      field: 'maxPalletQuantity',
      type: 'number',
      minWidth: 170,
      align: 'right',
      valueFormatter: (params: GridValueFormatterParams<number>) => Number(params.value).toLocaleString(),
    },
    {
      headerName: 'パレット積載数（面）',
      field: 'palletQuantityFace',
      type: 'number',
      minWidth: 200,
    },
    {
      headerName: 'パレット積載数（段）',
      field: 'palletQuantityTier',
      type: 'number',
      minWidth: 200,
    },
    {
      headerName: 'JANコード（バラ）',
      field: 'jan',
      type: 'string',
      minWidth: 170,
    },
    {
      headerName: 'JANコード（ケース）',
      field: 'janCase',
      type: 'string',
      minWidth: 180,
    },
    {
      headerName: 'GTINコード（バラ）',
      field: 'gtin13',
      type: 'string',
      minWidth: 170,
    },
    {
      headerName: 'GTINコード（ケース）',
      field: 'gtin14',
      type: 'string',
      minWidth: 180,
    },
    {
      headerName: 'ITFコード',
      field: 'itf',
      type: 'string',
      minWidth: 150,
    },
    {
      headerName: 'SDPコード',
      field: 'sdp',
      type: 'string',
      minWidth: 150,
    },
    {
      headerName: '後継商品コード',
      field: 'replaceItemCode',
      type: 'string',
      minWidth: 150,
    },
    {
      headerName: 'メーカーGS1事業者コード',
      field: 'makerGln',
      type: 'string',
      minWidth: 220,
    },
  ]

  // セルの自動調整機能 premium版のみ利用可能
  const apiRef = useGridApiRef()
  const autosizeColumns = {
    columns: [
      'itemCode',
      'orgItemCode',
      'itemName',
      'makerName',
      'orgSupplierId',
      'supplierCompanyId',
      'supplierName',
      'orderUnitTypeName',
      'caseLot',
      'minOrderQuantity',
      'salesStartdate',
      'salesEnddate',
      'spec',
      'longside',
      'shortside',
      'height',
      'weight',
      'maxPalletQuantity',
      'jan',
      'janCase',
      'gtin13',
      'gtin14',
      'itf',
      'sdp',
      'replaceItemCode',
      'makerGln',
    ],
    includeHeaders: true,
    includeOutliere: true,
    outliersFactor: 1.5,
    expand: true,
  }

  // https://mui.com/x/react-data-grid/column-dimensions/#autosizing
  // 上記のセルの自動調整機能採用しているが、正式的な機能ではない
  useEffect(() => {
    if (list.list.length > 0 && apiRef.current.autosizeColumns) {
      // NOTE:データをdataGridにバインドしてからじゃないと自動調節が効かないため意図的にずらして実行
      setTimeout(() => apiRef.current.autosizeColumns(autosizeColumns), 1000)
    }
  }, [list.list])

  return (
    <>
      <ShowLastUpdateDatetime<MstItem> list={list.list} lastUpdateDatetime={list.lastUpdateDatetime} />
      <DataGridPremium
        // ダイアログに関して共通の高さが違うため個別で設定する
        sx={{ maxHeight: 'calc(100vh - 300px)' }}
        rows={list.list}
        rowCount={list.maxcount as number}
        columns={columns}
        initialState={{
          pagination: { paginationModel: listStatus.paginationModel },
        }}
        disableRowSelectionOnClick
        paginationMode="server"
        filterMode="server"
        loading={isLoading}
        getRowId={(row) => row.id}
        sortModel={listStatus.sortModel}
        onSortModelChange={(state) => {
          onListStatusChange({ sortModel: state, paginationModel: listStatus.paginationModel })
        }}
        pagination
        paginationModel={listStatus.paginationModel}
        onPaginationModelChange={(pgState) => {
          onListStatusChange({ sortModel: listStatus.sortModel, paginationModel: pgState })
        }}
        onRowDoubleClick={(params) => {
          onRowSelect(params.row)
        }}
        apiRef={apiRef}
        density="compact"
      />
    </>
  )
}

export default DataList
